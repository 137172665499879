import React, { Fragment } from "react";
import { OverLayContainer } from "../EditForm";
import { ButtonWrapper, ColumnContainer } from "../../Form/Web3ResumeContainer";
import { FlexSpaceBetweenContainer } from "../../Profile/UserProfile";
import { SubHeading } from "../../Typography/Typo";
import { CustomButton } from "../../fields/Button/CustomButton";
import { IconWrapper } from "../../Profile/UserProfileHeader";
import EditIcon from "@mui/icons-material/Edit";
import { useStateValue } from "../../../../context/StateProvider";
import { IconButton } from "@mui/material";
import StageForm from "../../Form/StageForm/StageForm";
import { Container } from "../AchievmentEditForm/MultiAchieveDataEditComponent";
import { OverFlowContainer } from "../CustomEditForm/MultiCustomDataEditComponent";
import {
  ArrowBody,
  ArrowsContainer,
  Slider,
} from "../../Form/StageForm/StageSliderView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function AddStageDataEditComponent({ setOverLay, setStatus, showEdit }) {
  const [{ products }, dispatch] = useStateValue();

  const closeEdit = () => {
    dispatch({
      type: "CLEAR_PRODUCTS",
    });
    dispatch({
      type: "CLEAR_STAGE_FIELDS",
    });
    dispatch({
      type: "CLEAR_STAGE_CUSTOM_DATA_FIELDS",
    });
    setOverLay(false);
  };

  return (
    <>
      <OverLayContainer onClick={() => closeEdit()} />
      <Container>
        <OverFlowContainer>
          <FlexSpaceBetweenContainer
            style={{
              width: "95%",
              position: "absolute",
              left: "0",
              top: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              zIndex: "999",
            }}
          >
            <SubHeading style={{ padding: "2rem 3rem" }} primary>
              Add Stage Data
            </SubHeading>
            {!showEdit && (
              <IconWrapper style={{ backgroundColor: "#7271713b" }}>
                <IconButton onClick={() => setStatus(2)}>
                  <EditIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                </IconButton>
              </IconWrapper>
            )}
          </FlexSpaceBetweenContainer>

          <ColumnContainer
            style={{
              width: "90%",
              margin: "5rem 0rem",
            }}
          >
            {products.length > 0 && (
              <Fragment>
                <Slider productData={products} />
                <ArrowsContainer>
                  <ArrowBody className="prev">
                    <ArrowBackIosNewIcon
                      className="prev"
                      style={{ color: "#080036", cursor: "pointer" }}
                    />
                  </ArrowBody>
                  <ArrowBody className="next">
                    <ArrowForwardIosIcon
                      className="next"
                      style={{ color: "#080036", cursor: "pointer" }}
                    />
                  </ArrowBody>
                </ArrowsContainer>
              </Fragment>
            )}
            <StageForm />
          </ColumnContainer>
          <ColumnContainer
            style={{
              width: "100%",
              position: "absolute",
              right: "0",
              bottom: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <ButtonWrapper style={{ gap: "1rem", padding: "2rem 3rem" }}>
              <CustomButton
                login
                text="Cancel"
                rejectClass
                type="button"
                onclick={() => setOverLay(false)}
              />
              <CustomButton
                onclick={() => setStatus(3)}
                login
                text="Add New Data"
                disabled={products.length <= 0}
              />
            </ButtonWrapper>
          </ColumnContainer>
        </OverFlowContainer>
      </Container>
    </>
  );
}

export default AddStageDataEditComponent;
