import styled from "styled-components";

export const GridContainer = styled.div`
  width: 100%;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
  @media (max-width: 1568px) {
    grid-template-columns: 1fr 2fr;
  }
  @media (max-width: 1368px) {
    grid-template-columns: 1.5fr 2fr;
  }
  @media (max-width: 1068px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
