import { Divider, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import EditForm from "../EditForms/EditForm";
import { SubHeading } from "../Typography/Typo";
import TokenCard from "./TokenCard";
import {
  FlexSpaceBetweenContainer,
  SectionInnerContainer,
} from "./UserProfile";
import EditIcon from "@mui/icons-material/Edit";
import { IconWrapper, SectionContainer } from "./UserProfileHeader";
import AddIcon from "@mui/icons-material/Add";

function AchievementSection({
  isPublic,
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  isDecrypted,
  decryptType,
  setShowAchEdit,
  setEditType,
}) {
  return (
    <SectionContainer>
      <SectionInnerContainer>
        <FlexSpaceBetweenContainer>
          <SubHeading primary>Achievements</SubHeading>{" "}
          <IconWrapper style={{ backgroundColor: "#c7c7c72f" }}>
            <IconButton
              onClick={() => {
                setEditType("");
                if (!isDecrypted) {
                  decryptType("all");
                  setShowDecryption(true);
                  setEditType("achEdit");
                } else {
                  setShowAchEdit((value) => !value);
                }
              }}
            >
              {profileData?.achievements?.length === 0 ? (
                <AddIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              ) : (
                <EditIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              )}
            </IconButton>
          </IconWrapper>{" "}
        </FlexSpaceBetweenContainer>
        {profileData?.achievements &&
          profileData?.achievements?.length > 0 &&
          profileData?.achievements?.map((item, key) => {
            return (
              <Fragment key={key}>
                <TokenCard
                  id={key}
                  fieldData={item}
                  itemName={item?.data?.itemName?.data}
                  itemImage={item?.data?.itemImage?.data}
                  fieldId={{ id: key, name: "achievements" }}
                  status={0}
                  isPublic={isPublic}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  issueDate={Number(item?.data?.issueDate?.data)}
                  issuer={item?.data?.alias?.data}
                  description={item?.data?.description?.data}
                  setShowDecryption={setShowDecryption}
                  setShowEncryption={setShowEncryption}
                  encryptedType={item?.data?.itemName?.encryptionType}
                  setCurrentField={setCurrentField}
                />
                {profileData?.achievements?.length > 1 &&
                  profileData?.achievements?.length - 1 !== key && (
                    <Divider
                      style={{
                        background: "#5e5e5e11",
                        width: "100%",
                      }}
                    />
                  )}
              </Fragment>
            );
          })}
      </SectionInnerContainer>
    </SectionContainer>
  );
}

export default AchievementSection;
