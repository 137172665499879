import React from "react";
import styled from "styled-components";

export default function TableHeadItem({ item }) {
  return <Th title={item}>{item}</Th>;
}

const Th = styled.th`
  text-transform: capitalize;
  padding: 1rem;
  color: #1e1b3b;
  min-width: 6rem;
  text-align: left;
`;
