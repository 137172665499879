import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShareIcon from "@mui/icons-material/Share";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Modal } from "@mui/material";

import { ProviderShareConfirm } from "./ProviderShareConfirm";
import ContactInfo from "../../Profile/ContactInfo";
import EncryptedData from "../../Profile/EncryptedData";
import { Text } from "../../../../pages/JobListings/JobComponent";
import { Heading } from "../../Typography/Typo";
import VerifiedStatusContainer from "../../Profile/VerifiedStatusContainer";
import {
  FieldWrapper,
  FlexSpaceBetweenContainer,
} from "../../Profile/UserProfile";
import { ImageFlexContainer, LargeImage } from "../../../../pages/ViewCV/Field";
import { urlToHash } from "../../../../utils/ipfs";

const ProviderProfileHeader = ({
  profileData,
  setShowAction,
  setVerification,
  isPublic,
  setIsPublic,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  isDecrypted,
  decryptType,
  setEditType,
  setEditOpen,
  setContactOpen,
  setShareOpen,
}) => {
  const [openImage, setImageOpen] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleContactOpen = () => {
    setEditType("");
    if (!isDecrypted) {
      decryptType("all");
      setShowDecryption(true);
      setEditType("contact");
    } else {
      setContactOpen(true);
    }
  };

  return (
    <Fragment>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={
                profileData?.profileImage?.data?.data === "undefined"
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : !profileData?.profileImage?.data?.data
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      profileData?.profileImage?.data?.data
                    )}`
              }
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>

      <SectionContainer>
        <AbsoluteContainer>
          <FlexSpaceBetweenContainer style={{ gap: "0.5rem" }}>
            {/* Encrypting and decrypting icon */}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setEditType("");
                  decryptType("all");
                  isDecrypted
                    ? setShowEncryption(true)
                    : setShowDecryption(true);
                }}
              >
                {isDecrypted ? (
                  <LockOpenIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                ) : (
                  <LockIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                )}
              </IconButton>
            </IconWrapper>{" "}
            {/* Edit icon */}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setEditType("");
                  if (!isDecrypted) {
                    decryptType("all");
                    setShowDecryption(true);
                    setEditType("basicEdit");
                  } else {
                    setEditOpen((value) => !value);
                  }
                }}
              >
                <EditIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              </IconButton>
            </IconWrapper>{" "}
            {/* Share icon */}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setEditType("");
                  if (!isDecrypted) {
                    decryptType("all");
                    setShowDecryption(true);
                    setEditType("shareOpen");
                  } else {
                    setShareOpen((value) => !value);
                  }
                }}
              >
                <ShareIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              </IconButton>
            </IconWrapper>
          </FlexSpaceBetweenContainer>
        </AbsoluteContainer>

        <Banner>
          <ImageWrapper>
            <ProfileImage
              alt="Profile Picture"
              cursor
              src={
                profileData?.profileImage?.data?.data === "undefined" ||
                !profileData?.profileImage?.data?.data
                  ?.toString()
                  ?.includes("ipfs")
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : !profileData?.profileImage?.data?.data
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      profileData?.profileImage?.data?.data
                    )}`
              }
              onClick={() => {
                handleImageOpen();
              }}
            />
          </ImageWrapper>
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={profileData?.profileImage?.data?.data}
            fieldName="profileImage"
            fieldId={{ id: "profileImage" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            setShowDecryption={setShowDecryption}
            setShowEncryption={setShowEncryption}
            isDecrypted={profileData?.profileImage?.isPublic}
            encryptedType={profileData?.profileImage?.data?.encryptionType}
            setCurrentField={setCurrentField}
            style={{ top: "40%", position: "absolute", left: "18%" }}
          />
        </Banner>
        <SectionInnerContainer>
          <ContentContainer>
            <FieldWrapper>
              {profileData?.name?.isPublic ? (
                <Heading primary>{profileData?.name?.data?.data}</Heading>
              ) : (
                <EncryptedData
                  fieldData={`${profileData?.name?.data?.data}`}
                  setShowDecryption={setShowDecryption}
                />
              )}

              <VerifiedStatusContainer
                status={0}
                isPublic={isPublic}
                fieldData={profileData?.name?.data?.data}
                fieldName="Name"
                fieldId={{ id: "name" }}
                setShowAction={setShowAction}
                setVerification={setVerification}
                setShowDecryption={setShowDecryption}
                setShowEncryption={setShowEncryption}
                isDecrypted={profileData?.name?.isPublic}
                encryptedType={profileData?.name?.data?.encryptionType}
                setCurrentField={setCurrentField}
              />
            </FieldWrapper>

            <FieldWrapper>
              <NameContainer>
                {profileData?.city?.isPublic ? (
                  <Text lighter color="#6d6d6d">
                    {`${profileData?.city?.data?.data} District, Western, `}
                  </Text>
                ) : (
                  <EncryptedData
                    fieldData={`${profileData?.city?.data?.data} District, Western, `}
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  status={0}
                  isPublic={isPublic}
                  fieldData={`${profileData?.city?.data?.data} District, Western, `}
                  fieldName="Lives in"
                  fieldId={{ id: "city" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.city?.isPublic}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                  encryptedType={profileData?.city?.data?.encryptionType}
                />
                &nbsp;
                {profileData?.country?.isPublic ? (
                  <Text lighter color="#6d6d6d">
                    {` ${profileData?.country?.data?.data}`}
                  </Text>
                ) : (
                  <EncryptedData
                    fieldData={` ${profileData?.country?.data?.data}`}
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  status={0}
                  isPublic={isPublic}
                  fieldData={` ${profileData?.country?.data?.data}`}
                  fieldName="Lives in"
                  fieldId={{ id: "country" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.country?.isPublic}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                  encryptedType={profileData?.country?.data?.encryptionType}
                />
              </NameContainer>
            </FieldWrapper>
            <FieldWrapper>
              <SpanLink onClick={handleContactOpen}>Contact Info</SpanLink>
            </FieldWrapper>
          </ContentContainer>
        </SectionInnerContainer>
      </SectionContainer>
    </Fragment>
  );
};

export default ProviderProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const SpanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #1696ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
