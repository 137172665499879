import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import { Text } from "../Typography/Typo";
import LoadingGif from "../../../assets/images/uploading-to-cloud.gif";
import { AddImageToIPFS } from "../../../services/IpfsService";
import {
  DroppableContainer,
  InnerContainer,
} from "../fields/Dropzone/DropzoneComponent";

const PDFUploaderDropzone = ({
  description,
  setFiles,
  files,
  maxFiles,
  maxSize,
  multiple,
  minSize,
  type,
  form,
}) => {
  const [localFiles, setLocalFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    maxFiles: maxFiles,
    minSize: minSize,
    maxSize: maxSize,
    multiple: multiple,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles({
        fileArr: [],
        loading: false,
        errors: false,
        upload: 0,
      });
      rejectedFiles.forEach((fileCheck) => {
        fileCheck.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFiles((prevState) => ({
              ...prevState,
              errors: "Error: File is larger than 1 MB",
            }));
          } else if (err.code === "file-invalid-type") {
            setFiles((prevState) => ({
              ...prevState,
              errors: "Only PDF OR Image Allowed",
            }));
          }
        });
      });
      if (rejectedFiles?.length === 0) {
        uploadFiles(
          acceptedFiles?.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }
    },
  });

  const thumbs =
    files?.fileArr?.length > 0 &&
    files?.fileArr?.map((file, index) => (
      <div style={thumb} key={index}>
        <div style={thumbInner}>
          <CancelIcon
            onClick={() =>
              setFiles({
                fileArr: [],
                loading: false,
                errors: false,
                upload: 0,
              })
            }
            style={{
              position: "absolute",
              top: "0%",
              left: "100%",
              zIndex: "99",
              transform: "translate(-50%, -50%)",
              color: "#8a8888",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "80px",
              }}
              alt={file.path}
              src={
                file.type === "image/png" || file.type === "image/jpeg"
                  ? file.preview
                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
              }
            />
            <Text
              style={{
                fontSize: "13px",
              }}
            >
              {file.path}
            </Text>
          </div>
        </div>
      </div>
    ));

  const loadingThumbs =
    localFiles?.length > 0 &&
    localFiles?.map((file, index) => (
      <div style={thumb} key={index}>
        <div style={thumbInner}>
          <img alt={file.path} src={LoadingGif} style={img} />
        </div>
      </div>
    ));

  const uploadFiles = async (acceptedFiles) => {
    let acceptedFilesArr = acceptedFiles;
    setLocalFiles(acceptedFilesArr);
    if (acceptedFilesArr?.length > 0) {
      let newfileArr = [];
      acceptedFilesArr?.forEach((file) => URL.revokeObjectURL(file?.preview));

      setFiles((prevState) => ({
        ...prevState,
        loading: true,
      }));

      try {
        for (let i = 0; i < acceptedFilesArr.length; i++) {
          const fileObject = acceptedFilesArr[i];
          let previewImageUrl;
          const ipfsData = await AddImageToIPFS(acceptedFilesArr[i]);
          previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
          fileObject.preview = previewImageUrl;
          newfileArr.push(fileObject);
        }
        setFiles((prevState) => ({
          ...prevState,
          fileArr: newfileArr,
          upload: 1,
          loading: false,
        }));
      } catch (error) {
        setFiles({
          fileArr: [],
          loading: false,
          errors: "Upload Error",
          upload: 0,
        });
        setLocalFiles([]);
      }
    }
  };

  return (
    <DroppableContainer>
      <InnerContainer {...getRootProps()}>
        <input
          form={form}
          required
          {...getInputProps()}
          disabled={files?.loading}
        />
        {localFiles?.length === 0 && (
          <Text lighter small color={files.errors ? "#fe2121" : "#1E1B3B"}>
            {files.errors ? files?.errors : description}
          </Text>
        )}
        <aside style={thumbsContainer}>
          {files?.loading ? loadingThumbs : thumbs}
        </aside>
      </InnerContainer>
    </DroppableContainer>
  );
};

PDFUploaderDropzone.propTypes = {
  description: PropTypes.string,
  setFiles: PropTypes.func,
  files: PropTypes.object,
  id: PropTypes.string,
  amount: PropTypes.string,
  maxSize: PropTypes.string,
};

export default PDFUploaderDropzone;

const thumbsContainer = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #EAEAEA",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  // overflow: "hidden",
};
const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
