import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import ProviderFormContainer from "./ProviderFormContainer";
import ProviderView from "../ViewUniversity/ProviderView";
import { useSelector } from "react-redux";
import { fetchLatestProfileData } from "../../utils/FetchLatestData";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";

const AddProviderProfileView = () => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(1);
  const currentUser = useSelector((state) => state.currentUser);
  const latestIpfsData = useSelector((state) => state.latestIpfsData);

  useEffect(() => {
    fetchLatestProfileData(setLoading, setView, currentUser);
  }, [view, currentUser]);

  return (
    <Container>
      {loading ? (
        <InnerLoadingView />
      ) : view === 2 ? (
        <ProviderView data={latestIpfsData} />
      ) : (
        <Fragment>
          <ButtonContainer
            heading="Credential Provider Profile"
            description="Add, remove or edit your institution details here"
            icon={"school"}
          />
          <ProviderFormContainer setView={setView} />
        </Fragment>
      )}
    </Container>
  );
};

export default AddProviderProfileView;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
