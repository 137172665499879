import React, { useState } from "react";
import styled from "styled-components";
import { EditorState } from "draft-js";
import * as API from "../../api/API";

import { useMediaQuery } from "@mui/material";
import { SubHeading } from "../../components/shared/Typography/Typo";

import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import TextEditor from "../../components/shared/TextEditor/TextEditor";
import JobComponent from "../../pages/JobListings/JobComponent";
import { TextField } from "../../components/shared/Form/FormComponents/TextField";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

var emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const JobPostingForm = ({ setMainView }) => {
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => API.getUserSession());
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state) => state.currentUser);
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const [showAction, setShowAction] = useState(false);
  const [position, setPosition] = useState("");
  const matches = useMediaQuery("(max-width:1368px)");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [description, setDescription] = useState(null);
  // form field states
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  function handleJobPostSubmit(e) {
    if (
      position !== "" &&
      phoneNumber !== "" &&
      description !== "" &&
      email !== "" &&
      latestIpfsData
    ) {
      setShowAction(true);
    } else {
      enqueueSnackbar(`Please fill in the details`, {
        variant: "error",
      });
    }
  }

  const dispatchAddJob = async () => {
    if (currentUser?.latestIpfsUrl) {
      setLoading(true);
      let jobData = {
        position: position,
        phoneNumber: phoneNumber,
        description: description,
        email: email,
        credentialProviderName: latestIpfsData?.name?.data?.data,
        credentialProviderPublicKey: currentUser.publicKey,
        credentialProviderProfileImage:
          latestIpfsData?.profileImage?.data?.data,
        credentialProviderAddress: {
          addressLineOne: latestIpfsData?.addressLineOne?.data?.data,
          addressLineTwo: latestIpfsData?.addressLineTwo?.data?.data,
        },
      };

      try {
        const response = await API.createJobPosting({ body: jobData });
        if (response) {
          if (response?.data?.code === 201) {
            enqueueSnackbar(`Job Posting Added`, {
              variant: "success",
            });
            setMainView(0);
          } else {
            enqueueSnackbar(`Job Posting Failed`, {
              variant: "error",
            });
          }
          setLoading(false);
          setShowAction((value) => !value);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setEmail("");
        setPosition("");
        setPhoneNumber("");
        setDescription("");
      }
    } else {
      enqueueSnackbar(`Please fill Company Profile data to proceed`, {
        variant: "error",
      });
      setShowAction(false);
    }
  };

  if (view === 1) {
    return (
      <Container>
        {showAction && (
          <ActionConfirm
            successMessage={{
              message: "Adding Job details in to the Blockchain",
              width: "18rem",
            }}
            warningMessage={"Are you sure you want to post?"}
            loading={loading}
            setShowAction={setShowAction}
            authTypeInput={authUser.authType}
            publicKey={authUser.publicKey}
            encryptedSecret={authUser.encryptedSecret}
            onSecretKey={dispatchAddJob}
          />
        )}
        <JobComponent
          onClickEdit={setView}
          fullView
          companyView
          description={description}
          company={{
            name: latestIpfsData?.name?.data?.data,
            logo: latestIpfsData?.profileImage?.data?.data,
          }}
          time={Date.now()}
          title={position}
          location={`${latestIpfsData?.city?.data?.data}, ${latestIpfsData?.country?.data?.data}`}
        />
        <ColumnContainer>
          <ButtonWrapper>
            <CustomButton login text="Post Job" onclick={handleJobPostSubmit} />
          </ButtonWrapper>
        </ColumnContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <GridContainer id="job-form">
          <SubHeading
            primary
            style={{
              gridColumn: `${!matches ? "1/3" : "unset"}`,
              margin: "1rem 0rem",
            }}
          >
            Job Description
          </SubHeading>
          <TextField
            label="Position"
            required
            value={position}
            onChange={setPosition}
            placeholder="Position"
            id="position"
            name="position"
            type="text"
            maxLength="80"
          />
          <TextField
            label="Phone Number"
            placeholder="0/ 94/ +94/ 0094"
            id="phoneNumber"
            required
            name="phoneNumber"
            type="text"
            maxLength="13"
            value={phoneNumber}
            onChange={(e) => {
              if (!phoneNoValidation.test(e)) {
                setPhoneNumberError(true);
              } else {
                setPhoneNumberError(false);
              }
              setPhoneNumber(e);
            }}
            color="#FF613F"
            helper={phoneNumberError && "Invalid Mobile Number"}
          />
          <TextEditor
            placeholder="Add Job Descriptions"
            fieldName="Job Description"
            editorState={editorState}
            setEditorState={setEditorState}
            setDescription={setDescription}
          />
          <TextField
            label="Email"
            placeholder="Email"
            id="email"
            span
            required
            name="email"
            type="text"
            maxLength="50"
            value={email}
            onChange={(e) => {
              if (!emailValidation.test(e)) {
                setEmailError(true);
              } else {
                setEmailError(false);
              }
              setEmail(e);
            }}
            color="#FF613F"
            helper={emailError && "Invalid Email"}
          />
        </GridContainer>
        <ColumnContainer>
          <ButtonWrapper>
            <CustomButton
              login
              disabled={description === null || position === ""}
              text="Preview"
              onclick={() => {
                if (position !== "" || description !== null) {
                  setView(1);
                }
              }}
            />
          </ButtonWrapper>
        </ColumnContainer>
      </Container>
    );
  }
};

export default JobPostingForm;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

export const GridContainer = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;
