import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import styled from "styled-components";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import { ButtonWrapper } from "../Web3ResumeContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import { Label } from "./TextField";
import { useStateValue } from "../../../../context/StateProvider";
import MultipleImageSlider from "./MultipleImageSlider";
import { ArrowBody, ArrowsContainer } from "../StageForm/StageSliderView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const MultipleImageUpload = ({
  name,
  customImageArr,
  setCustomImageArr,
  span,
}) => {
  const [imageFile, setImageFile] = useState([]);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [{ fields }, dispatch] = useStateValue();
  const [stateSwitch, setStateSwitch] = useState(1);



  var dataListfieldKeys =
    customImageArr.length > 0 &&
    customImageArr?.map((data) => {
      const keyData = Object.keys(data);
      return keyData[0];
    });

  const handleRemoveField = (name) => {
    if (
      window.confirm(
        `Are you sure you want to delete the field named ${name} ? `
      )
    ) {
      enqueueSnackbar(`Field named ${name} Removed!`, {
        variant: "warning",
      });
      dispatch({
        type: "REMOVE_FIELD",
        id: name,
      });
    }
  };

  const handleImageSubmit = async () => {
    setLoading(true);
    let customObject = {};
    if (imageFile.length > 0) {
      let imageArr = [];
      try {
        for (let i = 0; i < imageFile.length; i++) {
          let previewImageUrl;
          const ipfsData = await AddImageToIPFS(imageFile[i]);
          previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
          imageArr.push(previewImageUrl);
          console.log(imageArr);
        }
        if (imageArr.length > 0) {
          customObject[name] = imageArr;
          setCustomImageArr([...customImageArr, customObject]);
        } else {
          throw new Error();
        }
        enqueueSnackbar("Upload Success", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar("Upload Error", {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setStateSwitch(2);
      }
    }
  };

  return (
    <Container span={Boolean(span)}>
      <InputContainer>
        <Label>{name}</Label>

        <CancelIcon
          onClick={() => {
            handleRemoveField(name);
          }}
          size="small"
          style={{ color: "#6e6e6eca", cursor: "pointer" }}
        />
      </InputContainer>
      {stateSwitch === 1 ? (
        <>
          <DroppableContainer>
            <DropzoneComponent
              files={imageFile}
              id="dropzone"
              maxFiles={10}
              multiple={true}
              setErrors={setErrors}
              errors={errors}
              maxSize="1000000"
              setFiles={setImageFile}
              type="image/*, image/jpeg, image/png"
              description="Drop or select your item image (Max file size 1MB)"
            />
          </DroppableContainer>
          <ButtonWrapper>
            <Button
              style={{
                background: "#1fc36157",
                color: "#080036",
                textTransform: "inherit",
                fontWeight: "500",
                fontFamily: "Poppins",
                padding: ".3rem 1rem",
                float: "right",
              }}
              onClick={handleImageSubmit}
              disabled={imageFile.length === 0 || loading}
              endIcon={loading && <CircularProgress size={20} />}
              type="button"
              size="small"
            >
              Upload
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          {dataListfieldKeys?.length > 0 &&
            dataListfieldKeys?.map((fieldName, key) => {
              if (fieldName === name) {
                return (
                  <>
                    <MultipleImageSlider
                      data={customImageArr[key][fieldName]}
                    />
                    <ArrowsContainer>
                      <ArrowBody className="prev">
                        <ArrowBackIosNewIcon
                          className="prev"
                          style={{ color: "#080036", cursor: "pointer" }}
                        />
                      </ArrowBody>
                      <ArrowBody className="next">
                        <ArrowForwardIosIcon
                          className="next"
                          style={{ color: "#080036", cursor: "pointer" }}
                        />
                      </ArrowBody>
                    </ArrowsContainer>
                  </>
                );
              }
            })}
        </>
      )}
    </Container>
  );
};

export default MultipleImageUpload;

const Container = styled.div`
  display: flex;
  margin: 1rem 0rem;
  flex-direction: column;
  gap: 1rem;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.2rem;
  background-color: #c3d0e181;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.2rem;
`;
