import { NIFTRON } from "niftron-client-sdk";
import { ADD_IPFS_DATA } from "../redux/constants/ActionTypes";
import store from "../redux/reducers";

// --- Assymetric encryption/decryption --- //

export function asymmetricEncryption(field, toPublicKey, fromSecretKey) {
  const asymmetricEncryption = new NIFTRON.utils.asymmetricEncryption();
  const response = asymmetricEncryption.encrypt(
    field,
    toPublicKey,
    fromSecretKey
  );
  return response;
}

export function asymmetricDecryption(encryptionObject, toSecretKey) {
  const asymmetricEncryption = new NIFTRON.utils.asymmetricEncryption();
  const response = asymmetricEncryption.decrypt(encryptionObject, toSecretKey);
  return response;
}

// --- Symmetric encryption/decryption --- //

export function symmetricEncryption(field, fromSecretKey) {
  const response = NIFTRON.utils.symmetricEncryption.encrypt(
    field,
    fromSecretKey
  );
  return response;
}

export function symmetricDecryption(encryptedField, fromSecretKey) {
  const response = NIFTRON.utils.symmetricEncryption.decrypt(
    encryptedField,
    fromSecretKey
  );
  return response;
}

// --- Create data encryption object --- //

export function createEncryptionObject(
  encryptionType,
  field,
  toPublicKey,
  fromPublicKey,
  fromSecretKey
) {
  let encryptionObject = { encryptionType, toPublicKey, fromPublicKey };
  if (encryptionType === "SYMMETRIC") {
    encryptionObject.data = symmetricEncryption(field, fromSecretKey);
  }

  if (encryptionType === "ASYMMETRIC") {
    encryptionObject.data = asymmetricEncryption(
      field,
      toPublicKey,
      fromSecretKey
    );
  }

  if (encryptionType === "PUBLIC") {
    encryptionObject.data = field;
  }
  return encryptionObject;
}

// --- TODO: Decrypt encryption object --- //
// export function createEncryptionObject(){}

export const decryptData = async (
  secretKey,
  latestIpfsData,
  setSecretKey,
  setIsDecrypted,
  setShowDecryption,
  currentField,
  decryptType
) => {
  setSecretKey(secretKey);
  // Decrypting all the data
  if (decryptType === "all") {
    Object.entries(latestIpfsData).forEach((e) => {
      const [key, value] = e;
      if (value.isPublic === false) {
        value.data.data = symmetricDecryption(value.data.data, secretKey);
        value.data.encryptionType = "PUBLIC";
        value.isPublic = true;
      }

      if (key !== "techStack") {
        for (let i = 0; i < value.length; i++) {
          if (value[i].isPublic === false) {
            value[i].isPublic = true;
            Object.entries(value[i].data).forEach((e) => {
              const [key, value] = e;
              value.data = symmetricDecryption(value.data, secretKey);
              value.encryptionType = "PUBLIC";
            });
          }
        }
      }
    });
    store.dispatch({
      type: ADD_IPFS_DATA,
      payload: latestIpfsData,
    });
    setIsDecrypted(true);
  } else {
    // Decrypting single data
    if (typeof currentField?.id === "string") {
      latestIpfsData[currentField.id].data.data = symmetricDecryption(
        latestIpfsData[currentField.id].data.data,
        secretKey
      );
      latestIpfsData[currentField.id].isPublic = true;
    }
    // Decrypting data of arrays
    if (typeof currentField?.id === "number") {
      Object.entries(
        latestIpfsData[currentField.name][currentField.id].data
      ).forEach((e) => {
        const [key, value] = e;
        value.data = symmetricDecryption(value.data, secretKey);
      });
      latestIpfsData[currentField.name][currentField.id].isPublic = true;
    }
  }
  setShowDecryption(false);
};
