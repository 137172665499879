import { Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import { Text } from "../../components/shared/Typography/Typo";
import moment from "moment";
import Switch from "@mui/material/Switch";
import CredentialCard from "./CredentialCard";
import { urlToHash } from "../../utils/ipfs";

function Field({
  image,
  toggle,
  fieldName,
  setVerification,
  setShowAction,
  fieldData,
  addPadding,
  banner,
  key,
  basicField,
  status,
  fieldList,
  fieldNameOne,
  fieldNameTwo,
}) {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleActionOpen = () => {
    setShowAction(true);
  };

  const positionRef = useRef({
    x: 0,
    y: 0,
  });

  const popperRef = useRef(null);
  const areaRef = useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  if (basicField) {
    return (
      <FieldWrapper addPadding={addPadding} key={key}>
        <Text primary lighter small style={{ justifySelf: "start" }}>
          {fieldName}
        </Text>
        <FlexGap>
          <Text primary lighter small style={{ justifySelf: "start" }}>
            {fieldData}
          </Text>
        </FlexGap>
      </FieldWrapper>
    );
  } else if (toggle) {
    return (
      <FieldWrapper addPadding={addPadding} key={key}>
        <Text primary lighter small style={{ justifySelf: "start" }}>
          {fieldName}
        </Text>
        <FlexGap>
          <Text primary lighter small style={{ justifySelf: "start" }}>
            {fieldNameOne}
          </Text>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Text primary lighter small style={{ justifySelf: "start" }}>
            {fieldNameTwo}
          </Text>
        </FlexGap>
      </FieldWrapper>
    );
  } else if (banner) {
    return (
      <>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <div style={{ position: "relative" }}>
              <LargeImage src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(image)}`} alt="image" />
            </div>
          </ImageFlexContainer>
        </Modal>
        <ImageRounded
          loading="lazy"
          cursor
          onClick={() => {
            handleImageOpen();
          }}
          src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(image)}`}
          alt={"profile picture"}
        />
      </>
    );
  } else if (image) {
    return (
      <>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <div style={{ position: "relative" }}>
              <LargeImage src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(image)}`} alt="image" />
            </div>
          </ImageFlexContainer>
        </Modal>
        <FieldWrapper addPadding={addPadding} key={key}>
          <Text primary lighter small style={{ justifySelf: "start" }}>
            {fieldName}
          </Text>
          <FlexGap>
            <Image
              loading="lazy"
              cursor
              onClick={() => {
                handleImageOpen();
              }}
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(image)}`}
              alt={"image"}
            />
            <>
              {status === 1 ? (
                <Tooltip
                  placement="top"
                  arrow
                  title="Verified"
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        return new DOMRect(
                          positionRef.current.x,
                          areaRef.current.getBoundingClientRect().y,
                          0,
                          0
                        );
                      },
                    },
                  }}
                >
                  <StatusContainer
                    status={status}
                    ref={areaRef}
                    onMouseMove={handleMouseMove}
                  >
                    <Box color="#59D88C" />
                    <Box color="#59D88C" />
                  </StatusContainer>
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  placement="top"
                  title="Unverified"
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        return new DOMRect(
                          positionRef.current.x,
                          areaRef.current.getBoundingClientRect().y,
                          0,
                          0
                        );
                      },
                    },
                  }}
                >
                  <StatusContainer
                    onClick={() => {
                      handleActionOpen();
                      if (setVerification) {
                        setVerification({
                          type: fieldName,
                          fieldData: image,
                        });
                      }
                    }}
                    status={status}
                    ref={areaRef}
                    onMouseMove={handleMouseMove}
                  >
                    <Box color="#9b9b9b" />
                    <Box color="#9b9b9b" />
                  </StatusContainer>
                </Tooltip>
              )}
            </>
          </FlexGap>
        </FieldWrapper>
      </>
    );
  } else if (fieldList) {
    return (
      <FieldWrapper addPadding={addPadding} key={key}>
        <Text primary lighter small style={{ justifySelf: "start" }}>
          {fieldName}
        </Text>
        <FlexGap>
          <Column>
            {fieldList &&
              fieldList?.map((item, key) => {
                if (item?.data?.itemImage?.data) {
                  return (
                    <FlexGap>
                      <CredentialCard data={item?.data} />
                      <>
                        {item.status === 1 ? (
                          <Tooltip
                            title="Verified"
                            arrow
                            placement="top"
                            PopperProps={{
                              popperRef,
                              anchorEl: {
                                getBoundingClientRect: () => {
                                  return new DOMRect(
                                    positionRef.current.x,
                                    areaRef.current.getBoundingClientRect().y,
                                    0,
                                    0
                                  );
                                },
                              },
                            }}
                          >
                            <StatusContainer
                              status={item.status}
                              ref={areaRef}
                              onMouseMove={handleMouseMove}
                            >
                              <Box color="#59D88C" />
                              <Box color="#59D88C" />
                            </StatusContainer>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Unverified"
                            arrow
                            placement="top"
                            PopperProps={{
                              popperRef,
                              anchorEl: {
                                getBoundingClientRect: () => {
                                  return new DOMRect(
                                    positionRef.current.x,
                                    areaRef.current.getBoundingClientRect().y,
                                    0,
                                    0
                                  );
                                },
                              },
                            }}
                          >
                            <StatusContainer
                              status={item.status}
                              ref={areaRef}
                              onClick={() => {
                                handleActionOpen();
                                if (setVerification) {
                                  setVerification({
                                    type: fieldName,
                                    fieldData: item,
                                  });
                                }
                              }}
                              onMouseMove={handleMouseMove}
                            >
                              <Box color="#9b9b9b" />
                              <Box color="#9b9b9b" />
                            </StatusContainer>
                          </Tooltip>
                        )}
                      </>
                    </FlexGap>
                  );
                } else {
                  return (
                    <ListContainer key={key}>
                      <Text primary lighter small>
                        {item?.data?.courseName?.data
                          ? item?.data?.courseName?.data
                          : item?.data?.position?.data}
                      </Text>
                      <FlexGap style={{ gap: "0.5rem" }}>
                        {item?.data?.imageLogo?.data && (
                          <SmallLogo src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(item?.data?.imageLogo?.data)}`} />
                        )}
                        <Text primary lighter small>
                          {item?.data?.institutionName?.data
                            ? item?.data?.institutionName?.data
                            : item?.data?.company?.data}
                        </Text>
                      </FlexGap>
                      <Text primary lighter small>
                        {moment(Number(item?.data?.startDate?.data)).format(
                          "MM/DD/YYYY"
                        )}{" "}
                        -{" "}
                        {moment(Number(item?.data?.endDate?.data)).format(
                          "MM/DD/YYYY"
                        )}
                      </Text>
                      <>
                        {item.status === 1 ? (
                          <Tooltip
                            title="Verified"
                            arrow
                            placement="top"
                            PopperProps={{
                              popperRef,
                              anchorEl: {
                                getBoundingClientRect: () => {
                                  return new DOMRect(
                                    positionRef.current.x,
                                    areaRef.current.getBoundingClientRect().y,
                                    0,
                                    0
                                  );
                                },
                              },
                            }}
                          >
                            <StatusContainer
                              status={item.status}
                              ref={areaRef}
                              onMouseMove={handleMouseMove}
                            >
                              <Box color="#59D88C" />
                              <Box color="#59D88C" />
                            </StatusContainer>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Unverified"
                            arrow
                            placement="top"
                            PopperProps={{
                              popperRef,
                              anchorEl: {
                                getBoundingClientRect: () => {
                                  return new DOMRect(
                                    positionRef.current.x,
                                    areaRef.current.getBoundingClientRect().y,
                                    0,
                                    0
                                  );
                                },
                              },
                            }}
                          >
                            <StatusContainer
                              status={item.status}
                              ref={areaRef}
                              onClick={() => {
                                handleActionOpen();
                                if (setVerification) {
                                  setVerification({
                                    type: fieldName,
                                    fieldData: item,
                                  });
                                }
                              }}
                              onMouseMove={handleMouseMove}
                            >
                              <Box color="#9b9b9b" />
                              <Box color="#9b9b9b" />
                            </StatusContainer>
                          </Tooltip>
                        )}
                      </>
                    </ListContainer>
                  );
                }
              })}
          </Column>
        </FlexGap>
      </FieldWrapper>
    );
  } else {
    return (
      <FieldWrapper addPadding={addPadding} key={key}>
        <Text primary lighter small style={{ justifySelf: "start" }}>
          {fieldName}
        </Text>
        <FlexGap>
          <Text
            primary
            lighter
            small
            style={{
              justifySelf: "end",
              wordBreak: "break-word",
              textAlign: "left",
            }}
          >
            {fieldData}
          </Text>
          <>
            {status === 1 ? (
              <Tooltip
                title="Verified"
                arrow
                placement="top"
                PopperProps={{
                  popperRef,
                  anchorEl: {
                    getBoundingClientRect: () => {
                      return new DOMRect(
                        positionRef.current.x,
                        areaRef.current.getBoundingClientRect().y,
                        0,
                        0
                      );
                    },
                  },
                }}
              >
                <StatusContainer
                  status={status}
                  ref={areaRef}
                  onMouseMove={handleMouseMove}
                >
                  <Box color="#59D88C" />
                  <Box color="#59D88C" />
                </StatusContainer>
              </Tooltip>
            ) : (
              <Tooltip
                title="Unverified"
                arrow
                placement="top"
                PopperProps={{
                  popperRef,
                  anchorEl: {
                    getBoundingClientRect: () => {
                      return new DOMRect(
                        positionRef.current.x,
                        areaRef.current.getBoundingClientRect().y,
                        0,
                        0
                      );
                    },
                  },
                }}
              >
                <StatusContainer
                  status={status}
                  ref={areaRef}
                  onClick={() => {
                    handleActionOpen();
                    if (setVerification) {
                      setVerification({
                        type: fieldName,
                        fieldData: fieldData,
                      });
                    }
                  }}
                  onMouseMove={handleMouseMove}
                >
                  <Box color="#9b9b9b" />
                  <Box color="#9b9b9b" />
                </StatusContainer>
              </Tooltip>
            )}
          </>
        </FlexGap>
      </FieldWrapper>
    );
  }
}

export default Field;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.5rem 1rem")};
  background-color: #c3d0e1;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 2fr;
`;

const FlexGap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: ${(props) => props.status !== 1 && "pointer"};
`;

const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

export const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;

export const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 60vh;
  overflow: hidden;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ListContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 2fr 1.5fr 0.2fr;
  align-items: center;
  @media (max-width: 1368px) {
    display: flex;
    flex-direction: column;
    align-items: unset;
    background-color: #ffffff5a;
    border-radius: 5px;
    padding: 1rem;
  }
`;

const Column = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ImageRounded = styled.img`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  text-align: center;
`;

export const Label = styled.label`
  color: #080036;
  font-weight: 500;
`;

export const SmallLogo = styled.img`
  width: 1.5rem;
  height: auto;
  border-radius: 1px;
`;
