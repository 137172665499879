import { useState } from "react";
import styled from "styled-components";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles, withStyles } from "@mui/styles";
import { Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import Dropzone from "react-dropzone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NIFTRON } from "niftron-client-sdk";
import { useSnackbar } from "notistack";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const useStyles = makeStyles(() => ({
  TextField: {
    borderRadius: 5,
    color: "#ffffff !important",
    "& .Mui-error": {
      color: "rgb(244, 67, 54)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(244, 67, 54)",
    },
  },
}));

export const SecretComponentForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // setSecretKey
  const [secretKeyValues, setSecretKeyValues] = useState({
    secretKey: "",
    showSecretKey: false,
  });

  //Errors
  const [secretKeyError, setSecretKeyError] = useState(false);

  //functions
  const handleClickShowPassword = () => {
    setSecretKeyValues({
      ...secretKeyValues,
      showSecretKey: !secretKeyValues.showSecretKey,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Custom form validation
  const customValidation = (values) => {
    let errors = {};

    if (!values.secret_key) {
      errors.secret_key = "Required";
    }
    if (secretKeyError) {
      errors.secret_key = "Could not read Secret Key";
    }
    return errors;
  };

  // Form status
  const Login = async (data, actions) => {
    if (!secretKeyError && secretKeyValues.secretKey !== "") {
      try {
        actions.setSubmitting(true);
        //the jwt is already set as niftoken in local storage
        await NIFTRON.user.loginWithSecret(secretKeyValues.secretKey);
        window.location.assign("/");
        enqueueSnackbar("Logged in Successfully", {
          variant: "success",
        });
        //get user object if need to display from server
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      } finally {
        actions.setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        secret_key: "",
      }}
      validate={customValidation}
      onSubmit={async (values, actions) => {
        await Login(values, actions);
      }}
    >
      {/* Formik Props */}
      {({
        errors,
        isValid,
        dirty,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <ContainerForm style={{ rowGap: "1rem" }}>
          <TextField
            fullWidth
            size="small"
            variant="standard"
            disabled={isSubmitting}
            name="secret_key"
            label="Secret Key"
            id="secret_key"
            classes={{ root: classes.TextField }}
            value={secretKeyValues.secretKey}
            helperText={errors.secret_key ? errors.secret_key : null}
            error={errors.secret_key ? true : false}
            onBlur={handleBlur}
            InputLabelProps={{
              style: {
                color: "#fff",
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              onChange: function (e) {
                setSecretKeyValues({
                  ...secretKeyValues,
                  secretKey: e.target.value,
                });
                setFieldValue("secret_key", e.target.value);
              },
              style: { color: "#fff", padding: ".2rem" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disabled={isSubmitting}
                  >
                    {secretKeyValues.showSecretKey ? (
                      <Visibility style={{ color: "#fff" }} />
                    ) : (
                      <VisibilityOff style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: "new-password",
              type: secretKeyValues.showSecretKey ? "text" : "password",
            }}
          />
          <Dropzone
            onDrop={(acceptedFiles) => {
              const reader = new FileReader();
              reader.addEventListener("load", async () => {
                setFieldValue(
                  "secret_key",
                  JSON.parse(atob(reader.result)).secretKey
                );
                setSecretKeyValues({
                  ...secretKeyValues,
                  secretKey: JSON.parse(atob(reader.result)).secretKey,
                });
                setSecretKeyError(false);
              });
              reader.readAsText(acceptedFiles[0]);
            }}
            multiple={false}
            accept={[".niftron"]}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <DroppableContainer>
                <InnerContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Text small lighter>
                      Drop the files here ...
                    </Text>
                  ) : (
                    <Text small lighter>
                      Drop or select your Niftron credential file
                    </Text>
                  )}
                </InnerContainer>
              </DroppableContainer>
            )}
          </Dropzone>
          {isSubmitting ? (
            <LoadingContainer>
              <BorderLinearProgress />
              <Text small lighter style={{ width: "80px" }}>
                Verifying <span className="loading"></span>
              </Text>
            </LoadingContainer>
          ) : (
            <CustomButton
              style={{ marginTop: "2rem" }}
              disabled={!isValid || (isSubmitting && dirty)}
              onclick={handleSubmit}
              text="Sign In"
              login
              loginIcon
              loginClass
            />
          )}
        </ContainerForm>
      )}
    </Formik>
  );
};

export const DroppableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

export const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #dddddda4;
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #dddddd37;
`;

export const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-evenly;
`;

export const LoadingContainer = styled.div`
  height: 48px;
  width: 100%;
  margin-top: 2rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

// const InnerContainer = styled.div`
//   height: 8rem;
//   text-align: center;
//   word-wrap: break-word;
//   border: dashed 0.75px #ae9bf0a3;
//   width: 100%;
//   display: flex;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
//   background-color: #c3d0e147;
// `;
// const DroppableContainer = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   row-gap: 1rem;
//   align-items: center;
//   justify-content: space-evenly;
//   position: "relative";
// `;
