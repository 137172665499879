import React from "react";
import { PDFReader } from "reactjs-pdf-reader";

const PDFViewer = (props) => {
  return (
    <div style={{ overflow: "scroll", height: 600 }}>
      <PDFReader showAllPage="true" url={props.url} />
    </div>
  );
};

export default PDFViewer;
