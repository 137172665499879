import React from "react";
import styled from "styled-components";
import BgOne from "../../assets/images/bg-1.jpg";
import { LoginBanner } from "../Login/LoginBanner";
import { GridContainer } from "../SignUp/SignUpPageStyles";
import { ResetComponent } from "./ResetComponent";

const ResetPassword = () => {
  return (
    <Container>
      <GridContainer>
        <ResetComponent />
        <LoginBanner />
      </GridContainer>
    </Container>
  );
};

export default ResetPassword;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  backface-visibility: hidden;
  /* background-image: url(${BgOne}); */
  @media (max-width: 768px) {
    height: auto;
  }
`;
