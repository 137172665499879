import { useState } from "react";
import styled from "styled-components";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import { makeStyles, withStyles } from "@mui/styles";
import { Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import Dropzone from "react-dropzone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { passwordReset } from "../../api/API";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const useStyles = makeStyles(() => ({
  TextField: {
    borderRadius: 5,
    color: "#ffffff !important",
    "& .Mui-error": {
      color: "rgb(244, 67, 54)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(244, 67, 54)",
    },
  },
}));

export const ResetForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // setSecretKey
  const [secretKeyValues, setSecretKeyValues] = useState({
    secretKey: "",
    showSecretKey: false,
  });

  //Errors
  const [secretKeyError, setSecretKeyError] = useState(false);

  //functions
  const handleClickShowPassword = () => {
    setSecretKeyValues({
      ...secretKeyValues,
      showSecretKey: !secretKeyValues?.showSecretKey,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Custom form validation
  const customValidation = (values) => {
    let errors = {};

    if (secretKeyError) {
      errors.secret_key = "Could not read Secret Key";
    }
    return errors;
  };

  const handlePasswordReset = async (values) => {
    // If using email
    if (values.email !== "") {
      try {
        // Password reset API
        const response = await passwordReset({
          body: {
            email: values.email,
          },
        });
        if (response.data.code === 200) {
          enqueueSnackbar(
            "Click the link in the email to change your password",
            {
              variant: "success",
            }
          );
        }
        if (response.data.code === 404) {
          enqueueSnackbar("Couldn't get user", {
            variant: "error",
          });
        }
      } catch (e) {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      }
    }
    // If using secretKey
    if (values.secret_key !== "") {
      const creObj = {
        secretKey: values.secret_key,
      };
      const objJsonB64 = Buffer.from(JSON.stringify(creObj)).toString("base64");

      window.open(`/new-password/${objJsonB64}`, "_self");
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        secret_key: "",
      }}
      validate={customValidation}
      onSubmit={async (values, actions) => {
        handlePasswordReset(values);
      }}
    >
      {/* Formik Props */}
      {({
        values,
        touched,
        errors,
        isValid,
        dirty,
        isSubmitting,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <ContainerForm style={{ rowGap: "1rem" }}>
          {/* Alias */}
          <TextField
            fullWidth
            size="small"
            variant="standard"
            name="email"
            id="email"
            label="Email"
            classes={{ root: classes.TextField }}
            disabled={isSubmitting}
            value={values.email}
            type="alias"
            helperText={errors.email && touched.email ? errors.email : null}
            error={errors.email && touched.email ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            InputLabelProps={{
              style: {
                color: "#fff",
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              style: { color: "#fff", padding: ".2rem" },
            }}
          />
          or
          <TextField
            fullWidth
            size="small"
            variant="standard"
            disabled={isSubmitting}
            name="secret_key"
            label="Secret Key"
            id="secret_key"
            classes={{ root: classes.TextField }}
            value={secretKeyValues?.secretKey}
            helperText={errors.secret_key ? errors.secret_key : null}
            error={errors.secret_key ? true : false}
            onBlur={handleBlur}
            InputLabelProps={{
              style: {
                color: "#fff",
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              onChange: function (e) {
                setSecretKeyValues({
                  ...secretKeyValues,
                  secretKey: e.target.value,
                });
                setFieldValue("secret_key", e.target.value);
              },
              style: { color: "#fff", padding: ".2rem" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disabled={isSubmitting}
                  >
                    {secretKeyValues?.showSecretKey ? (
                      <Visibility style={{ color: "#fff" }} />
                    ) : (
                      <VisibilityOff style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: "new-password",
              type: secretKeyValues?.showSecretKey ? "text" : "password",
            }}
          />
          <Dropzone
            onDrop={(acceptedFiles) => {
              const reader = new FileReader();
              reader.addEventListener("load", async () => {
                setFieldValue(
                  "secret_key",
                  JSON.parse(atob(reader.result)).secretKey
                );
                setSecretKeyValues({
                  ...secretKeyValues,
                  secretKey: JSON.parse(atob(reader.result)).secretKey,
                });
                setSecretKeyError(false);
              });
              reader.readAsText(acceptedFiles[0]);
            }}
            multiple={false}
            accept={[".niftron"]}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <DroppableContainer>
                <InnerContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Text small lighter>
                      Drop the files here ...
                    </Text>
                  ) : (
                    <Text small lighter>
                      Drop or select your Niftron credential file
                    </Text>
                  )}
                </InnerContainer>
              </DroppableContainer>
            )}
          </Dropzone>
          {isSubmitting ? (
            <LoadingContainer>
              <BorderLinearProgress />
              <Text small lighter style={{ width: "80px" }}>
                Verifying <span className="loading"></span>
              </Text>
            </LoadingContainer>
          ) : (
            <CustomButton
              style={{ marginTop: "2rem" }}
              disabled={
                !isValid ||
                (isSubmitting && dirty) ||
                (values.email !== "" && values.secret_key !== "")
              }
              onclick={handleSubmit}
              text="Reset Password"
              login
              loginIcon
              loginClass
            />
          )}
        </ContainerForm>
      )}
    </Formik>
  );
};

export const DroppableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

export const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #dddddda4;
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #dddddd37;
`;

export const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-evenly;
`;

export const LoadingContainer = styled.div`
  height: 48px;
  width: 100%;
  margin-top: 2rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

// const InnerContainer = styled.div`
//   height: 8rem;
//   text-align: center;
//   word-wrap: break-word;
//   border: dashed 0.75px #ae9bf0a3;
//   width: 100%;
//   display: flex;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
//   background-color: #c3d0e147;
// `;
// const DroppableContainer = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   row-gap: 1rem;
//   align-items: center;
//   justify-content: space-evenly;
//   position: "relative";
// `;
