import { Divider } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IconWrapper } from "../../../App";
import { Text } from "../Typography/Typo";
import { FlexContainer } from "./ShareConfirm";
import EncryptedData from "./EncryptedData";
import VerifiedStatusContainer from "./VerifiedStatusContainer";

const ContactInfo = ({
  profileData,
  setShowAction,
  setVerification,
  setCurrentField,
  setShowEncryption,
  setShowDecryption,
}) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [isPublic, setIsPublic] = useState(false);

  // Creating a name header
  const headerName = () => {
    if (profileData?.fullName?.data?.data) {
      return (
        <HeaderNameWrapper>
          {profileData?.firstName?.isPublic ? (
            <Text primary>{`${profileData?.firstName?.data?.data}`}</Text>
          ) : (
            <EncryptedData
              fieldData={`${profileData?.firstName?.data?.data}`}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={`${profileData?.firstName?.data?.data}`}
            fieldName="Name"
            fieldId={{ id: "firstName" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.firstName?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.firstName?.data?.encryptionType}
          />

          {profileData?.lastName?.isPublic ? (
            <Text primary>{`${profileData?.lastName?.data?.data}`}</Text>
          ) : (
            <EncryptedData
              fieldData={`${profileData?.lastName?.data?.data}`}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={`${profileData?.lastName?.data?.data}`}
            fieldName="Name"
            fieldId={{ id: "lastName" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.lastName?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.lastName?.data?.encryptionType}
          />
        </HeaderNameWrapper>
      );
    }
    if (profileData?.name?.data?.data) {
      return (
        <HeaderNameWrapper>
          {profileData?.name?.isPublic ? (
            <Text primary>{`${profileData?.name?.data?.data}`}</Text>
          ) : (
            <EncryptedData
              fieldData={`${profileData?.name?.data?.data}`}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={`${profileData?.name?.data?.data}`}
            fieldName="Name"
            fieldId={{ id: "name" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.name?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.name?.data?.encryptionType}
          />
        </HeaderNameWrapper>
      );
    }
    if (profileData?.productName?.data?.data) {
      return (
        <HeaderNameWrapper>
          {profileData?.productName?.isPublic ? (
            <Text primary>{`${profileData?.productName?.data?.data}`}</Text>
          ) : (
            <EncryptedData
              fieldData={`${profileData?.productName?.data?.data}`}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={`${profileData?.productName?.data?.data}`}
            fieldName="Product Name"
            fieldId={{ id: "productName" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.productName?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.productName?.data?.encryptionType}
          />
        </HeaderNameWrapper>
      );
    }
  };

  // Creating address
  const address = () => {
    return (
      <Link
        primary
        lighter
        target="_blank"
        small
        href={
          profileData?.addressLineOne?.isPublic &&
          profileData?.addressLineTwo?.isPublic &&
          profileData?.city?.isPublic &&
          profileData?.country?.isPublic
            ? `https://www.google.com/maps/search/?api=1${profileData?.addressLineOne?.data?.data},${profileData?.addressLineTwo?.data?.data}`
            : null
        }
      >
        <AddressWrapper>
          {/* For addressLineOne */}
          {profileData?.addressLineOne?.isPublic ? (
            profileData?.addressLineOne?.data?.data
          ) : (
            <EncryptedData
              fieldData={profileData?.addressLineOne?.data?.data}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={profileData?.addressLineOne?.data?.data}
            fieldName="addressLineOne"
            fieldId={{ id: "addressLineOne" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.addressLineOne?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.addressLineOne?.data?.encryptionType}
          />
          ,{/* For addressLineTwo */}
          {profileData?.addressLineTwo?.isPublic ? (
            profileData?.addressLineTwo?.data?.data
          ) : (
            <EncryptedData
              fieldData={profileData?.addressLineTwo?.data?.data}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={profileData?.addressLineTwo?.data?.data}
            fieldName="addressLineTwo"
            fieldId={{ id: "addressLineTwo" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.addressLineTwo?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.addressLineTwo?.data?.encryptionType}
          />
          ,{/* For city */}
          {profileData?.city?.isPublic ? (
            profileData?.city?.data?.data
          ) : (
            <EncryptedData
              fieldData={profileData?.city?.data?.data}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={profileData?.city?.data?.data}
            fieldName="city"
            fieldId={{ id: "city" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.city?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.city?.data?.encryptionType}
          />
          ,{/* For country */}
          {profileData?.country?.isPublic ? (
            profileData?.country?.data?.data
          ) : (
            <EncryptedData
              fieldData={profileData?.country?.data?.data}
              setShowDecryption={setShowDecryption}
            />
          )}
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={profileData?.country?.data?.data}
            fieldName="country"
            fieldId={{ id: "country" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            isDecrypted={profileData?.country?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            setShowDecryption={setShowDecryption}
            encryptedType={profileData?.country?.data?.encryptionType}
          />
        </AddressWrapper>
      </Link>
    );
  };

  return (
    <ContactContainer>
      <FlexContainer>
        <Text primary>{headerName()}</Text>
        <Text primary lighter>
          Contact Info
        </Text>
      </FlexContainer>
      <Divider
        style={{
          background: "#36353511",
          width: "100%",
          margin: "0.5rem 0rem",
        }}
      />
      <ContactInnerColumnFlex>
        <ContactFlexContainer addPadding>
          <IconOuter>
            <IconWrapper>
              <span className="material-icons ico-md">{"account_circle"}</span>
            </IconWrapper>
          </IconOuter>
          <FlexColumn>
            <Text primary>Your Profile on DePro Explorer</Text>{" "}
            <Link
              primary
              lighter
              small
              href={`http://explorer.deprofile.io/profile/${currentUser?.publicKey}`}
              target="_blank"
            >
              http://explorer.deprofile.io/profile/publicKey
            </Link>
          </FlexColumn>
        </ContactFlexContainer>
        <ContactFlexContainer addPadding>
          <IconOuter>
            <IconWrapper>
              <span className="material-icons ico-md">{"phone"}</span>
            </IconWrapper>
          </IconOuter>
          <FlexColumn>
            <Text primary>Phone</Text>{" "}
            <HeaderNameWrapper>
              {profileData?.phoneNumber?.isPublic ? (
                <Link
                  primary
                  lighter
                  small
                  href={`tel:${profileData?.phoneNumber?.data?.data}`}
                >
                  {profileData?.phoneNumber?.data?.data}
                </Link>
              ) : (
                <EncryptedData
                  fieldData={profileData?.phoneNumber?.data?.data}
                  setShowDecryption={setShowDecryption}
                />
              )}
              <VerifiedStatusContainer
                status={0}
                isPublic={isPublic}
                fieldData={profileData?.phoneNumber?.data?.data}
                fieldName="phoneNumber"
                fieldId={{ id: "phoneNumber" }}
                setShowAction={setShowAction}
                setVerification={setVerification}
                isDecrypted={profileData?.phoneNumber?.isPublic}
                setCurrentField={setCurrentField}
                setShowEncryption={setShowEncryption}
                setShowDecryption={setShowDecryption}
                encryptedType={profileData?.phoneNumber?.data?.encryptionType}
              />
            </HeaderNameWrapper>
          </FlexColumn>
        </ContactFlexContainer>{" "}
        {profileData?.addressLineOne?.data?.data && (
          <ContactFlexContainer addPadding>
            <IconOuter>
              <IconWrapper>
                <span className="material-icons ico-md">{"location_on"}</span>
              </IconWrapper>
            </IconOuter>
            <FlexColumn>
              <Text primary>Address</Text> {address()}
            </FlexColumn>
          </ContactFlexContainer>
        )}
        {currentUser?.email && (
          <ContactFlexContainer addPadding>
            <IconOuter>
              <IconWrapper>
                <span className="material-icons ico-md">{"email"}</span>
              </IconWrapper>
            </IconOuter>
            <FlexColumn>
              <Text primary>Email</Text>{" "}
              <Link primary lighter small href={`mailto:${currentUser?.email}`}>
                {currentUser?.email}
              </Link>
            </FlexColumn>
          </ContactFlexContainer>
        )}
      </ContactInnerColumnFlex>
    </ContactContainer>
  );
};

export default ContactInfo;

export const IconOuter = styled.div`
  background: linear-gradient(180deg, #2f3a60 0%, #1d2439 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;
  height: 2.25rem;
  padding: 0.39rem 0.1rem 0.1rem 0.1rem;
  width: 2.5rem;
  min-width: 2.5rem;
`;

export const ContactContainer = styled.div`
  position: absolute;
  top: 50%;
  outline: none;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 23rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ContactInnerColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.2rem;
`;

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ContactFlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 0.7rem, 0rem;
`;

const Link = styled.a`
  max-width: 20rem;
  cursor: pointer;
  word-break: break-all;
  text-decoration: underline;
  color: #1696ff;
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "16px")};
  font-weight: ${(props) => (props.lighter ? "400" : "500")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
