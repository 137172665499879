import axios from "axios";
import {
  Server,
  TransactionBuilder,
  Keypair,
  Networks,
  Operation,
} from "stellar-sdk";

export async function passwordReset(secretKey, encryptedSecret) {
  console.log("hai", process.env.REACT_APP_NIFTRON_API);
  try {
    let keypair;
    try {
      keypair = Keypair.fromSecret(secretKey);
    } catch (err) {
      console.lof(err);
      return null;
    }

    const { xdr } = await buildLoginXDR(keypair);
    if (xdr === null) {
      return null;
    }

    //console.log(xdr)
    let postBody = {
      xdr,
      encryptedSecret,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_NIFTRON_API}/users/passwordReset`,
      postBody,
      {
        headers: {
          // 'Authorization': "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res != null) {
      if (res.status === 200) {
        console.log("changed");
        // localStorage.setItem("keypair", JSON.stringify(keypair))
        localStorage.setItem("secretKey", keypair.secret());

        localStorage.setItem("token", res.data.data.token);

        return res.status;
      } else {
        return res.status;
      }
    } else {
      return null;
    }
  } catch (err) {
    //console.log(err)
    return null;
  }
}

export async function buildLoginXDR(keypair) {
  try {
    let server = new Server(process.env.REACT_APP_STELLA_URL);
    let sourceAccount;
    let networkPassphrase;
    try {
      sourceAccount = await server.loadAccount(keypair.publicKey());
      networkPassphrase = Networks.PUBLIC;
    } catch (err1) {
      try {
        server = new Server(process.env.REACT_APP_STELLA_URL_TEST);
        sourceAccount = await server.loadAccount(keypair.publicKey());
        networkPassphrase = Networks.TESTNET;
      } catch (err2) {
        //if testnet doesn't have the account redo
        // //fund main account
        await fundAccountInTestnet(keypair.publicKey());
      }
    }
    let transaction = new TransactionBuilder(sourceAccount, {
      fee: "200",
      networkPassphrase: networkPassphrase,
    })
      .addOperation(
        Operation.manageData({ name: "login", value: new Date().toUTCString() })
      )
      // .addOperation(Operation.manageData({ name: "login", value: null }))
      .setTimeout(0)
      .build();
    transaction.sign(keypair);
    // transaction.hash().toString('hex')
    return { xdr: transaction.toEnvelope().toXDR("base64"), reactivate: false };
  } catch (e) {
    //console.log(e)
    return null;
  }
}

export const fundAccountInTestnet = async (publicKey) => {
  try {
    const STELLAT_FRIEND_BOT_URL = `https://friendbot.stellar.org/?addr=`;
    const stellarResponse = await axios.get(
      `${STELLAT_FRIEND_BOT_URL}${publicKey}`
    );

    if (stellarResponse !== null && stellarResponse.status !== 200) {
      return null;
    }
    return true;
    // }
  } catch (err) {
    return null;
  }
};
