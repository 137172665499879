import { useState } from "react";
import styled from "styled-components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import { Text } from "../../components/shared/Typography/Typo";
import CopyPasteComponent from "./CopyPasteComponent";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";

var FileSaver = require("file-saver");

const CredentialsPage = ({ userData }) => {
  const [canProceed, setCanProceed] = useState(false);

  return (
    <Container>
      <Column>
        <CopyPasteComponent
          secretKey={userData?.publicKey}
          label="Public Key"
        />
      </Column>
      <Column>
        <CopyPasteComponent
          secretKey={userData?.secretKey}
          label="Secret Key"
        />
      </Column>
      <HeadingWrapper>
        <Text lighter small color="yellow">
          Here are your blockchain credentials, please export it before you
          continue.
        </Text>
      </HeadingWrapper>
      <ButtonContainer>
        <CustomButton
          login
          wide
          text="Export to File"
          icon={<SaveAltIcon />}
          onclick={() => {
            var credObj = {
              publicKey: userData?.publicKey,
              secretKey: userData?.secretKey,
            };
            var blob = new Blob([btoa(JSON.stringify(credObj))], {
              type: "text/plain;charset=utf-8",
            });
            FileSaver.saveAs(blob, `${userData?.alias}_credential.niftron`);
            setCanProceed(true);
          }}
        />
        <CustomButton
          disabled={!canProceed}
          tab
          secondTab
          wide
          text="Continue"
          icon={canProceed ? <ChevronRightIcon /> : null}
          onclick={() => {
            window.location.assign("/");
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

export default CredentialsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
`;

const Column = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin: 1rem 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 3rem 0rem;
  @media only screen and (max-width: 542px) {
    flex-direction: column;
    margin: 3rem 0.5rem;
    min-width: 70vw;
    max-width: 70vw;
  }
  @media (max-width: 768px) {
    min-width: 85vw;
    max-width: 85vw;
  }
  @media (max-width: 660px) {
    min-width: 75vw;
    max-width: 75vw;
  }
  @media (max-width: 375px) {
    min-width: 65vw;
    max-width: 65vw;
  }
`;

const HeadingWrapper = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    min-width: 80vw;
    max-width: 80vw;
  }
  @media (max-width: 600px) {
    min-width: 75vw;
    max-width: 75vw;
  }
  @media (max-width: 375px) {
    min-width: 65vw;
    max-width: 65vw;
  }
`;
