export const initialstate = {
  fields: [],
  customData: [],
  stageFields: [],
  stageCustomData: [],
  products: [],
  authConfirm: false,
  educationFields: [],
  workFields: [],
  achievementFields: [],
  searchData: {},
  privacyData: [],
  customPrivacyData: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_AUTHORITY":
      return {
        ...state,
        searchData: action.payload,
      };

    case "REMOVE_AUTHORITY":
      return {
        ...state,
        searchData: {},
      };

    case "ADD_FIELD":
      return {
        ...state,
        fields: [...state.fields, action.item],
      };

    case "ADD_PRIVACY":
      return {
        ...state,
        privacyData: [...state.privacyData, action.item],
      };

    case "ADD_PRIVACY_CUSTOM":
      return {
        ...state,
        customPrivacyData: [...state.customPrivacyData, action.item],
      };

    case "EDIT_PRIVACY":
      const updatedField = action.item;

      const updatedFields = state.privacyData.map((data) => {
        if (data._id === updatedField._id) {
          return updatedField;
        }
        return data;
      });

      return {
        ...state,
        privacyData: updatedFields,
      };
    case "EDIT_PRIVACY_CUSTOM":
      const updatedFieldCustom = action.item;

      const updatedFieldsCustom = state.customPrivacyData.map((data) => {
        if (data._id === updatedFieldCustom._id) {
          return updatedFieldCustom;
        }
        return data;
      });

      return {
        ...state,
        customPrivacyData: updatedFieldsCustom,
      };

    case "ADD_CUSTOM_DATA_FIELDS":
      return {
        ...state,
        customData: [...state.customData, action.item],
      };

    case "ADD_STAGE_CUSTOM_DATA_FIELDS":
      return {
        ...state,
        stageCustomData: [...state.stageCustomData, action.item],
      };
    case "ADD_STAGE_FIELD":
      return {
        ...state,
        stageFields: [...state.stageFields, action.item],
      };
    case "ADD_PRODUCTS":
      return {
        ...state,
        products: [...state.products, action.item],
      };

    //FormFields

    case "ADD_EDUCATION_FIELDS":
      return {
        ...state,
        educationFields: [...state.educationFields, action.item],
      };
    case "ADD_ACHIEVEMENT_FIELDS":
      return {
        ...state,
        achievementFields: [...state.achievementFields, action.item],
      };
    case "ADD_WORK_FIELDS":
      return {
        ...state,
        workFields: [...state.workFields, action.item],
      };

    case "REMOVE_EDU_FIELD":
      const eduId = state.educationFields.findIndex(
        (fieldsItem) => fieldsItem.id === action.id
      );

      let newEduFields = [...state.educationFields];
      if (eduId >= 0) {
        newEduFields.splice(eduId, 1);
      } else {
        console.warn(`Cannot remove the requested field`);
      }
      return {
        ...state,
        educationFields: newEduFields,
      };
    case "REMOVE_ACHIEVEMENT_FIELD":
      const achiId = state.achievementFields.findIndex(
        (fieldsItem) => fieldsItem.id === action.id
      );

      let newAchiFields = [...state.achievementFields];
      if (achiId >= 0) {
        newAchiFields.splice(achiId, 1);
      } else {
        console.warn(`Cannot remove the requested field`);
      }
      return {
        ...state,
        achievementFields: newAchiFields,
      };

    case "REMOVE_WORK_FIELD":
      const workId = state.workFields.findIndex(
        (fieldsItem) => fieldsItem.id === action.id
      );

      let newWorkFields = [...state.workFields];
      if (workId >= 0) {
        newWorkFields.splice(workId, 1);
      } else {
        console.warn(`Cannot remove the requested field`);
      }
      return {
        ...state,
        workFields: newWorkFields,
      };

    case "REMOVE_FIELD":
      const index = state.fields.findIndex(
        (fieldsItem) => fieldsItem.name === action.id
      );

      let newFields = [...state.fields];
      if (index >= 0) {
        newFields.splice(index, 1);
      } else {
        console.warn(`Cannot remove the requested field (id: ${action.id}`);
      }
      return {
        ...state,
        fields: newFields,
      };

    case "REMOVE_STAGE_FIELD":
      const stageIndex = state.stageFields.findIndex(
        (fieldsItem) => fieldsItem.name === action.id
      );

      let newStageFields = [...state.stageFields];
      if (stageIndex >= 0) {
        newStageFields.splice(stageIndex, 1);
      } else {
        console.warn(`Cannot remove the requested field (id: ${action.id}`);
      }
      return {
        ...state,
        stageFields: newStageFields,
      };

    case "REMOVE_PRODUCT":
      const key = state.products.findIndex(
        (productsItem) => productsItem.id === action.id
      );

      let newProducts = [...state.products];
      if (key >= 0) {
        newProducts.splice(key, 1);
      } else {
        console.warn(`Cannot remove the requested products (id: ${action.id}`);
      }
      return {
        ...state,
        products: newProducts,
      };

    case "EDIT_PRODUCT":
      const updatedProduct = action.payload;

      const updatedProducts = state.products.map((product) => {
        if (product.productId === updatedProduct.productId) {
          return updatedProduct;
        }

        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };

    case "GET_PRODUCT":
      const productId = action.id;
      const productWithId = state.products.map((product) => {
        if (product.productId === productId) {
          return product;
        }
        return product;
      });

      return {
        ...state,
        product: productWithId,
      };

    case "CLEAR_FIELDS":
      return {
        ...state,
        fields: [],
      };

    case "CLEAR_CUSTOM_DATA_FIELDS":
      return {
        ...state,
        customData: [],
      };
    case "CLEAR_STAGE_CUSTOM_DATA_FIELDS":
      return {
        ...state,
        stageCustomData: [],
      };

    case "CLEAR_STAGE_FIELDS":
      return {
        ...state,
        stageFields: [],
      };

    case "CLEAR_EDU_FIELDS":
      return {
        ...state,
        educationFields: [],
      };
    case "CLEAR_ACHIEVEMENT_FIELDS":
      return {
        ...state,
        achievementFields: [],
      };
    case "CLEAR_WORK_FIELDS":
      return {
        ...state,
        workFields: [],
      };

    case "CLEAR_PRODUCTS":
      return {
        ...state,
        products: [],
      };
    case "CLEAR_PRIVACY":
      return {
        ...state,
        privacyData: [],
      };
    // case "REMOVE_DUPLICATES":
    //   const titleIndex = state.basket.findIndex((basketItem) =>
    //     basketItem.domainLevel === "TLD"
    //       ? basketItem.name === action.title
    //       : basketItem.name + `.` + basketItem.tld === action.title
    //   );

    //   let updatedBasket = [...state.basket];
    //   if (titleIndex >= 0) {
    //     updatedBasket.splice(titleIndex, 1);
    //   }
    //   return {
    //     ...state,
    //     basket: updatedBasket,
    //   };
    default:
      return state;
  }
};

export default reducer;
