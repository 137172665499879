import React, { lazy, Suspense, useState } from "react";
import styled from "styled-components";
import { ButtonWrapper, ColumnContainer } from "../../Form/Web3ResumeContainer";
import { FlexSpaceBetweenContainer } from "../../Profile/UserProfile";
import { SubHeading } from "../../Typography/Typo";
import { CustomButton } from "../../fields/Button/CustomButton";
import { IconWrapper } from "../../Profile/UserProfileHeader";
import { CircularProgress, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { OverLayContainer } from "../EditForm";
import { useStateValue } from "../../../../context/StateProvider";

const WorkDataEditForm = lazy(() => import("./WorkDataEditForm"));

function MultiWorkDataEditComponent({
  setOverLay,
  setStatus,
  workData,
  setDeleteData,
  datas,
}) {
  const [{ workFields }, dispatch] = useStateValue();
  const [data, setData] = useState(datas);
  const handleDelete = (key) => {
    const newWorkData = data.filter((item) => item.data.id.data !== key);

    setData(newWorkData);
    setDeleteData(newWorkData);
  };

  const closeEdit = () => {
    dispatch({
      type: "CLEAR_WORK_FIELDS",
    });
    setOverLay(false);
  };

  return (
    <>
      <OverLayContainer onClick={() => closeEdit()} />
      <Container>
        <OverFlowContainer style={{ width: "90%" }}>
          <FlexSpaceBetweenContainer
            style={{
              width: "95%",
              position: "absolute",
              left: "0",
              top: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              zIndex: "1",
            }}
          >
            <SubHeading style={{ padding: "2rem 3rem" }} primary>
              Edit Work Data
            </SubHeading>{" "}
            <IconWrapper style={{ backgroundColor: "#7271713b" }}>
              <IconButton onClick={() => setStatus(1)}>
                <AddIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              </IconButton>
            </IconWrapper>
          </FlexSpaceBetweenContainer>
          <ColumnContainer
            style={{
              gap: "2rem",
              width: "100%",
            }}
          >
            <Suspense
              fallback={
                <LoadingContainer>
                  <CircularProgress size={40} />
                </LoadingContainer>
              }
            >
              {data?.length > 0 &&
                data.map((data, index) => {
                  return (
                    <WorkDataEditForm
                      key={data.data.id.data}
                      disabled={
                        data.verifiedBy.find(
                          (item) => item?.verificationStatus === 0
                        )
                          ? true
                          : false
                      }
                      id={data.data.id.data}
                      onDelete={handleDelete}
                      initialCompany={data?.data?.company.data}
                      initialPosition={data?.data?.position.data}
                      initialDescription={data?.data?.description.data}
                      initialStartWorkDate={Number(data?.data?.startDate.data)}
                      initialEndWorkDate={Number(data?.data?.endDate.data)}
                      initialLogoImage={data.data.imageLogo.data}
                      isPublic={Boolean(
                        data.data.isPublic.data === "true" ? true : false
                      )}
                      initialData={data}
                      workData={workData[index]}
                    />
                  );
                })}
            </Suspense>
          </ColumnContainer>
          <ColumnContainer
            style={{
              width: "100%",
              position: "absolute",
              right: "0",
              bottom: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <ButtonWrapper style={{ gap: "1rem", padding: "2rem 3rem" }}>
              <CustomButton
                login
                text="Cancel"
                rejectClass
                type="button"
                onclick={() => closeEdit()}
              />
              <CustomButton
                login
                text="Save Changes"
                type="submit"
                // disabled={workFields.length <= 0}
                onclick={() => setStatus(4)}
              />
            </ButtonWrapper>
          </ColumnContainer>{" "}
        </OverFlowContainer>
      </Container>
    </>
  );
}

export default MultiWorkDataEditComponent;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 50rem;
  z-index: 999;
  height: 35rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1468px) {
    width: 80%;
    padding: 2rem 0rem;
  }
  @media (max-width: 768px) {
    width: 80%;
    padding: 2rem 0.5rem;
  }
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

export const OverFlowContainer = styled.div`
  overflow: auto;
  display: flex;
  width: max-content;
  padding: 0rem 1.5rem 2rem 1.5rem;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0rem 1rem 1rem 1rem;
  }
`;
