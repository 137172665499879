import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import ShoppingIcon from "../../assets/images/shopping-cart.png";
import JobView from "./JobView";

const JobListings = () => {
  return (
    <Container>
      <ButtonContainer
        heading="Job Listings"
        description="Find the list of jobs posted by companies here"
        icon={"work"}
      />
      <JobView />
    </Container>
  );
};

export default JobListings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
