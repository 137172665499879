import jwt from "jsonwebtoken";

export function jwtDecode() {
  if (localStorage.getItem("niftoken")) {
    const token = localStorage.getItem("niftoken");
    const decodedToken = jwt.decode(token);
    return decodedToken;
  }
  return null;
}
