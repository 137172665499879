import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUserSession } from "../../../../api/API";
import { useStateValue } from "../../../../context/StateProvider";
import { dispatchEditObject, getArrayData } from "../../../../utils/editForm";
import { useHistory } from "react-router-dom";
import ActionConfirm from "../../Form/FormComponents/ActionConfirm";
import AddEduDataEditComponent from "./AddEduDataEditComponent";
import MultiEduDataEditComponent from "./MultiEduDataEditComponent";

function EduEditSwitch({ setOverLay }) {
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const ipfsDataCopy = useSelector((state) => state.ipfsDataCopy);
  const [eduData, setEduData] = useState(
    getArrayData(latestIpfsData?.educationData)
  );
  const [status, setStatus] = useState(eduData?.length <= 0 ? 1 : 2);
  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [{ educationFields }, dispatch] = useStateValue();
  const [deleteData, setDeleteData] = useState();

  const currentUser = useSelector((state) => state.currentUser);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleEditEdu = (secretkey) => {
    let productObject = {};
    try {
      if (educationFields?.length > 0) {
        let updatedData = latestIpfsData?.educationData.map(
          (obj) =>
            educationFields.find((o) => o.data.id.data === obj.data.id.data) ||
            obj
        );

        productObject.educationData = updatedData;
      } else {
        if (deleteData) {
          latestIpfsData.educationData = deleteData;
          productObject.educationData = deleteData;
        }
      }

      if (productObject.educationData) {
        dispatchEditObject(
          secretkey,
          setLoading,
          productObject,
          [],
          currentUser,
          enqueueSnackbar,
          "eduData-edit",
          ipfsDataCopy.length !== 0 ? ipfsDataCopy : latestIpfsData,
          setOverLay,
          history
        );
      } else {
        dispatch({
          type: "CLEAR_EDU_FIELDS",
        });
        setOverLay(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: "CLEAR_EDU_FIELDS",
      });
    }
  };

  const handleAddEdu = (secretkey) => {
    let productObject = {};
    try {
      productObject.educationData = educationFields;

      dispatchEditObject(
        secretkey,
        setLoading,
        productObject,
        [],
        currentUser,
        enqueueSnackbar,
        "eduData-add",
        ipfsDataCopy.length !== 0 ? ipfsDataCopy : latestIpfsData,
        setOverLay,
        history
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {(() => {
        switch (status) {
          case 1:
            return (
              <AddEduDataEditComponent
                setOverLay={setOverLay}
                setStatus={setStatus}
                showEdit={eduData?.length <= 0}
              />
            );
          case 2:
            return (
              <MultiEduDataEditComponent
                setOverLay={setOverLay}
                setStatus={setStatus}
                eduData={eduData}
                datas={latestIpfsData.educationData}
                setDeleteData={setDeleteData}
              />
            );
          case 3:
            return (
              <ActionConfirm
                successMessage={{
                  message: "Making changes in IPFS",
                  width: "12rem",
                }}
                warningMessage={"Are you sure you want to Add data?"}
                loading={loading}
                setShowAction={setOverLay}
                authTypeInput={authUser.authType}
                publicKey={authUser.publicKey}
                encryptedSecret={authUser.encryptedSecret}
                onSecretKey={handleAddEdu}
              />
            );
          case 4:
            return (
              <ActionConfirm
                successMessage={{
                  message: "Making changes in IPFS",
                  width: "12rem",
                }}
                warningMessage={"Are you sure you want to edit?"}
                loading={loading}
                setShowAction={setOverLay}
                authTypeInput={authUser.authType}
                publicKey={authUser.publicKey}
                encryptedSecret={authUser.encryptedSecret}
                onSecretKey={handleEditEdu}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
}

export default EduEditSwitch;
