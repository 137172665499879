import styled from "styled-components";
import React, { useState } from "react";
import TimeAgo from "react-time-ago";
import DOMPurify from "dompurify";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { CircularProgress, IconButton } from "@mui/material";
import { applyJob } from "../../api/API";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const JobComponent = ({
  title,
  location,
  time,
  company,
  description,
  companyView,
  fullView,
  onClickEdit,
  applicant,
  setScreen,
  setItem,
  item,
}) => {
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const { enqueueSnackbar } = useSnackbar();

  // Handle onClick of the jobComponent
  const handleApplyJob = async (data) => {
    data.profilePublicKey = currentUser?.publicKey;
    data.ipfsUrl = currentUser?.latestIpfsUrl?.ipfsUrl;
    data.Image = latestIpfsData?.profileImage?.data.data;
    data.profileName =
      latestIpfsData?.firstName?.data.data +
      " " +
      latestIpfsData?.lastName?.data.data;
    data.profilePosition = latestIpfsData?.workData[0]?.data?.position.data;
    data.profileUniversity =
      latestIpfsData?.educationData[0]?.data?.institutionName.data;

    try {
      setLoading(true);
      // Apply job API
      const response = await applyJob({ body: data });
      if (response.data.code === 200) {
        enqueueSnackbar("You have already applied for this job", {
          variant: "warning",
        });
      } else if (response.data.code === 201) {
        enqueueSnackbar(`${data.position} application Success!`, {
          variant: "success",
        });
      }
      setLoading(false);
    } catch (e) {
      enqueueSnackbar("Failed to apply", {
        variant: "error",
      });
      console.log(e);
    }
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  if (applicant) {
    return (
      <FullContainer>
        <FlexContainer>
          <ImageWrapper>
            <Image alt="student" src={applicant?.candidateImage} />
          </ImageWrapper>
          <ColumnContainer>
            <Text bold primary underline>
              {applicant?.candidatePosition}
            </Text>
            <Text color="#666666">{applicant?.candidateName}</Text>
            <Text color="#666666">{applicant?.candidateUniversity}</Text>
            <Text primary small>
              <TimeAgo date={new Date(applicant?.createdAt)} locale="en-US" />
            </Text>
            <FlexContainer>
              <CustomButton
                login
                text="View CV"
                acceptClass
                onclick={() => {
                  setScreen(1);
                  setItem(applicant);
                }}
              />
            </FlexContainer>
          </ColumnContainer>
        </FlexContainer>
      </FullContainer>
    );
  } else if (fullView) {
    return (
      <FullContainer>
        <FlexContainer>
          <ImageWrapper>
            <Image alt="company" src={company?.logo} />
          </ImageWrapper>
          <ColumnContainer>
            <Text bold primary underline>
              {title}
            </Text>
            <Text color="#666666">{company?.name}</Text>
            <Text color="#666666">{location}</Text>
            <Text primary small>
              {new Date(time).toLocaleDateString()}
            </Text>
            {companyView ? (
              <FlexContainer style={{ gap: "1rem" }}>
                <CustomButton
                  login
                  text="Edit"
                  onclick={() => onClickEdit(0)}
                />
                <CustomButton login rejectClass text="Delete" />
              </FlexContainer>
            ) : (
              <FlexContainer style={{ gap: "1rem" }}>
                <CustomButton acceptClass login text="Apply" />
                <CustomButton login text="Save" />
              </FlexContainer>
            )}
          </ColumnContainer>
        </FlexContainer>
        <Preview
          style={{
            margin: `${description != null && "1rem 0rem"}`,
          }}
          className="preview"
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      </FullContainer>
    );
  } else {
    return (
      <Container hide={!view}>
        <FlexContainer>
          <ImageWrapper>
            <Image alt="company" src={company?.logo} />
          </ImageWrapper>
          <ColumnContainer>
            <Text bold primary underline>
              {title}
            </Text>
            <Text color="#666666">{company?.name}</Text>
            <Text color="#666666">{location}</Text>
            <Text primary small>
              <TimeAgo date={new Date(time)} locale="en-US" />
            </Text>
            <FlexContainer style={{ gap: "1rem" }}>
              <CustomButton
                acceptClass
                login
                text="Apply"
                onclick={() =>
                  handleApplyJob({
                    jobId: item?._id,
                    position: item?.position,
                    credentialProviderName: item?.credentialProviderName,
                    credentialProviderPublicKey:
                      item?.credentialProviderPublicKey,
                  })
                }
                icon={loading && <CircularProgress size={25} />}
                disabled={loading}
              />
              <CustomButton login text="Save" />
            </FlexContainer>
          </ColumnContainer>
        </FlexContainer>
        <PrevieWrapper hide={!view}>
          <Preview
            className="preview"
            dangerouslySetInnerHTML={createMarkup(description)}
          />
          {!view ? (
            <AbsoluteContainer>
              <IconButton onClick={() => setView((value) => !value)}>
                <KeyboardDoubleArrowDownIcon style={{ color: "#1E1B3B" }} />
              </IconButton>
            </AbsoluteContainer>
          ) : (
            <ColumnContainer style={{ alignItems: "center" }}>
              <IconButton onClick={() => setView((value) => !value)}>
                <KeyboardDoubleArrowUpIcon style={{ color: "#1E1B3B" }} />
              </IconButton>
            </ColumnContainer>
          )}
        </PrevieWrapper>
        <OpacityContainer hide={view} />
      </Container>
    );
  }
};

export default JobComponent;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 5px;
  background-color: #c3d0e176;
  padding: 1rem 2rem;
  &:hover {
    background-color: #c3d0e1;
  }
  overflow: ${(props) => (props.hide ? "hidden" : "unset")};
`;

const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #c3d0e176;
  padding: 1rem 2rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 1rem 0rem;
  align-items: center;
`;

export const Image = styled.img`
  width: 10rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  min-width: 9rem;
  display: flex;
  align-items: center;
  height: 9rem;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
`;

export const Text = styled.p`
  text-decoration: ${(props) => (props.underline ? "underline" : "unset")};
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "16px")};
  color: ${(props) => (props.primary ? "#1E1B3B" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "500")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const Preview = styled.div`
  line-height: 2rem;
  margin-bottom: 2rem;
`;

export const PrevieWrapper = styled.div`
  position: relative;
  max-height: ${(props) => (props.hide ? "3rem" : "unset")};
  overflow: ${(props) => (props.hide ? "hidden" : "unset")};
  transition: all 300ms ease-in-out;
  padding-bottom: 1rem;
`;

export const OpacityContainer = styled.div`
  display: ${(props) => (props.hide ? "none" : "block")};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to bottom, transparent, #c3d0e1);
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
`;
