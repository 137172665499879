import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { SearchComponent } from "../../pages/JobListings/SearchComponent";
import ViewPagination from "../../components/shared/Pagination/Pagination";
import JobComponent, {
  ColumnContainer,
  FlexContainer,
  Image,
  ImageWrapper,
  Text,
} from "../../pages/JobListings/JobComponent";
import ProfileView from "../../pages/ViewCV/ProfileView";
import { retrieveApplications } from "../../api/API";
import { useLocation } from "react-router-dom";
import TimeAgo from "react-time-ago";
import { urlToHash } from "../../utils/ipfs";

const ApplicationsView = () => {
  const [date, onDateChange] = useState();
  const [search, setSearch] = useState("");
  //eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const [applications, setApplications] = useState([]);
  const [view, setView] = useState(0);
  const [data, setData] = useState(0);
  const location = useLocation();
  const item = location.state;

  // Retrieving Applications API
  const fetchApplications = async () => {
    try {
      const response = await retrieveApplications({
        path: {
          id: item._id,
        },
      });
      if (response.data.code === 200) {
        setApplications(response.data.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchApplications();
    //eslint-disable-next-line
  }, []);

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scroll(0, 0);
  };

  //get Current Products

  // const indexOfLastPost = currentPage * productsPerPage;
  // const indexOfFirstPost = indexOfLastPost - productsPerPage;

  return (
    <Container>
      <JobContainer>
        <FlexContainer>
          <ImageWrapper>
            <Image
              alt="company"
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                item?.companyProfileImage
              )}`}
            />
          </ImageWrapper>
          <ColumnContainer>
            <Text bold primary underline>
              {item?.position}
            </Text>
            <Text color="#666666">{item?.name}</Text>
            <Text color="#666666">{`${item?.city}, ${item?.country}`}</Text>
            <Text primary small>
              <TimeAgo date={new Date(item?.createdAt)} locale="en-US" />
            </Text>
          </ColumnContainer>
        </FlexContainer>
      </JobContainer>
      <InnerContainer>
        {view === 1 ? (
          <ProfileView isPublic setScreen={setView} ipfsUrl={data} />
        ) : (
          <>
            <SearchComponent
              placeholder="Search for Candidates"
              searchValue={search}
              onSearchChange={setSearch}
              dateValue={date}
              onDateChange={(e) => {
                onDateChange(e);
                setSearch(moment(e).format("L"));
              }}
            />

            {applications &&
              applications.length > 0 &&
              applications.map((item, key) => {
                return (
                  <JobComponent
                    key={key}
                    applicant={item}
                    setScreen={setView}
                    setItem={setData}
                  />
                );
              })}

            {search === "" && <ViewPagination paginate={paginate} />}
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default ApplicationsView;

const InnerContainer = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;

const JobContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  position: relative;
  flex-direction: column;
  border-radius: 5px;
  background-color: #c3d0e176;
  padding: 1rem 2rem;
`;
