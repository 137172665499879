import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import ProviderVerificationsView from "../../university-pages/Provider-Verifications/ProviderVerificationsView";
import AddProviderProfileView from "../../university-pages/AddProfile/AddProviderProfileView";
import IssueCertificates from "../../university-pages/Certificates/IssueCertificates";
import Settings from "../Settings/Settings";
import JobPosting from "../../company-pages/JobPosting/JobPosting";
import ApplicationsView from "../../company-pages/Applications/ApplicationsView";
import Claim from "../Claim/Claim";
import VerifyApplication from "../../university-pages/Provider-Verifications/VerifyApplication";
import CredentialViewer from "../../university-pages/Certificates/CredentialViewer";

function DashBoardSwitchProvider() {
  return (
    <Switch>
      <Route
        path="/dashboard/profile"
        exact
        component={AddProviderProfileView}
      />
      <Route
        path="/dashboard/issue-credentials"
        exact
        component={IssueCertificates}
      />{" "}
      <Route
        path="/dashboard/issue-credentials/:id"
        exact
        component={CredentialViewer}
      />{" "}
      <Route path="/dashboard/settings" exact component={Settings} />
      <Route
        path="/dashboard/verifications"
        exact
        component={ProviderVerificationsView}
      />
      <Route
        path="/dashboard/verifications/:id"
        component={VerifyApplication}
      />
      <Route path="/dashboard/job-postings" exact component={JobPosting} />
      <Route
        path="/dashboard/job-postings/candidates"
        exact
        component={ApplicationsView}
      />
      <Route path="/dashboard/credentials" exact component={Claim} />
      <Route path="/dashboard/credentials/:id" exact component={Claim} />
      <Route path="/dashboard/certificates" exact component={Claim} />
      <Route path="/dashboard/certificates/:id" exact component={Claim} />
      <Route path="/dashboard/tokens" exact component={Claim} />
      <Route path="/dashboard/tokens/:id" exact component={Claim} />
      <Container>
        <ButtonContainer
          bigLink
          link="/dashboard/profile"
          heading="Credential Provider Profile"
          description="Add, remove or edit your institution details here."
          icon={"account_circle"}
        />
        <ButtonContainer
          bigLink
          link="/dashboard/issue-credentials"
          heading="Issue Credentials"
          description="Issue qualification credentials here."
          icon={"link"}
        />
      </Container>
    </Switch>
  );
}

export default DashBoardSwitchProvider;

export const Container = styled.div`
  display: grid;
  align-content: flex-start;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1068px) {
    grid-template-columns: 1fr;
  }
`;
