import React from "react";
import styled from "styled-components";

import Cover from "../../assets/images/Chain3x.png";

export const LoginCover = () => {
  return <Image src={Cover} />;
};

const Image = styled.img`
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;
