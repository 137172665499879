import { CustomButton } from "../fields/Button/CustomButton";
import WorkIcon from "@mui/icons-material/Work";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GradingIcon from "@mui/icons-material/Grading";
import VerifiedIcon from "@mui/icons-material/Verified";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCode2Icon from "@mui/icons-material/QrCode2";

export const StudentNav = (history) => {
  return (
    <div>
      <CustomButton
        text="Home"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="My Profile"
        tab
        onclick={() => {
          history.push("/dashboard/my-profile");
        }}
        icon={<PersonIcon />}
      />
      {/* 
      <CustomButton
        text="Job Listings"
        tab
        onclick={() => {
          history.push("/dashboard/job-listings");
        }}
        icon={<WorkIcon />}
      /> */}
      <CustomButton
        text="Credentials"
        tab
        onclick={() => {
          history.push("/dashboard/credentials");
        }}
        icon={<GradingIcon />}
      />
      <CustomButton
        text="Products"
        tab
        onclick={() => {
          history.push("/products");
        }}
        icon={<QrCodeIcon />}
      />
      <CustomButton
        text="Scans"
        tab
        onclick={() => {
          history.push("/tickets");
        }}
        icon={<QrCode2Icon />}
      />
    </div>
  );
};

export const ProductNav = (history) => {
  return (
    <div>
      <CustomButton
        text="Home"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="My Profile"
        tab
        onclick={() => {
          history.push("/dashboard/my-profile");
        }}
        icon={<PersonIcon />}
      />

      <CustomButton
        text="Credentials"
        tab
        onclick={() => {
          history.push("/dashboard/credentials");
        }}
        icon={<GradingIcon />}
      />

      <CustomButton
        text="Scans"
        tab
        onclick={() => {
          history.push("/dashboard/scans");
        }}
        icon={<QrCode2Icon />}
      />
    </div>
  );
};

export const CredentialProvider = (history) => {
  return (
    <div>
      <CustomButton
        text="Dashboard"
        tab
        onclick={() => {
          history.push("/dashboard");
        }}
        icon={<DashboardIcon />}
      />
      <CustomButton
        text="Provider Profile"
        tab
        onclick={() => {
          history.push("/dashboard/profile");
        }}
        icon={<PersonIcon />}
      />
      <CustomButton
        text="Verifications"
        tab
        onclick={() => {
          history.push("/dashboard/verifications");
        }}
        icon={<VerifiedIcon />}
      />
      <CustomButton
        text="Credentials"
        tab
        onclick={() => {
          history.push("/dashboard/issue-credentials");
        }}
        icon={<GradingIcon />}
      />{" "}
      {/* <CustomButton
        text="Job Postings"
        tab
        onclick={() => {
          history.push("/dashboard/job-postings");
        }}
        icon={<WorkIcon />}
      /> */}
    </div>
  );
};
