import React from "react";
import styled from "styled-components";
import { useCSVReader } from "react-papaparse";
import { LinearProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import { LoadingContainer } from "../../pages/SignUp/SignUpForm";
import { useSnackbar } from "notistack";
import { Text } from "../../components/shared/Typography/Typo";
import Upload from "../../assets/images/upload.png";
import Download from "../../assets/images/csv.png";
import Send from "../../assets/images/send.png";
import { DroppableContainer, InnerContainer } from "./IssueCertificateFormBulk";
import {
  InputContainer,
  Label,
  Container as DropContainer,
} from "../../components/shared/Form/FormComponents/TextField";
import CSVFile from "../../assets/csv/Credentials-Format.csv";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//Progress button styles
const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  browseFile: {
    borderRadius: "5px",
    border: "none",
    background: "rgb(76, 175, 80)",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    color: "#fff",
    cursor: "pointer",
    width: "15rem",
    padding: "0 20px",
  },
  acceptedFile: {
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: "80%",
  },
  remove: {
    borderRadius: "5px",
    padding: "0 20px",
    border: "none",
    background: "rgb(244, 67, 54)",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    cursor: "pointer",
    color: "#fff",
  },
};

export default function CSVReader({ setCertificateData, setPreview }) {
  const { CSVReader } = useCSVReader();
  const { enqueueSnackbar } = useSnackbar();
  const validate = ["itemName", "blockchain", "itemImage", "email"];

  return (
    <CSVReader
      config={{ skipEmptyLines: true }}
      maxFiles={1}
      onUploadAccepted={(results) => {
        // Check the headers
        if (validate.every((r) => results.data[0].includes(r))) {
          var blockChain = [];
          // Check wether same blockchain or not
          results.data.slice(1).forEach((element) => {
            blockChain.push(element[2]);
          });
          if (blockChain.every((val, i, arr) => val === arr[0])) {
            setCertificateData(results);
          } else {
            setCertificateData([]);
            enqueueSnackbar(
              "Please ensure all the items are issued on the same blockchain",
              {
                variant: "warning",
              }
            );
          }
        } else {
          setCertificateData([]);
          enqueueSnackbar("Please upload the correct format", {
            variant: "warning",
          });
        }
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <OuterContainer style={{ gridRow: "span 2" }}>
          <FlexContainer>
            <a
              href="https://niftron-util.s3.amazonaws.com/Depro-Credential-Template.csv"
              download="Depro-Credential-Format.csv"
              style={{ textDecoration: "none" }}
            >
              <ImageWrapper>
                <Imagecontainer>
                  <Image src={Download} />
                </Imagecontainer>
                <ImageContent>
                  <Text primary style={{ fontWeight: "600" }}>
                    Download
                  </Text>
                  <Text color="#686868" lighter small>
                    Download sample CSV file format.
                  </Text>
                </ImageContent>
              </ImageWrapper>
            </a>
            <ArrowForwardIosIcon
              style={{ color: "#8a8a8a" }}
              sx={{ fontSize: 20 }}
            />
            <ImageWrapper type="button" {...getRootProps()}>
              <Imagecontainer>
                <Image src={Upload} />
              </Imagecontainer>
              <ImageContent>
                <Text primary style={{ fontWeight: "600" }}>
                  Upload
                </Text>
                <Text color="#686868" lighter small>
                  Upload CSV file with data.
                </Text>
              </ImageContent>
            </ImageWrapper>{" "}
            <ArrowForwardIosIcon
              style={{ color: "#8a8a8a" }}
              sx={{ fontSize: 20 }}
            />
            <ImageWrapper
              onClick={() => {
                if (acceptedFile && acceptedFile.name) {
                  setPreview(1);
                }
              }}
            >
              <Imagecontainer>
                <Image src={Send} />
              </Imagecontainer>
              <ImageContent>
                <Text primary style={{ fontWeight: "600" }}>
                  Issue
                </Text>
                <Text color="#686868" lighter small>
                  Issue Credentials.
                </Text>
              </ImageContent>
            </ImageWrapper>
          </FlexContainer>
          <DropContainer>
            <InputContainer style={{ margin: "0.5rem 0rem" }}>
              <Label>Upload CSV</Label>
            </InputContainer>
            <DroppableContainer>
              <InnerContainer>
                <FlexColumn>
                  <div style={styles.csvReader}>
                    <button
                      type="button"
                      {...getRootProps()}
                      style={styles.browseFile}
                    >
                      Browse File
                    </button>
                    <div style={styles.acceptedFile}>
                      {acceptedFile && acceptedFile.name}
                    </div>
                    <button {...getRemoveFileProps()} style={styles.remove}>
                      Remove
                    </button>
                  </div>
                  <ProgressBar>
                    <LoadingContainer>
                      <BorderLinearProgress />
                    </LoadingContainer>
                  </ProgressBar>
                </FlexColumn>
              </InnerContainer>
            </DroppableContainer>
          </DropContainer>
        </OuterContainer>
      )}
    </CSVReader>
  );
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-evenly;
  @media (max-width: 1468px) {
    flex-direction: column;
    margin: 3rem 0rem;
  }
`;

const Imagecontainer = styled.div`
  width: 5rem;
  padding: 2rem;
  display: grid;
  place-items: center;
  height: 5rem;
  border-radius: 50%;
  background-color: #ecf2ff;
`;

const ImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.2rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  min-width: 15rem;
  cursor: pointer;
  &:hover {
    background-color: #dfdede;
    ${Imagecontainer} {
      background-color: #ffffff;
    }
  }
  gap: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;
