export function urlToHash(url) {
  if(!url){
    return null
  }
  if (url.includes(".ipfs.infura-ipfs.io")){
    return url
  }
  const urlArr = url?.split("/");
  return urlArr[urlArr?.length - 1].includes("png") ||
    urlArr[urlArr?.length - 1].includes("jpeg") ||
    urlArr[urlArr?.length - 1].includes("mp4") ||
    urlArr[urlArr?.length - 1].includes("jpg")
    ? `${urlArr[urlArr?.length - 2]}/${urlArr[urlArr?.length - 1]}`
    : urlArr[urlArr?.length - 1];
}
