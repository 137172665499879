import React from "react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "../../components/shared/Form/FormComponents/TextField";
import DateComponent from "../../components/shared/Date/DateComponent";
export const SearchComponent = (props) => {
  return (
    <FlexContainer>
      <TextField
        placeholder={props.placeholder}
        startIcon={<SearchIcon />}
        value={props.searchValue}
        onChange={props.onSearchChange}
      />
      <DateComponent
        span
        value={props.dateValue}
        onChange={props.onDateChange}
      />
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 1fr;
  justify-content: space-between;
  gap: 2rem;
`;
