import { createEncryptionObject } from "./encryption";

export function createCVObject(
  productData,
  toPublicKey,
  fromPublicKey,
  fromSecretKey,
  encryptionType
) {
  let encryptedObject = {};

  let productObjects = {};

  for (const [key, value] of Object.entries(productData)) {
    let privacyStatus = encryptionType.find((x) => x[key]);
    productObjects[key] = value;

    if (Array.isArray(value)) {
      let tempArray = [];
      value.forEach((arrayObject) => {
        if (typeof arrayObject === "object") {
          let obj = {};
          obj.verifiedBy = [];
          obj.isPublic = arrayObject.isPublic;
          obj.data = {};
          for (const [key, value] of Object.entries(arrayObject)) {
            if (key === "customStageData") {
              console.log("arrayObject : ", arrayObject);
              obj.data[key] = createEncryptionObject(
                "PUBLIC",
                value,
                toPublicKey,
                fromPublicKey,
                fromSecretKey
              );
            } else {
              obj.data[key] = createEncryptionObject(
                arrayObject.isPublic === true ? "PUBLIC" : "SYMMETRIC",
                typeof value === "number" || "string"
                  ? value.toString()
                  : value,
                toPublicKey,
                fromPublicKey,
                fromSecretKey
              );
            }
          }
          tempArray.push(obj);
        } else {
          let obj = {};
          obj.verifiedBy = [];
          obj.isPublic = privacyStatus === undefined ? true : false;
          obj.data = arrayObject;
          obj.data = createEncryptionObject(
            privacyStatus === undefined ? "PUBLIC" : "SYMMETRIC",
            arrayObject,
            toPublicKey,
            fromPublicKey,
            fromSecretKey
          );
          tempArray.push(obj);
        }
      });
      encryptedObject[key] = tempArray;
    } else {
      encryptedObject[key] = {};
      encryptedObject[key].verifiedBy = [];
      encryptedObject[key].isPublic =
        privacyStatus === undefined ? true : false;
      encryptedObject[key].data = createEncryptionObject(
        privacyStatus === undefined ? "PUBLIC" : "SYMMETRIC",
        typeof value === "number" ? value.toString() : value,
        toPublicKey,
        fromPublicKey,
        fromSecretKey
      );
    }
  }
  console.log("PRODUCT OBJECT", productObjects);
  console.log("*****************************************");
  console.log("ENCRYPTED OBJECT", encryptedObject);
  return encryptedObject;
}

//change this to new one later
export function oldCreateCVObject(
  productData,
  toPublicKey,
  fromPublicKey,
  fromSecretKey,
  encryptionType = "PUBLIC"
) {
  let encryptedObject = {};
  let productObjects = {};
  for (const [key, value] of Object.entries(productData)) {
    productObjects[key] = value;
    if (Array.isArray(value)) {
      let tempArray = [];
      value.forEach((arrayObject) => {
        if (typeof arrayObject === "object") {
          let obj = {};
          obj.verifiedBy = [];
          obj.isPublic = true;
          obj.data = arrayObject;
          for (const [key, value] of Object.entries(arrayObject)) {
            obj.data[key] = createEncryptionObject(
              encryptionType,
              value,
              toPublicKey,
              fromPublicKey,
              fromSecretKey
            );
          }
          tempArray.push(obj);
        } else {
          let obj = {};
          obj.verifiedBy = [];
          obj.isPublic = true;
          obj.data = arrayObject;
          obj.data = createEncryptionObject(
            encryptionType,
            arrayObject,
            toPublicKey,
            fromPublicKey,
            fromSecretKey
          );
          tempArray.push(obj);
        }
      });
      encryptedObject[key] = tempArray;
    } else {
      encryptedObject[key] = {};
      encryptedObject[key].verifiedBy = [];
      encryptedObject[key].isPublic = true;
      encryptedObject[key].data = createEncryptionObject(
        encryptionType,
        value,
        toPublicKey,
        fromPublicKey,
        fromSecretKey
      );
    }
  }
  console.log("PRODUCT OBJECT", productObjects);
  console.log("*****************************************");
  console.log("ENCRYPTED OBJECT", encryptedObject);
  return encryptedObject;
}
