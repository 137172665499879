import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Header } from "../../components/shared/Header/Header";
import SideNav from "../../components/shared/SideBar/SideNav";
import DashBoardSwitch from "./DashBoardSwitch";
import DashBoardSwitchProvider from "./DashBoardSwitchProvider";

const DashBoard = () => {
  const [sidebar, setSidebar] = useState(null);
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <>
      {sidebar && <OverLayContainer onClick={() => setSidebar(false)} />}
      <GridContainer>
        <SideNav sideBarActive={sidebar} setShowSideBar={setSidebar} />
        <HeaderMain>
          <Header setSidebar={setSidebar} />
          {(() => {
            switch (currentUser.type) {
              case "PROFILE":
                return <DashBoardSwitch />;
              case "CREDENTIALPROVIDER":
                return <DashBoardSwitchProvider />;
              default:
                return null;
            }
          })()}
        </HeaderMain>
      </GridContainer>
    </>
  );
};

export default DashBoard;

export const GridContainer = styled.div`
  height: 94vh;
  display: flex;
  gap: 1rem;
`;

export const HeaderMain = styled.div`
  width: 100%;
  overflow: auto;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

const OverLayContainer = styled.div`
  @media (max-width: 1368px) {
    z-index: 200;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #0c0b554e;
    color: white;
  }
`;
