import React, { Fragment, useState } from "react";
import { ColumnLoadContainer, FlexContainer, Gif } from "./ShareConfirm";
import { LoginIcon } from "../fields/Button/LoginIcon";
import { SmallText } from "../Form/FormComponents/ActionConfirm";
import { ColumnContainer } from "../Form/ProductFormContainer";
import { Text } from "../Typography/Typo";
import { Divider } from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";
import LoadingGif from "../../../assets/images/loading.gif";
import {
  ContainerForm,
  DroppableContainer,
  InnerContainer,
  LoadingContainer,
} from "../../../pages/Login/LoginForm";
import {
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import sha256 from "sha256";
import { Keypair } from "stellar-sdk";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { withStyles } from "@mui/styles";
import { CustomButton } from "../fields/Button/CustomButton";
import Dropzone from "react-dropzone";
import { symmetricDecryption } from "../../../services/encryptionService";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const RequestVerificationActionConfirm = ({
  authTypeInput,
  encryptedSecret,
  publicKey,
  warningMessage,
  onSecretKey,
  loading,
  setShowAction,
  successMessage,
}) => {
  const [authType, setAuthType] = useState(authTypeInput);
  const { enqueueSnackbar } = useSnackbar();
  // states
  const [fileError] = useState(false);
  // setSecretKey
  const [showSecret, setShowSecret] = useState(false);

  // setPassword
  const [showPassword, setShowPassword] = useState(false);

  //functions
  const handleClickShowSecretKey = () => {
    setShowSecret((value) => !value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const switchToSecret = (e) => {
    e.preventDefault();
    setAuthType("2");
  };
  const switchToPassword = (e) => {
    e.preventDefault();
    setAuthType("0");
  };
  return (
    <Container>
      {(() => {
        if (loading) {
          return (
            <ColumnLoadContainer>
              <Gif src={LoadingGif} style={{ width: "23rem" }} />
              <LoadingContainer>
                <BorderLinearProgress />
                <Text
                  primary
                  small
                  lighter
                  style={{ width: successMessage.width }}
                >
                  {successMessage.message}
                  <span className="loading"></span>
                </Text>
              </LoadingContainer>
            </ColumnLoadContainer>
          );
        } else
          return (
            <Fragment>
              <FlexContainer addPadding>
                <ColumnContainer>
                  <Text primary lighter small>
                    Please Confirm action
                  </Text>
                  <SmallText color="#7D7979" lighter small>
                    {warningMessage}
                  </SmallText>
                </ColumnContainer>
                <LoginIcon large />
              </FlexContainer>
              <Divider
                style={{
                  background: "#36353511",
                  width: "100%",
                  marginBottom: "0.5rem",
                }}
              />
              {authType === "2" ? (
                <Formik
                  initialValues={{
                    secretKey: "",
                    autosign: false,
                  }}
                  validationSchema={Yup.object().shape({
                    secretKey: Yup.string()
                      .matches(
                        /^[S,s][a-zA-Z0-9]{55}$/,
                        "SecretKey format is invalid"
                      )
                      .required("SecretKey is required"),
                  })}
                  onSubmit={async (values) => {
                    try {
                      if (
                        publicKey !==
                        Keypair.fromSecret(values.secretKey).publicKey()
                      ) {
                        enqueueSnackbar("Credential Mismatch", {
                          variant: "warning",
                        });
                      } else {
                        enqueueSnackbar("Credential Validated Successfully", {
                          variant: "success",
                        });
                        if (values.autosign) {
                          localStorage.setItem("secretKey", values.secretKey);
                        }
                        onSecretKey(values.secretKey);
                        setShowAction(1);
                      }
                    } catch (e) {
                      enqueueSnackbar(e.message, { variant: "error" });
                      console.log(e.message);
                    }
                  }}
                >
                  {/* Formik props */}
                  {({
                    errors,
                    isValid,
                    dirty,
                    isSubmitting,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    handleChange,
                  }) => (
                    <ContainerForm onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="standard"
                        disabled={isSubmitting}
                        name="secretKey"
                        label="Secret Key"
                        id="secretKey"
                        onChange={handleChange}
                        value={values.secretKey}
                        helperText={touched.secretKey && errors.secretKey}
                        InputLabelProps={{
                          style: {
                            color: "#080036",
                            fontFamily: "Poppins",
                          },
                        }}
                        error={Boolean(touched.secretKey && errors.secretKey)}
                        onBlur={handleBlur}
                        InputProps={{
                          style: { color: "#0e0c2b", padding: ".2rem" },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                style={{ marginBottom: ".2rem" }}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowSecretKey}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disabled={isSubmitting}
                              >
                                {showSecret ? (
                                  <VisibilityIcon style={{ fill: "#080036" }} />
                                ) : (
                                  <VisibilityOffIcon
                                    style={{ fill: "#080036" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                          type: showSecret ? "text" : "password",
                        }}
                      />
                      {/*SecretKey*/}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          const reader = new FileReader();
                          reader.addEventListener("load", async () => {
                            setFieldValue(
                              "secretKey",
                              JSON.parse(atob(reader.result)).secretKey
                            );
                          });
                          reader.readAsText(acceptedFiles[0]);
                        }}
                        multiple={false}
                        accept={[".niftron"]}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <DroppableContainer>
                            <InnerContainer
                              {...getRootProps({
                                className: fileError
                                  ? "dropzone2Error"
                                  : "dropzone2",
                              })}
                            >
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <Text small>Drop the files here ...</Text>
                              ) : (
                                <Text primary small lighter>
                                  Drop or select your Niftron credential file
                                </Text>
                              )}
                            </InnerContainer>
                          </DroppableContainer>
                        )}
                      </Dropzone>

                      <FlexContainer>
                        <CheckBoxContainer>
                          <Checkbox
                            checked={values.autosign}
                            name="autosign"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <Text primary>Autosign</Text>
                        </CheckBoxContainer>
                        {Boolean(touched.autosign && errors.autosign) && (
                          <FormHelperText error>
                            {errors.autosign}
                          </FormHelperText>
                        )}
                        {authTypeInput !== "2" && (
                          <Button
                            endIcon={<ArrowLeftIcon />}
                            style={{
                              background: "#1fc36157",
                              color: "#080036",
                              textTransform: "inherit",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              padding: ".3rem 1rem",
                              float: "right",
                            }}
                            onClick={switchToPassword}
                            type="button"
                            size="small"
                          >
                            Use Password
                          </Button>
                        )}
                      </FlexContainer>

                      {isSubmitting ? (
                        <LoadingContainer>
                          <BorderLinearProgress />
                          <Text primary small lighter style={{ width: "80px" }}>
                            Verifying <span className="loading"></span>
                          </Text>
                        </LoadingContainer>
                      ) : (
                        <CustomButton
                          wide
                          login
                          disabled={!isValid || (isSubmitting && dirty)}
                          onclick={handleSubmit}
                          text="Confirm Action"
                        />
                      )}
                    </ContainerForm>
                  )}
                </Formik>
              ) : (
                <Formik
                  initialValues={{
                    password: "",
                    autosign: false,
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .max(255)
                      .required("Password is required"),
                  })}
                  // ON submit do a API call
                  onSubmit={async (values) => {
                    try {
                      const secret = symmetricDecryption(
                        encryptedSecret,
                        sha256(values.password)
                      );
                      if (
                        !secret ||
                        secret === "" ||
                        secret === null ||
                        secret === undefined
                      ) {
                        enqueueSnackbar("Invalid Credential", {
                          variant: "warning",
                        });
                      } else if (
                        publicKey !== Keypair.fromSecret(secret).publicKey()
                      ) {
                        enqueueSnackbar("Credential Mismatch", {
                          variant: "warning",
                        });
                      } else {
                        enqueueSnackbar("Credential Validated Successfully", {
                          variant: "success",
                        });
                        if (values.autosign) {
                          localStorage.setItem("secretKey", secret);
                        }
                        onSecretKey(secret);
                        setShowAction(1);
                      }
                    } catch (e) {
                      enqueueSnackbar(e.message, { variant: "error" });
                      console.log(e.message);
                    }
                  }}
                >
                  {/* Formik Props */}
                  {({
                    errors,
                    isValid,
                    dirty,
                    isSubmitting,
                    handleBlur,
                    handleSubmit,
                    touched,
                    values,
                    handleChange,
                  }) => (
                    <ContainerForm onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="standard"
                        disabled={isSubmitting}
                        name="password"
                        label="Password"
                        id="password"
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        InputLabelProps={{
                          style: {
                            color: "#080036",
                            fontFamily: "Poppins",
                          },
                        }}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        InputProps={{
                          style: {
                            color: "#0e0c2b",
                            padding: ".2rem",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                style={{ marginBottom: ".2rem" }}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                disabled={isSubmitting}
                              >
                                {showPassword ? (
                                  <VisibilityIcon style={{ fill: "#080036" }} />
                                ) : (
                                  <VisibilityOffIcon
                                    style={{ fill: "#080036" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          autoComplete: "new-password",
                          type: showPassword ? "text" : "password",
                        }}
                      />
                      <FlexContainer>
                        <CheckBoxContainer>
                          <Checkbox
                            checked={values.autosign}
                            name="autosign"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <Text primary>Autosign</Text>
                        </CheckBoxContainer>
                        {Boolean(touched.autosign && errors.autosign) && (
                          <FormHelperText error>
                            {errors.autosign}
                          </FormHelperText>
                        )}
                        <Button
                          endIcon={<ArrowRightIcon />}
                          style={{
                            background: "#1fc36157",
                            color: "#080036",
                            textTransform: "inherit",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            padding: ".3rem 1rem",
                            float: "right",
                          }}
                          onClick={switchToSecret}
                          type="button"
                        >
                          Use SecretKey
                        </Button>
                      </FlexContainer>
                      {isSubmitting ? (
                        <LoadingContainer>
                          <BorderLinearProgress />
                          <Text primary small lighter style={{ width: "80px" }}>
                            Verifying <span className="loading"></span>
                          </Text>
                        </LoadingContainer>
                      ) : (
                        <CustomButton
                          wide
                          login
                          disabled={!isValid || (isSubmitting && dirty)}
                          onclick={handleSubmit}
                          text="Confirm Action"
                        />
                      )}
                    </ContainerForm>
                  )}
                </Formik>
              )}
            </Fragment>
          );
      })()}
    </Container>
  );
};

export default RequestVerificationActionConfirm;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;
