import React from "react";
import styled from "styled-components";
import AchievmentEditSwitch from "./AchievmentEditForm/AchievmentEditSwitch";
import CustomDataFormSwitch from "./CustomEditForm/CustomDataFormSwitch";
import EduEditSwitch from "./EduEditForms/EduEditSwitch";
import StageEditSwitch from "./StageEditForm/StageEditSwitch";
import WorkEditSwitch from "./WorkEditForms/WorkEditSwitch";

const EditForm = ({ type, setOverLay, authType }) => {
  return (
    <>
      {(() => {
        switch (type) {
          case 1:
            return <EduEditSwitch setOverLay={setOverLay} />;
          case 2:
            return <WorkEditSwitch setOverLay={setOverLay} />;
          case 3:
            return <AchievmentEditSwitch setOverLay={setOverLay} />;
          case 4:
            return (
              <CustomDataFormSwitch
                setOverLay={setOverLay}
                authType={authType}
              />
            );
          case 5:
            return <StageEditSwitch setOverLay={setOverLay} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default EditForm;

export const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b558f; /* Add the blur effect */
`;
