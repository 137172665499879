import React from "react";
import { OverLayContainer } from "../EditForm";
import { Container, OverFlowContainer } from "./MultiWorkDataEditComponent";
import { ButtonWrapper, ColumnContainer } from "../../Form/Web3ResumeContainer";
import { FlexSpaceBetweenContainer } from "../../Profile/UserProfile";
import { SubHeading } from "../../Typography/Typo";
import { CustomButton } from "../../fields/Button/CustomButton";
import AddWorkDataForm from "./AddWorkDataForm";
import { IconWrapper } from "../../Profile/UserProfileHeader";
import EditIcon from "@mui/icons-material/Edit";
import { useStateValue } from "../../../../context/StateProvider";
import { IconButton } from "@mui/material";

function AddWorkDataEditComponent({ setOverLay, setStatus, showEdit }) {
  const [{ workFields }, dispatch] = useStateValue();

  const closeEdit = () => {
    dispatch({
      type: "CLEAR_WORK_FIELDS",
    });
    setOverLay(false);
  };

  return (
    <>
      <OverLayContainer onClick={() => closeEdit()} />
      <Container>
        <OverFlowContainer style={{ width: "90%" }}>
          <FlexSpaceBetweenContainer
            style={{
              width: "95%",
              position: "absolute",
              left: "0",
              top: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
              zIndex: "1",
            }}
          >
            <SubHeading style={{ padding: "2rem 3rem" }} primary>
              Add Work Data
            </SubHeading>
            {!showEdit && (
              <IconWrapper style={{ backgroundColor: "#7271713b" }}>
                <IconButton onClick={() => setStatus(2)}>
                  <EditIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                </IconButton>
              </IconWrapper>
            )}
          </FlexSpaceBetweenContainer>
          <AddWorkDataForm />
          <ColumnContainer
            style={{
              width: "100%",
              position: "absolute",
              right: "0",
              bottom: "0",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <ButtonWrapper style={{ gap: "1rem", padding: "2rem 3rem" }}>
              <CustomButton
                login
                text="Cancel"
                rejectClass
                type="button"
                onclick={() => setOverLay(false)}
              />
              <CustomButton
                onclick={() => setStatus(3)}
                login
                text="Add New Data"
                disabled={workFields.length <= 0}
              />
            </ButtonWrapper>
          </ColumnContainer>
        </OverFlowContainer>
      </Container>
    </>
  );
}

export default AddWorkDataEditComponent;
