import { Modal, useMediaQuery } from "@mui/material";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { useStateValue } from "../../../context/StateProvider";
import { getFormDataById } from "../../../utils/getFormData";
import { CustomButton } from "../fields/Button/CustomButton";
import { CustomTextFieldForm } from "../Form/FormComponents/CustomTextFieldForm";
import { TextField } from "../Form/FormComponents/TextField";
import { ButtonWrapper } from "../Form/Web3ResumeContainer";
import { SubHeading } from "../Typography/Typo";
import PropTypes from "prop-types";
import MultipleImageUpload from "../Form/FormComponents/MultipleImageUpload";

function CustomTextFieldModal({
  openCustomModal,
  setCustomModalOpen,
  title,
  formId,
  stageType,
}) {
  const [{ fields, stageFields }, dispatch] = useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  const [customImageArr, setCustomImageArr] = useState([]);

  const handleModalClose = () => {
    setCustomModalOpen(false);
  };

  const handleCustomFieldSubmit = (e) => {
    e.preventDefault();
    const customData = getFormDataById(formId);

    const imageArr = Object.assign({}, ...customImageArr);

    let customDataRefined = {
      ...customData,
      ...imageArr,
    };

    const keys = Object.keys(customDataRefined);

    keys.forEach((key, index) => {
      const customItem = {};
      customItem[keys[index]] = customDataRefined[key];
      customItem.isPublic = fields[index]?.isPublic;

      if (stageType) {
        dispatch({
          type: "ADD_STAGE_CUSTOM_DATA_FIELDS",
          item: customItem,
        });
      } else {
        dispatch({
          type: "ADD_CUSTOM_DATA_FIELDS",
          item: customItem,
        });
      }
    });
    clearCustom();
    setCustomImageArr([]);
    handleModalClose();
    document.getElementById(formId).reset();
  };

  const clearCustom = () => {
    if (stageType) {
      setCustomImageArr([]);
      dispatch({
        type: "CLEAR_STAGE_FIELDS",
      });
    } else {
      dispatch({
        type: "CLEAR_FIELDS",
      });
    }
  };

  return (
    <Modal
      open={openCustomModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomModalContainer>
        <SubHeading
          primary
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
        >
          {title}
        </SubHeading>
        <GridCustomContainer
          id={formId}
          name={formId}
          onSubmit={handleCustomFieldSubmit}
        >
          {(() => {
            if (stageType) {
              return (
                <Fragment>
                  {stageFields &&
                    stageFields.length > 0 &&
                    stageFields.map((item, key) => {
                      if (item.select.value === "image") {
                        return (
                          <MultipleImageUpload
                            span
                            key={key}
                            name={item.name}
                            setCustomImageArr={setCustomImageArr}
                            customImageArr={customImageArr}
                          />
                        );
                      } else {
                        return (
                          <TextField
                            stageType={stageType}
                            custom
                            form={formId}
                            key={key}
                            label={item.name}
                            placeholder={item.name}
                            id={item.name}
                            required
                            name={item.name.replace(/ /g, "")}
                            pattern={
                              item.select.value === "number" && "[0-9]{0,5}"
                            }
                            type={item.select.value}
                            maxLength="50"
                          />
                        );
                      }
                    })}
                </Fragment>
              );
            } else {
              return (
                <Fragment>
                  {fields &&
                    fields.length > 0 &&
                    fields.map((item, key) => {
                      if (item.select.value === "image") {
                        return (
                          <MultipleImageUpload
                            span
                            key={key}
                            name={item.name}
                            setCustomImageArr={setCustomImageArr}
                            customImageArr={customImageArr}
                          />
                        );
                      } else {
                        return (
                          <TextField
                            custom
                            form={formId}
                            key={key}
                            label={item.name}
                            placeholder={item.name}
                            id={item.name}
                            required
                            name={item.name.replace(/ /g, "")}
                            pattern={
                              item.select.value === "number" && "[0-9]{0,5}"
                            }
                            type={item.select.value}
                            maxLength="50"
                            // icon={
                            //   <PrivacyToggle
                            //     custom
                            //     fieldName={item.name}
                            //     _id={item.name}
                            //   />
                            // }
                          />
                        );
                      }
                    })}
                </Fragment>
              );
            }
          })()}
        </GridCustomContainer>

        <ButtonWrapper style={{ gap: "1rem" }}>
          {stageType ? (
            <>
              {stageFields.length > 0 && (
                <Fragment>
                  <CustomButton
                    login
                    rejectClass
                    text="Clear Fields"
                    type="button"
                    onclick={clearCustom}
                  />
                  <CustomButton
                    login
                    text="Add Custom Data"
                    type="submit"
                    form={formId}
                  />
                </Fragment>
              )}
            </>
          ) : (
            <>
              {fields.length > 0 && (
                <Fragment>
                  <CustomButton
                    login
                    rejectClass
                    text="Clear Fields"
                    type="button"
                    onclick={clearCustom}
                  />
                  <CustomButton
                    login
                    text="Add Custom Data"
                    type="submit"
                    form={formId}
                  />
                </Fragment>
              )}
            </>
          )}
        </ButtonWrapper>
        <CustomTextFieldForm stageType={stageType} />
      </CustomModalContainer>
    </Modal>
  );
}

CustomTextFieldModal.propTypes = {
  openCustomModal: PropTypes.bool,
  setCustomModalOpen: PropTypes.func,
  title: PropTypes.string,
  formId: PropTypes.string,
  stageType: PropTypes.bool,
};

export default CustomTextFieldModal;

const CustomModalContainer = styled.div`
  display: flex;
  width: 60%;
  padding: 2rem;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  max-height: 80vh;
  background: #ffffff;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const GridCustomContainer = styled.form`
  overflow: auto;
  display: grid;
  padding-right: 1rem;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;
