import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { SubHeading } from "../../components/shared/Typography/Typo";
import Field from "./Field";
import { GetIPFSData } from "../../services/IpfsService";
import { urlToHash } from "../../utils/ipfs";

const ProfileView = ({ isPublic, setScreen, ipfsUrl }) => {
  const [data, setData] = useState([]);

  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (ipfsUrl?.ipfsUrl) {
      const hash = urlToHash(ipfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        console.log("data", data);
        setData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
    //eslint-disable-next-line
  }, [setScreen]);


  if (isPublic) {
    return (
      <Container>
        <SubHeading
          primary
          style={{
            margin: "0.5rem 0rem",
          }}
        >
          Basic Details
        </SubHeading>
        <BannerContainer>
          <ImageWrapper>
            <Field
              banner
              status={1}
              fieldName="Profile Picture"
              image={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                data?.profileImage?.data.data
              )}`}
            />
          </ImageWrapper>
        </BannerContainer>
        <Field
          fieldName="First Name"
          fieldData={data?.firstName?.data.data}
          status={1}
        />
        <Field
          fieldName="Last Name"
          fieldData={data?.lastName?.data.data}
          status={1}
        />
        <Field fieldName="Bio" fieldData={data?.bio?.data.data} />
        <Field
          fieldName="Address"
          fieldData={
            data?.addressLineOne?.data.data +
            " " +
            data?.addressLineTwo?.data.data
          }
        />
        <Field
          fieldName="Email Address"
          fieldData={data?.email?.data.data}
          status={1}
        />
        <Field
          fieldName="Phone Number"
          fieldData={data?.phoneNumber?.data.data}
          status={1}
        />
        <SubHeading
          primary
          style={{
            margin: "0.5rem 0rem",
          }}
        >
          Education
        </SubHeading>
        <Field
          addPadding
          fieldName="Education Details"
          fieldList={data?.educationData}
        />
        <SubHeading
          primary
          style={{
            margin: "0.5rem 0rem",
          }}
        >
          Work Experience
        </SubHeading>
        <Field
          addPadding
          fieldName="Work Experience"
          fieldList={data?.workData}
        />
        {setScreen && (
          <ButtonWrapper>
            <CustomButton login text="Schedule Interview" acceptClass />
            <CustomButton login text="Reject Application" rejectClass />
            <CustomButton
              login
              text="Back"
              onclick={() => {
                setScreen(0);
              }}
            />
          </ButtonWrapper>
        )}
      </Container>
    );
  } else {
    return (
      <Container>
        <SubHeading
          primary
          style={{
            margin: "0.5rem 0rem",
          }}
        >
          Basic Details
        </SubHeading>
        <BannerContainer>
          <ImageWrapper>
            <Field
              banner
              status={1}
              fieldName="Profile Picture"
              image="https://images.unsplash.com/photo-1544723795-3fb6469f5b39?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1889&q=80"
            />
          </ImageWrapper>
        </BannerContainer>
        <Field fieldName="Full Name" fieldData="Shuhaib Ahamed" status={1} />
      </Container>
    );
  }
};

export default ProfileView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e1;
  height: 10rem;
  margin-bottom: 2rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 8rem;
  border-radius: 50%;
  height: 8rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
`;
