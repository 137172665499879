import { useState } from "react";
import styled from "styled-components";
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import sha256 from "sha256";
import { useSnackbar } from "notistack";
import { makeStyles, withStyles } from "@mui/styles";
import { Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { passwordReset } from "../../services/passwordService";
import { symmetricEncryption } from "../../services/encryptionService";

//Password strength checker requirements
var setup = require("hsimp-purescript");
var characterSets = require("hsimp-purescript/dictionaries/character-sets.json");
var periods = require("hsimp-purescript/dictionaries/periods.json");
var namednumbers = require("hsimp-purescript/dictionaries/named-numbers.json");
var top10k = require("hsimp-purescript/dictionaries/top10k.json");
var patterns = require("hsimp-purescript/dictionaries/patterns.json");
var checks = require("hsimp-purescript/dictionaries/checks.json");

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const useStyles = makeStyles(() => ({
  TextField: {
    borderRadius: 5,
    color: "#ffffff !important",
    "& .Mui-error": {
      color: "rgb(244, 67, 54)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(244, 67, 54)",
    },
  },
}));

export const NewPasswordForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [displayFormStatus, setDisplayFormStatus] = useState(false);

  //Password Strength
  const [passwordStrength, setPasswordStrength] = useState("a second");

  //Form Status
  // eslint-disable-next-line
  const [formStatus, setFormStatus] = useState({
    message: "",
    type: "",
  });

  //Toggle Password
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });

  //Functions
  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleReset = async (values) => {
    // Decode id
    let decodeId = Buffer.from(id, "base64").toString();
    let credentialObject = JSON.parse(decodeId);

    const secret = symmetricEncryption(
      credentialObject.secretKey,
      sha256(values.password)
    );

    setLoading(true);
    const res = await passwordReset(credentialObject.secretKey, secret);

    if (res === 200) {
      enqueueSnackbar("Your password has been changed successfully", {
        variant: "success",
      });
      history.push("/login");
    }
    setLoading(false);
  };

  // Custom form validation
  const customValidation = (values) => {
    let errors = {};
    //password validation
    var passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/;
    if (!passwordValues.password) {
      errors.password = "Required";
    } else if (passwordValues.password.length < 8) {
      errors.password = "Password must be 8 characters long.";
    } else if (!passwordRegex.test(passwordValues.password)) {
      errors.password =
        "Please valid password. One uppercase, one lowercase, one special character and no spaces";
    }
    return errors;
  };

  //Password strength Checker
  const checkStrength = () => {
    const hsimp = setup({
      calculation: {
        calcs: 40e9,
        characterSets: characterSets,
      },
      time: {
        periods: periods,
        namedNumbers: namednumbers,
        forever: "Forever",
        instantly: "an Instant",
      },
      checks: {
        dictionary: top10k,
        patterns: patterns,
        messages: checks,
      },
    });
    setPasswordStrength(hsimp(passwordValues.password).time);
  };

  return (
    <Formik
      initialValues={{
        password: "",
      }}
      validate={customValidation}
      // ON submit do a API call
      onSubmit={(values, actions) => {
        setTimeout(() => {
          actions.setSubmitting(false);
          handleReset(values);
          // alert(JSON.stringify(values, null, 2));
        }, 3000);
      }}
    >
      {/* Formik Props */}
      {({
        touched,
        errors,
        isValid,
        dirty,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <ContainerForm style={{ rowGap: "1rem" }}>
          <TextField
            fullWidth
            size="small"
            variant="standard"
            disabled={isSubmitting}
            name="password"
            label="New Password"
            id="password"
            classes={{ root: classes.TextField }}
            value={passwordValues.password}
            helperText={
              errors.password && touched.password
                ? errors.password
                : `It takes ${passwordStrength} to crack your password`
            }
            error={errors.password && touched.password ? true : false}
            onBlur={handleBlur}
            InputLabelProps={{
              style: {
                color: "#fff",
                fontFamily: "Poppins",
              },
            }}
            InputProps={{
              onChange: function (e) {
                checkStrength();
                setPasswordValues({
                  ...passwordValues,
                  password: e.target.value,
                });
                setFieldValue("password", e.target.value);
              },
              style: { color: "#fff", padding: ".2rem" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disabled={isSubmitting}
                  >
                    {passwordValues.showPassword ? (
                      <Visibility style={{ color: "#fff" }} />
                    ) : (
                      <VisibilityOff style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: "new-password",
              type: passwordValues.showPassword ? "text" : "password",
            }}
          />
          {isSubmitting || loading ? (
            <LoadingContainer>
              <BorderLinearProgress />
              <Text small lighter style={{ width: "80px" }}>
                Verifying <span className="loading"></span>
              </Text>
            </LoadingContainer>
          ) : (
            <CustomButton
              style={{ marginTop: "2rem" }}
              disabled={!isValid || (isSubmitting && dirty) || loading === true}
              onclick={handleSubmit}
              text="Change Password"
              login
              loginIcon
              loginClass
            />
          )}
        </ContainerForm>
      )}
    </Formik>
  );
};

export const DroppableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

export const InnerContainer = styled.div`
  height: 10rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #dddddda4;
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #dddddd37;
`;

export const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-evenly;
`;

export const LoadingContainer = styled.div`
  height: 48px;
  width: 100%;
  margin-top: 2rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

// const InnerContainer = styled.div`
//   height: 8rem;
//   text-align: center;
//   word-wrap: break-word;
//   border: dashed 0.75px #ae9bf0a3;
//   width: 100%;
//   display: flex;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
//   background-color: #c3d0e147;
// `;
// const DroppableContainer = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   row-gap: 1rem;
//   align-items: center;
//   justify-content: space-evenly;
//   position: "relative";
// `;
