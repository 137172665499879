import styled from "styled-components";
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
// import { ClaimCardHeader } from "./ClaimCardHeader";
import { Text } from "../../components/shared/Typography/Typo";
import TimeAgo from "react-time-ago";
import { urlToHash } from "../../utils/ipfs";
import { ClaimCardHeader } from "../Claim/ClaimCardHeader";

const AssignedItemCard = ({ data }) => {
  const [openImage, setImageOpen] = useState(false);

  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <LargeImage
            alt="image"
            cursor
            src={
              data?.productImage?.data?.data === "undefined" ||
              !data?.productImage?.data?.data?.toString()?.includes("ipfs")
                ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                    data?.productImage?.data?.data
                  )}`
            }
            onClick={() => {
              handleImageOpen();
            }}
          />
        </ImageFlexContainer>
      </Modal>
      <Container>
        <ClaimCardHeader
          addPadding
          certificateName={
            data?.productName?.isPublic
              ? data?.productName?.data?.data
              : "**************"
          }
          description={
            data?.description?.isPublic
              ? data?.description?.data?.data
              : "**************"
          }
        />
        <FieldWrapper addPadding>
          <Text primary lighter small style={{ justifySelf: "start" }}>
            Product Image
          </Text>
          <Image
            loading="lazy"
            cursor
            alt={"Image"}
            src={
              data?.productImage?.data?.data === "undefined" ||
              !data?.productImage?.data?.data?.toString()?.includes("ipfs")
                ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                    data?.productImage?.data?.data
                  )}`
            }
            onClick={() => {
              handleImageOpen();
            }}
          />
        </FieldWrapper>
        <ButtonContainer>
          <ViewButton
            to={{
              pathname: `/products/${data?.productPublicKey}`,
            }}
          >
            View Product
          </ViewButton>
        </ButtonContainer>
      </Container>
    </Fragment>
  );
};

export default AssignedItemCard;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  gap: 0.5rem;
  padding: ${(props) => (props.addPadding ? "1.5rem" : "1rem")};
`;

export const FieldWrapper = styled.div`
  padding: ${(props) => (props.addPadding ? "1rem 1rem" : "0.2rem 1rem")};
  background-color: #c3d0e1;
  display: grid;
  border-radius: 5px;
  grid-template-columns: 1fr 1fr;
`;

const ViewButton = styled(Link)`
  text-decoration: none;
  padding: 0.3rem 1rem;
  background: #1fc36157;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  color: #080036;
  font-family: "Poppins";
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.img`
  width: 4rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

const LargeImage = styled.img`
  text-align: center;
  width: 100%;
  height: auto;
`;

const ImageFlexContainer = styled.div`
  display: grid;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #ffffff;
  transform: translate(-50%, -50%);
  place-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1368px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
