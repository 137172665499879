import React from "react";
import styled from "styled-components";
import EncryptedData from "../Profile/EncryptedData";
import { Text } from "../Typography/Typo";
import HttpsIcon from "@mui/icons-material/Https";
import moment from "moment";
import { ColumnContainer } from "../../../pages/JobListings/JobComponent";
import MultipleImageSlider from "../Form/FormComponents/MultipleImageSlider";
import { ArrowBody, ArrowsContainer } from "../Form/StageForm/StageSliderView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const VerificationCard = ({
  type,
  title,
  institution,
  startDate,
  endDate,
  description,
  logoImage,
  isPublic,
  onClick,
}) => {
  if (
    type === "Education" ||
    type === "Work Experience" ||
    type === "Studying at" ||
    type === "Working at"
  ) {
    return (
      <Container>
        {isPublic ? (
          <SmallImageWrapper
            cursor={onClick}
            onClick={() => {
              onClick(true);
            }}
          >
            <ProfileImage src={logoImage} alt={title} />
          </SmallImageWrapper>
        ) : (
          <SmallImageWrapper
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HttpsIcon sx={{ fontSize: 48 }} style={{ color: "#646464" }} />
          </SmallImageWrapper>
        )}

        <Content>
          <FlexSpaceContainer>
            {isPublic ? (
              <Text style={{ textTransform: "capitalize" }} primary>
                {title}
              </Text>
            ) : (
              <EncryptedData fieldData={title} />
            )}
          </FlexSpaceContainer>

          {isPublic ? (
            <Text style={{ textTransform: "capitalize" }} primary lighter>
              {institution}
            </Text>
          ) : (
            <EncryptedData fieldData={institution} />
          )}
          {isPublic ? (
            <>
              <Text lighter color="#6d6d6d">
                {`${moment(Number(startDate)).format("MMM YYYY")}${
                  !endDate
                    ? ""
                    : endDate === "undefined"
                    ? " -  Present"
                    : endDate > Date.now()
                    ? " - Present"
                    : ` - ${moment(Number(endDate)).format("MMM YYYY")}`
                }`}
              </Text>
            </>
          ) : (
            <EncryptedData fieldData={endDate} />
          )}
          {isPublic ? (
            <>
              {description && (
                <Text style={{ marginTop: "0.5rem" }} primary lighter>
                  {description}
                </Text>
              )}
            </>
          ) : (
            <EncryptedData fieldData={description} />
          )}
        </Content>
      </Container>
    );
  } else {
    return (
      <Container>
        {isPublic ? (
          <>
            {title?.toString()?.includes("ipfs") ? (
              <div>
                <ImageContainer>
                  <Image src={title} alt="image" />
                </ImageContainer>
              </div>
            ) : (
              <Content>
                <Text primary>{title}</Text>
                {description
                  ?.toString()
                  ?.includes("ipfs") ? (
                  <ColumnContainer style={{ gap: "2rem", width: "100%" }}>
                    <MultipleImageSlider data={description?.split(",")} />
                    <ArrowsContainer>
                      <ArrowBody className="prev">
                        <ArrowBackIosNewIcon
                          className="prev"
                          style={{ color: "#080036", cursor: "pointer" }}
                        />
                      </ArrowBody>
                      <ArrowBody className="next">
                        <ArrowForwardIosIcon
                          className="next"
                          style={{ color: "#080036", cursor: "pointer" }}
                        />
                      </ArrowBody>
                    </ArrowsContainer>
                  </ColumnContainer>
                ) : (
                  <Text>{description}</Text>
                )}
              </Content>
            )}
          </>
        ) : (
          <EncryptedData fieldData={title} />
        )}
      </Container>
    );
  }
};

export default VerificationCard;

const Container = styled.div`
  display: inline-block;
  gap: 1rem;
  margin: 0.7rem 0rem;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SmallImageWrapper = styled.div`
  overflow: hidden;
  cursor: ${(props) => props.cursor && "pointer"};
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

export const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const FlexSpaceContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  width: 480px;
  height: 10rem;
  cursor: ${(props) => props.cursor && "pointer"};
  overflow: hidden;
  border-radius: 5px;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  height: auto;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
`;
