import React, { useState } from "react";
import styled from "styled-components";
import { TextField } from "./FormComponents/TextField";
import { IconButton, useMediaQuery } from "@mui/material";
import Select from "react-select";
import { SubHeading } from "../Typography/Typo";
import {
  Container as DropContainer,
  InputContainer,
  Label,
} from "./FormComponents/TextField";
import * as API from "../../../api/API";
import ActionConfirm from "./FormComponents/ActionConfirm";
import { useStateValue } from "../../../context/StateProvider";
import { CustomButton } from "../fields/Button/CustomButton";
import BlockForm from "./BlockForm/BlockForm";
import BlockFormView from "./BlockForm/BlockFormView";
import { getUserSession } from "../../../api/API";
import { AddImageToIPFS } from "../../../services/IpfsService";
import { jwtDecode } from "../../../utils/jwt";
import { createCVObject } from "../../../utils/cvObject";
import { useSnackbar } from "notistack";
import { ADD_USER_DETAILS } from "../../../redux/constants/ActionTypes";
import store from "../../../redux/reducers";
import DropzoneComponent from "../fields/Dropzone/DropzoneComponent";
import { FlexSpaceBetweenContainer } from "../Profile/UserProfile";
import { IconWrapper } from "../../../pages/AddProfile/AddProfile";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomTextFieldModal from "../CustomTextfieldModal/CustomTextFieldModal";
import CustomView from "../CustomTextfieldModal/CustomView";
import PrivacyToggle from "../fields/Button/PrivacyToggle";
import MultipleImageViewSlider from "./StageForm/MultipleImageViewSlider";

export var phoneNoValidation =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

export const techOptions = [
  { value: "js", label: "Java Script" },
  { value: "react", label: "React" },
  { value: "java", label: "Java" },
  { value: "node", label: "Node" },
  { value: "python", label: "Python" },
  { value: "html", label: "HTML" },
  { value: "css", label: "CSS" },
  { value: "angular", label: "Angular" },
];

// country select options
export const countryOptions = [
  { value: "LK", label: "Sri Lanka" },
  { value: "IN", label: "India" },
  { value: "USA", label: "United States" },
  { value: "UK", label: "United Kingdom" },
  { value: "SING", label: "Singapore" },
  { value: "MALAY", label: "Malaysia" },
];

export const EntityProfileForm = ({ setView, profileType }) => {
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [openCustomModal, setCustomModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [formDataState, setFormDataState] = useState([]);
  // form field states
  const [techStack, setTechStack] = useState([]);
  const techStackValue = [];
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState("");
  const [country, setCountry] = useState(countryOptions[0]);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [{ customData, privacyData, achievementFields }, dispatch] =
    useStateValue();
  const matches = useMediaQuery("(max-width:1368px)");
  let previewImageUrl;

  var dataListfieldKeys =
    customData.length > 0 &&
    customData.map((data) => {
      const keyData = Object.keys(data);
      return keyData;
    });

  var customDataListToMap =
    customData.length > 0 &&
    customData.map((data) => {
      return data;
    });

  function handleProductSubmit(e) {
    e.preventDefault();
    setFormDataState(e.currentTarget);
    setShowAction(true);
  }

  //FormData
  const getFormData = async (target) => {
    let nameArr = fullName?.split(" ");
    // Set techStack value
    techStack.forEach((e) => {
      techStackValue.push(e.value);
    });

    var currentDateTime = Date.now();
    var profileObject = {};
    const formData = new FormData(target);
    //add image to ipfs
    if (files.length > 0) {
      const ipfsData = await AddImageToIPFS(files && files[0]);
      previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    }
    for (let [key, value] of formData.entries()) {
      profileObject[key] = value;
    }
    profileObject.country = country.value;
    profileObject.firstName = nameArr[0];
    profileObject.lastName = nameArr[1];
    profileObject.fullName = fullName;
    profileObject.phoneNumber = phoneNumber;
    profileObject.addedDate = currentDateTime;
    profileObject.profileImage = previewImageUrl;
    profileObject.profileType = profileType;
    profileObject.achievements = achievementFields;
    profileObject.customData = customData;

    dispatch({
      type: "CLEAR_ACHIEVEMENT_FIELDS",
    });
    dispatch({
      type: "CLEAR_FIELDS",
    });
    dispatch({
      type: "CLEAR_PRIVACY",
    });
    dispatch({
      type: "CLEAR_CUSTOM_DATA_FIELDS",
    });

    return profileObject;
  };

  const dispatchProduct = async (secretKey) => {
    setLoading(true);
    try {
      let productData = await getFormData(formDataState);
      // Get logged in user
      const user = jwtDecode();
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );
      // add product to ipfs
      const productIpfsHash = await AddImageToIPFS(
        JSON.stringify(formattedObject)
      );
      const response = await API.addCv({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: {
          latestIpfsUrl: {
            ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
            timestamp: Date.now(),
          },
          image: productData.profileImage,
          skills: techStackValue,
          profileType: productData.profileType,
        },
      });
      if (response?.data?.code === 202) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Profile details added", {
            variant: "success",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("entity-form").reset();
      setFiles([]);
      setLoading(false);
      setShowAction(false);
    }
    setView(2);
  };

  return (
    <Container>
      <CustomTextFieldModal
        title="Add Additional Details and Qualifications"
        formId="custom-data-form"
        setCustomModalOpen={setCustomModalOpen}
        openCustomModal={openCustomModal}
      />
      {showAction && (
        <ActionConfirm
          successMessage={{
            message: "Adding CV details into IPFS",
            width: "14rem",
          }}
          warningMessage={"Are you sure you want to submit?"}
          loading={loading}
          setShowAction={setShowAction}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={dispatchProduct}
        />
      )}
      <GridContainer id="entity-form" onSubmit={handleProductSubmit}>
        <FlexSpaceBetweenContainer
          style={{
            gridColumn: `${!matches ? "1/3" : "unset"}`,
            margin: "1rem 0rem",
          }}
        >
          <SubHeading primary>Basic Details</SubHeading>
          <IconWrapper>
            <IconButton
              onClick={() => {
                setView(0);
              }}
            >
              <ArrowBackIosNewIcon
                style={{ color: "#1e1b3b" }}
                sx={{ fontSize: 20 }}
              />
            </IconButton>
          </IconWrapper>
        </FlexSpaceBetweenContainer>

        <TextField
          span={!matches ? true : false}
          label="Entity Name *"
          placeholder="Entity Name"
          id="fullName"
          required
          name="fullName"
          onChange={setFullName}
          value={fullName}
          type="text"
          maxLength="100"
          icon={<PrivacyToggle fieldName="fullName" _id="5218" />}
        />
        <TextField
          span={!matches ? true : false}
          label="About"
          placeholder="About"
          id="bio"
          name="bio"
          type="text"
          formField
          icon={<PrivacyToggle fieldName="bio" _id="6231" />}
        />
        <DropContainer style={{ gridRow: "span 2" }}>
          <PrivacyToggle
            fieldName="profileImage"
            _id="218784"
            select
            top="25%"
            right="1.9%"
          />
          <InputContainer>
            <Label>Profile Picture</Label>
          </InputContainer>
          <DropzoneComponent
            files={files}
            id="dropzoneTwo"
            amount="1"
            form="none"
            multiple={false}
            errors={errors}
            setErrors={setErrors}
            maxSize="1000000"
            setFiles={setFiles}
            type="image/*, image/jpeg, image/png"
            description="Drop or select your profile picture (Max file size 1MB)"
          />
        </DropContainer>
        <TextField
          label="Address Line-1 *"
          placeholder="Address Line One"
          id="addressLineOne"
          required
          name="addressLineOne"
          type="text"
          maxLength="100"
          icon={<PrivacyToggle fieldName="addressLineOne" _id="53658" />}
        />
        <TextField
          label="Address Line-2"
          placeholder="Address Line Two"
          id="addressLineTwo"
          name="addressLineTwo"
          type="text"
          maxLength="100"
          icon={<PrivacyToggle fieldName="addressLineTwo" _id="21763" />}
        />
        <TextField
          label="City *"
          placeholder="City"
          id="city"
          required
          name="city"
          type="text"
          maxLength="50"
          icon={<PrivacyToggle fieldName="city" _id="92282" />}
        />
        <Column>
          <Label>Country *</Label>
          <PrivacyToggle
            fieldName="country"
            _id="921727"
            select
            top={matches ? "50%" : "40%"}
            right="5%"
          />
          <Select
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                outline: "none",
                text: "orangered",
                primary25: "#2f3a6039",
                primary: " #2f3a60a7",
                neutral0: "#c3d0e1",
                boxShadow: "none",
                zIndex: "5",
              },
            })}
            name="country"
            defaultValue={countryOptions[0]}
            options={countryOptions}
            value={country}
            onChange={setCountry}
          />
        </Column>
        <TextField
          label="Phone Number"
          placeholder="0/ 94/ +94/ 0094"
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          maxLength="13"
          icon={<PrivacyToggle fieldName="phoneNumber" _id="27310" />}
          value={phoneNumber}
          onChange={(e) => {
            if (e !== "" && !phoneNoValidation.test(e)) {
              setPhoneNumberError(true);
            } else {
              setPhoneNumberError(false);
            }
            setPhoneNumber(e);
          }}
          color="#FF613F"
          helper={phoneNumberError && "Invalid Mobile Number"}
        />

        <SubHeading
          primary
          style={{
            gridColumn: `${!matches ? "1/3" : "unset"}`,
            margin: "1rem 0rem",
          }}
        >
          Achievements
        </SubHeading>
        <Column span={!matches ? true : false}>
          {achievementFields &&
            achievementFields.length > 0 &&
            achievementFields.map((item, key) => {
              return (
                <BlockFormView
                  type={3}
                  data={item && item.issueDate ? item : item.data}
                  key={key}
                />
              );
            })}
          <Column>
            <Label>Add Achievements</Label>
            <BlockForm type={3} />
          </Column>
        </Column>
        <SubHeading
          primary
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
        >
          Additional Details
        </SubHeading>
        {dataListfieldKeys &&
          dataListfieldKeys.length > 0 &&
          dataListfieldKeys.map((fieldName, key) => {
            if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
              return (
                <ColumnContainer span={"true"}>
                  <MultipleImageViewSlider
                    fieldName={fieldName[0]}
                    data={customDataListToMap[key][fieldName[0]]}
                  />
                </ColumnContainer>
              );
            } else {
              return (
                <TextField
                  key={key}
                  disabled
                  label={
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(0, 1)
                      .toUpperCase() +
                    fieldName[0]
                      .toString()
                      .replace(/([A-Z])/g, " $1")
                      .substring(1)
                  }
                  value={customDataListToMap[key][fieldName[0]]}
                />
              );
            }
          })}
        <CustomView
          style={{ gridColumn: `${!matches ? "1/3" : "unset"}` }}
          setCustomModalOpen={setCustomModalOpen}
        />
      </GridContainer>
      <ColumnContainer>
        <ButtonWrapper>
          <CustomButton
            login
            text="Add Profile Data"
            type="submit"
            form="entity-form"
          />
        </ButtonWrapper>
      </ColumnContainer>
    </Container>
  );
};

export default EntityProfileForm;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
`;

export const GridContainer = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;

export const ButtonWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const Column = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;
