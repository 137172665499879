import React, { Fragment, useState } from "react";
import styled from "styled-components";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import { useSelector } from "react-redux";
import { IconButton, Modal } from "@mui/material";
import { Text } from "../../../pages/JobListings/JobComponent";
import { Heading } from "../Typography/Typo";
import { FieldWrapper, FlexSpaceBetweenContainer } from "./ProductProfile";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { urlToHash } from "../../../utils/ipfs";
import EncryptedData from "../Profile/EncryptedData";

const ProductProfileHeader = ({
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  isPublic,
  setIsPublic,
  setCurrentField,
  decryptType,
  isDecrypted,
  setEditOpen,
  setEditType,
  setShareOpen,
  setContactOpen,
  isViewAssignedItem,
  ownerShipDetails,
}) => {
  const [openImage, setImageOpen] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={
                profileData?.productImage?.data?.data === "undefined" ||
                !profileData?.productImage?.data?.data
                  ?.toString()
                  ?.includes("ipfs")
                  ? "https://www.iconsdb.com/icons/preview/gray/product-xxl.png"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      profileData?.productImage?.data?.data
                    )}`
              }
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      <SectionContainer>
        <AbsoluteContainer>
          <FlexSpaceBetweenContainer style={{ gap: "0.5rem" }}>
            {!isViewAssignedItem && (
              <IconWrapper>
                <IconButton
                  onClick={() => {
                    decryptType("all");
                    isDecrypted
                      ? setShowEncryption(true)
                      : setShowDecryption(true);
                  }}
                >
                  {isDecrypted ? (
                    <LockOpenIcon
                      style={{ color: "#8a8a8a" }}
                      sx={{ fontSize: 20 }}
                    />
                  ) : (
                    <LockIcon
                      style={{ color: "#8a8a8a" }}
                      sx={{ fontSize: 20 }}
                    />
                  )}
                </IconButton>
              </IconWrapper>
            )}
            <IconWrapper>
              <IconButton onClick={() => setIsPublic((value) => !value)}>
                {isPublic ? (
                  <RemoveRedEyeIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                )}
              </IconButton>
            </IconWrapper>{" "}
            {!isViewAssignedItem && (
              <>
                <IconWrapper>
                  <IconButton
                    onClick={() => {
                      setEditType("");
                      if (!isDecrypted) {
                        decryptType("all");
                        setShowDecryption(true);
                        setEditType("basicEdit");
                      } else {
                        setEditOpen((value) => !value);
                      }
                    }}
                  >
                    <EditIcon
                      style={{ color: "#8a8a8a" }}
                      sx={{ fontSize: 20 }}
                    />
                  </IconButton>
                </IconWrapper>{" "}
                <IconWrapper>
                  <IconButton
                    onClick={() => {
                      setEditType("");
                      if (!isDecrypted) {
                        decryptType("all");
                        setShowDecryption(true);
                        setEditType("shareOpen");
                      } else {
                        setShareOpen((value) => !value);
                      }
                    }}
                  >
                    <ShareIcon
                      style={{ color: "#8a8a8a" }}
                      sx={{ fontSize: 20 }}
                    />
                  </IconButton>
                </IconWrapper>
              </>
            )}
          </FlexSpaceBetweenContainer>
        </AbsoluteContainer>

        <Banner>
          <ImageWrapper>
            <ProfileImage
              alt="Product Picture"
              cursor
              src={
                profileData?.productImage?.data?.data === "undefined" ||
                !profileData?.productImage?.data?.data
                  ?.toString()
                  ?.includes("ipfs")
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      profileData?.productImage?.data?.data
                    )}`
              }
              onClick={() => {
                handleImageOpen();
              }}
            />
          </ImageWrapper>

          {isViewAssignedItem && profileData?.productImage?.isPublic ? (
            <VerifiedStatusContainer
              isPublic={isPublic}
              fieldData={profileData?.productImage}
              fieldName="Product Image"
              fieldId={{ id: "productImage" }}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setShowDecryption={setShowDecryption}
              setShowEncryption={setShowEncryption}
              isDecrypted={profileData?.productImage?.isPublic}
              encryptedType={profileData?.productImage?.data?.encryptionType}
              setCurrentField={setCurrentField}
              style={{ top: "40%", position: "absolute", left: "18%" }}
            />
          ) : !isViewAssignedItem ? (
            <VerifiedStatusContainer
              isPublic={isPublic}
              fieldData={profileData?.productImage}
              fieldName="Product Image"
              fieldId={{ id: "productImage" }}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setShowDecryption={setShowDecryption}
              setShowEncryption={setShowEncryption}
              isDecrypted={profileData?.productImage?.isPublic}
              encryptedType={profileData?.productImage?.data?.encryptionType}
              setCurrentField={setCurrentField}
              style={{ top: "40%", position: "absolute", left: "18%" }}
            />
          ) : (
            <></>
          )}
        </Banner>
        <SectionInnerContainer>
          <ContentContainer>
            <FieldWrapper>
              {profileData?.productName?.isPublic ? (
                <Heading
                  primary
                >{`${profileData?.productName?.data?.data}`}</Heading>
              ) : (
                <EncryptedData
                  fieldData={`${profileData?.productName?.data?.data}`}
                  setShowDecryption={setShowDecryption}
                />
              )}

              {isViewAssignedItem && profileData?.productName?.isPublic ? (
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.productName}
                  fieldName="Product Name"
                  fieldId={{ id: "productName" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  isDecrypted={profileData?.productName?.isPublic}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                  setShowDecryption={setShowDecryption}
                  encryptedType={profileData?.productName?.data?.encryptionType}
                  // style={{ top: "70%", position: "absolute", left: "26%" }}
                />
              ) : !isViewAssignedItem ? (
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.productName}
                  fieldName="Product Name"
                  fieldId={{ id: "productName" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  isDecrypted={profileData?.productName?.isPublic}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                  setShowDecryption={setShowDecryption}
                  encryptedType={profileData?.productName?.data?.encryptionType}
                  // style={{ top: "70%", position: "absolute", left: "26%" }}
                />
              ) : (
                <></>
              )}
            </FieldWrapper>

            {profileData?.stageData?.length > 0 ? (
              <FieldWrapper>
                {profileData?.stageData[profileData?.stageData?.length - 1]
                  ?.isPublic ? (
                  <Text primary>{`Operating Stage ${
                    profileData?.stageData[profileData?.stageData?.length - 1]
                      .data.stageName.data
                  }`}</Text>
                ) : (
                  <EncryptedData
                    fieldData={`${
                      profileData?.stageData[profileData?.stageData?.length - 1]
                        .data.stageName.data
                    }`}
                    setShowDecryption={setShowDecryption}
                  />
                )}

                {isViewAssignedItem &&
                profileData?.stageData[profileData?.stageData?.length - 1]
                  ?.isPublic ? (
                  <VerifiedStatusContainer
                    isPublic={isPublic}
                    fieldData={
                      profileData?.stageData[profileData?.stageData?.length - 1]
                    }
                    fieldName="Operating Stage"
                    fieldId={{ id: 0, name: "stageData" }}
                    setShowAction={setShowAction}
                    setVerification={setVerification}
                    isDecrypted={
                      profileData?.stageData[profileData?.stageData?.length - 1]
                        ?.isPublic
                    }
                    setCurrentField={setCurrentField}
                    setShowEncryption={setShowEncryption}
                    setShowDecryption={setShowDecryption}
                    encryptedType={
                      profileData?.stageData[profileData?.stageData?.length - 1]
                        .data?.id?.encryptionType
                    }
                  />
                ) : !isViewAssignedItem ? (
                  <VerifiedStatusContainer
                    isPublic={isPublic}
                    fieldData={
                      profileData?.stageData[profileData?.stageData?.length - 1]
                    }
                    fieldName="Operating Stage"
                    fieldId={{ id: 0, name: "stageData" }}
                    setShowAction={setShowAction}
                    setVerification={setVerification}
                    isDecrypted={
                      profileData?.stageData[profileData?.stageData?.length - 1]
                        ?.isPublic
                    }
                    setCurrentField={setCurrentField}
                    setShowEncryption={setShowEncryption}
                    setShowDecryption={setShowDecryption}
                    encryptedType={
                      profileData?.stageData[profileData?.stageData?.length - 1]
                        .data?.id?.encryptionType
                    }
                  />
                ) : (
                  <></>
                )}
              </FieldWrapper>
            ) : (
              currentUser.email && (
                <FieldWrapper>
                  <Text primary>{`Queries: ${currentUser.email}`}</Text>
                  <VerifiedStatusContainer
                    status={1}
                    isPublic={isPublic}
                    fieldData={`Queries: ${currentUser.email}`}
                    fieldName="Product Email"
                    isDecrypted={true}
                  />
                </FieldWrapper>
              )
            )}
          </ContentContainer>
          {isViewAssignedItem && (
            <ContentContainer
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Text primary lighter>
                  Purchased By :
                </Text>
                {ownerShipDetails ? (
                  <Chip
                    avatar={
                      <Avatar
                        alt={ownerShipDetails?.userAlias}
                        src={ownerShipDetails?.userImage}
                      />
                    }
                    label={ownerShipDetails?.userAlias}
                  />
                ) : (
                  <CircularProgress size={15} />
                )}
              </Stack>
            </ContentContainer>
          )}
        </SectionInnerContainer>
      </SectionContainer>
    </Fragment>
  );
};

export default ProductProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
