import { Button, Modal } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ImageWrapper, Td, Tr } from "./TableRow";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { urlToHash } from "../../../utils/ipfs";

const CredentialRow = ({ item }) => {
  const history = useHistory();
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);

  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Tr
      credentialRow
      onClick={() => {
        history.push({
          pathname: `/dashboard/issue-credentials/${item._id}`,
          state: { item: item },
        });
      }}
    >
      <Td>{item?.itemName}</Td>
      <Td>{item?.email}</Td>
      <Td>{item?.blockchain}</Td>
      <Td>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageFlexContainer>
            <div style={{ position: "relative" }}>
              <LargeImage src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      item?.itemImage
                    )}`} alt="image" />
            </div>
          </ImageFlexContainer>
        </Modal>
        <ImageWrapper
          image={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
            item?.itemImage
          )}`}
          onClick={() => {
            handleImageOpen();
          }}
        />
      </Td>
      <Td>{item?.description}</Td>
      <Td>
        {item?.isClaimed ? (
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
            disabled
            size="small"
          >
            True
          </Button>
        ) : (
          <Button
            style={{
              background: "#ff623f7a",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
            size="small"
            disabled
          >
            False
          </Button>
        )}
      </Td>
    </Tr>
  );
};

export default CredentialRow;
