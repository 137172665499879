import { ADD_IPFS_DATA } from "../redux/constants/ActionTypes";
import { GetIPFSData } from "../services/IpfsService";
import { urlToHash } from "./ipfs";
import store from "../redux/reducers";

export const fetchLatestProfileData = async (
  setLoading,
  setView,
  currentUser
) => {
  setLoading(true);
  if (currentUser?.latestIpfsUrl?.ipfsUrl) {
    const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
    const response = await GetIPFSData(hash);
    if (response) {
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: response,
      });
      setView(2);
    } else {
      setView(0);
    }
  }
  setLoading(false);
};
