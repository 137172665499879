import moment from "moment";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import PropTypes from "prop-types";
import EncryptedData from "./EncryptedData";
import HttpsIcon from "@mui/icons-material/Https";
import { urlToHash } from "../../../utils/ipfs";

const ExperienceCard = ({
  fieldId,
  title,
  institution,
  startDate,
  endDate,
  description,
  logoImage,
  isPublic,
  setShowAction,
  setVerification,
  fieldData,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  encryptedType,
}) => {
  return (
    <Container>
      {fieldData?.isPublic ? (
        <SmallImageWrapper>
          <ProfileImage
            src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(logoImage)}`}
            alt={title}
          />
        </SmallImageWrapper>
      ) : (
        <SmallImageWrapper
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HttpsIcon sx={{ fontSize: 48 }} style={{ color: "#646464" }} />
        </SmallImageWrapper>
      )}

      <Content>
        <FlexSpaceContainer>
          {fieldData?.isPublic ? (
            <Text primary>{title}</Text>
          ) : (
            <EncryptedData
              fieldData={title}
              setShowDecryption={setShowDecryption}
            />
          )}

          <VerifiedStatusContainer
            fieldId={fieldId}
            isPublic={isPublic}
            fieldData={fieldData}
            fieldName={
              fieldData?.data?.position ? "Work Experience" : "Education"
            }
            setShowAction={setShowAction}
            setVerification={setVerification}
            setShowDecryption={setShowDecryption}
            isDecrypted={fieldData?.isPublic}
            setCurrentField={setCurrentField}
            setShowEncryption={setShowEncryption}
            encryptedType={encryptedType}
          />
        </FlexSpaceContainer>

        {fieldData?.isPublic ? (
          <Text primary lighter>
            {institution}
          </Text>
        ) : (
          <EncryptedData
            fieldData={institution}
            setShowDecryption={setShowDecryption}
          />
        )}
        {fieldData?.isPublic ? (
          <Text lighter color="#6d6d6d">
            {`${moment(Number(startDate)).format("MMM YYYY")} - ${
              endDate === "undefined"
                ? "Present"
                : endDate > Date.now()
                ? "Present"
                : moment(Number(endDate)).format("MMM YYYY")
            }`}
          </Text>
        ) : (
          <EncryptedData
            fieldData={endDate}
            setShowDecryption={setShowDecryption}
          />
        )}
        {fieldData?.isPublic ? (
          <>
            {description && (
              <Text style={{ marginTop: "0.5rem" }} primary lighter>
                {description}
              </Text>
            )}
          </>
        ) : (
          <EncryptedData
            fieldData={description}
            setShowDecryption={setShowDecryption}
          />
        )}
      </Content>
    </Container>
  );
};

ExperienceCard.propTypes = {
  status: PropTypes.number,
  isPublic: PropTypes.bool,
  setVerification: PropTypes.func,
  setShowAction: PropTypes.func,
  title: PropTypes.string,
  institution: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  description: PropTypes.string,
  logoImage: PropTypes.any,
  fieldData: PropTypes.object,
};

export default ExperienceCard;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0.5rem 0rem;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const FlexSpaceContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;
