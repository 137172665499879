import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Text } from "../../../pages/JobListings/JobComponent";
import { Heading } from "../Typography/Typo";
import { IconButton, Modal } from "@mui/material";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import { FieldWrapper, FlexSpaceBetweenContainer } from "./UserProfile";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShareIcon from "@mui/icons-material/Share";
import EncryptedData from "./EncryptedData";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { urlToHash } from "../../../utils/ipfs";
import EditIcon from "@mui/icons-material/Edit";

const UserProfileHeader = ({
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  isPublic,
  setIsPublic,
  setCurrentField,
  decryptType,
  isDecrypted,
  setEditOpen,
  setEditType,
  setShareOpen,
  setContactOpen,
}) => {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleContactOpen = () => {
    setEditType("");
    if (!isDecrypted) {
      decryptType("all");
      setShowDecryption(true);
      setEditType("contact");
    } else {
      setContactOpen(true);
    }
  };

  return (
    <Fragment>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={
                profileData?.profileImage?.data?.data === "undefined" ||
                  !profileData?.profileImage?.data?.data
                    ?.toString()
                    ?.includes("ipfs")
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                    profileData?.profileImage?.data?.data
                  )}`
              }
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>

      <SectionContainer>
        <AbsoluteContainer>
          <FlexSpaceBetweenContainer style={{ gap: "0.5rem" }}>
            {/* Encrypting and decrypting icon */}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setEditType("");
                  decryptType("all");
                  isDecrypted
                    ? setShowEncryption(true)
                    : setShowDecryption(true);
                }}
              >
                {isDecrypted ? (
                  <LockOpenIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                ) : (
                  <LockIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                )}
              </IconButton>
            </IconWrapper>
            {/* Verification icon */}
            <IconWrapper>
              <IconButton onClick={() => setIsPublic((value) => !value)}>
                {isPublic ? (
                  <RemoveRedEyeIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ color: "#8a8a8a" }}
                    sx={{ fontSize: 20 }}
                  />
                )}
              </IconButton>
            </IconWrapper>{" "}
            {/* Edit icon */}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setEditType("");
                  if (!isDecrypted) {
                    decryptType("all");
                    setShowDecryption(true);
                    setEditType("basicEdit");
                  } else {
                    setEditOpen((value) => !value);
                  }
                }}
              >
                <EditIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              </IconButton>
            </IconWrapper>{" "}
            {/* Share icon */}
            <IconWrapper>
              <IconButton
                onClick={() => {
                  setEditType("");
                  if (!isDecrypted) {
                    decryptType("all");
                    setShowDecryption(true);
                    setEditType("shareOpen");
                  } else {
                    setShareOpen((value) => !value);
                  }
                }}
              >
                <ShareIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              </IconButton>
            </IconWrapper>
          </FlexSpaceBetweenContainer>
        </AbsoluteContainer>

        <Banner>
          <ImageWrapper>
            <ProfileImage
              alt="Profile Picture"
              cursor
              src={
                profileData?.profileImage?.data?.data === "undefined" ||
                  !profileData?.profileImage?.data?.data
                    ?.toString()
                    ?.includes("ipfs")
                  ? "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                  : `https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                    profileData?.profileImage?.data?.data
                  )}`
              }
              onClick={() => {
                handleImageOpen();
              }}
            />
          </ImageWrapper>
          <VerifiedStatusContainer
            isPublic={isPublic}
            fieldData={profileData?.profileImage}
            fieldName="Profile Image"
            fieldId={{ id: "profileImage" }}
            setShowAction={setShowAction}
            setVerification={setVerification}
            setShowDecryption={setShowDecryption}
            setShowEncryption={setShowEncryption}
            isDecrypted={profileData?.profileImage?.isPublic}
            encryptedType={profileData?.profileImage?.data?.encryptionType}
            setCurrentField={setCurrentField}
            style={{ top: "40%", position: "absolute", left: "18%" }}
          />
        </Banner>
        <SectionInnerContainer>
          <ContentContainer>
            <NameContainer>
              <FieldWrapper>
                {profileData?.firstName?.isPublic ? (
                  <Heading
                    primary
                  >{`${profileData?.firstName?.data?.data}`}</Heading>
                ) : (
                  <EncryptedData
                    fieldData={`${profileData?.firstName?.data?.data}`}
                    setShowDecryption={setShowDecryption}
                  />
                )}

                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.firstName}
                  fieldName="First Name"
                  fieldId={{ id: "firstName" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  isDecrypted={profileData?.firstName?.isPublic}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                  setShowDecryption={setShowDecryption}
                  encryptedType={profileData?.firstName?.data?.encryptionType}
                // style={{ top: "8%", position: "absolute", left: "10%" }}
                />
              </FieldWrapper>
              <FieldWrapper>
                {profileData?.lastName?.isPublic ? (
                  <Heading
                    primary
                  >{`${profileData?.lastName?.data?.data}`}</Heading>
                ) : (
                  <EncryptedData
                    fieldData={`${profileData?.lastName?.data?.data}`}
                    setShowDecryption={setShowDecryption}
                  />
                )}

                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.lastName}
                  fieldName="Last Name"
                  fieldId={{ id: "lastName" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  isDecrypted={profileData?.lastName?.isPublic}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                  setShowDecryption={setShowDecryption}
                  encryptedType={profileData?.lastName?.data?.encryptionType}
                // style={{ top: "8%", position: "absolute", left: "20%" }}
                // style={{ gap: "0rem" }}
                />
              </FieldWrapper>
            </NameContainer>

            {profileData?.workData?.length > 0 ? (
              <FieldWrapper>
                {profileData?.workData[0]?.isPublic ? (
                  <Text
                    primary
                  >{`${profileData?.workData[0]?.data?.position?.data} at ${profileData?.workData[0]?.data?.company?.data}`}</Text>
                ) : (
                  <EncryptedData
                    fieldData={`${profileData?.workData[0]?.data?.position?.data} at ${profileData?.workData[0]?.data?.company?.data}`}
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  status={0}
                  isPublic={isPublic}
                  fieldData={profileData?.workData[0]}
                  fieldName="Current Work Place"
                  fieldId={{ id: 0, name: "workData" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.workData[0]?.isPublic}
                  encryptedType={
                    profileData?.workData[0]?.data?.imageLogo?.encryptionType
                  }
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                />
              </FieldWrapper>
            ) : (
              profileData?.educationData?.length > 0 && (
                <FieldWrapper>
                  {profileData?.educationData[0]?.isPublic ? (
                    <Text
                      primary
                    >{`Student at ${profileData?.educationData[0]?.data?.institutionName?.data}`}</Text>
                  ) : (
                    <EncryptedData
                      fieldData={`Student at ${profileData?.educationData[0]?.data?.institutionName?.data}`}
                      setShowDecryption={setShowDecryption}
                    />
                  )}
                  <VerifiedStatusContainer
                    status={1}
                    isPublic={isPublic}
                    fieldData={profileData?.educationData[0]}
                    fieldName="Student at"
                    fieldId={{ id: 0, name: "educationData" }}
                    setShowAction={setShowAction}
                    setVerification={setVerification}
                    setShowDecryption={setShowDecryption}
                    isDecrypted={profileData?.educationData[0]?.isPublic}
                    encryptedType={
                      profileData?.educationData[0]?.data?.imageLogo
                        ?.encryptionType
                    }
                    setCurrentField={setCurrentField}
                    setShowEncryption={setShowEncryption}
                  />
                </FieldWrapper>
              )
            )}
            <FieldWrapper>
              <NameContainer>
                {profileData?.city?.isPublic ? (
                  <Text lighter color="#6d6d6d">
                    {`${profileData?.city?.data?.data} District, Western, `}
                  </Text>
                ) : (
                  <EncryptedData
                    fieldData={`${profileData?.city?.data?.data} District, Western, `}
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.city}
                  fieldName="Lives in"
                  fieldId={{ id: "city" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.city?.isPublic}
                  encryptedType={profileData?.city?.data?.encryptionType}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                // style={{ top: "60%", position: "absolute", left: "24%" }}
                />

                {profileData?.country?.isPublic ? (
                  <Text lighter color="#6d6d6d">
                    {` ${profileData?.country?.data?.data}`}
                  </Text>
                ) : (
                  <EncryptedData
                    fieldData={` ${profileData?.country?.data?.data}`}
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  isPublic={isPublic}
                  fieldData={profileData?.country}
                  fieldName="Lives in"
                  fieldId={{ id: "country" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.country?.isPublic}
                  encryptedType={profileData?.country?.data?.encryptionType}
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                // style={{ top: "60%", position: "absolute", left: "28.8%" }}
                />
              </NameContainer>
            </FieldWrapper>
            <FieldWrapper>
              <SpanLink onClick={handleContactOpen}>Contact Info</SpanLink>
            </FieldWrapper>
          </ContentContainer>
          <ContentContainer style={{ gap: "0.8rem" }}>
            {profileData?.workData?.length > 0 && (
              <FlexContainer>
                {profileData?.workData[0]?.isPublic ? (
                  <SmallImageWrapper>
                    <ProfileImage
                      src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                        profileData?.workData[0]?.data?.imageLogo?.data
                      )}`}
                    />
                  </SmallImageWrapper>
                ) : (
                  <></>
                )}
                {profileData?.workData[0]?.isPublic ? (
                  <Text primary>
                    {profileData?.workData[0]?.data?.company?.data}
                  </Text>
                ) : (
                  <EncryptedData
                    fieldData={profileData?.workData[0]?.data?.company?.data}
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  status={0}
                  isPublic={isPublic}
                  fieldData={profileData?.workData[0]}
                  fieldName="Working at"
                  fieldId={{ id: 0, name: "workData" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.workData[0]?.isPublic}
                  encryptedType={
                    profileData?.workData[0]?.data?.imageLogo?.encryptionType
                  }
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                />
              </FlexContainer>
            )}
            {profileData?.educationData?.length > 0 && (
              <FlexContainer>
                {profileData?.educationData[0]?.isPublic ? (
                  <SmallImageWrapper>
                    <ProfileImage
                      src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                        profileData?.educationData[0]?.data?.imageLogo?.data
                      )}`}
                    />
                  </SmallImageWrapper>
                ) : (
                  <></>
                )}
                {profileData?.educationData[0]?.isPublic ? (
                  <Text primary>
                    {profileData?.educationData[0]?.data?.institutionName?.data}
                  </Text>
                ) : (
                  <EncryptedData
                    fieldData={
                      profileData?.educationData[0]?.data?.institutionName?.data
                    }
                    setShowDecryption={setShowDecryption}
                  />
                )}
                <VerifiedStatusContainer
                  status={0}
                  isPublic={isPublic}
                  fieldData={profileData?.educationData[0]}
                  fieldName="Studying at"
                  fieldId={{ id: 0, name: "educationData" }}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setShowDecryption={setShowDecryption}
                  isDecrypted={profileData?.educationData[0]?.isPublic}
                  encryptedType={
                    profileData?.educationData[0]?.data?.imageLogo
                      ?.encryptionType
                  }
                  setCurrentField={setCurrentField}
                  setShowEncryption={setShowEncryption}
                />
              </FlexContainer>
            )}
          </ContentContainer>
        </SectionInnerContainer>
      </SectionContainer>
    </Fragment>
  );
};

export default UserProfileHeader;

export const SectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  background-color: #eff2f5;
  border-radius: 10px;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 2.5rem;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const SpanLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #1696ff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin-top: 3rem;
  margin-left: 2rem;
  @media (max-width: 1368px) {
    margin-top: 3rem;
    margin-left: 1rem;
    width: 8rem;
    height: 8rem;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Banner = styled.div`
  position: relative;
  background: linear-gradient(45deg, #0575e6 1%, #00f260 100%);
  height: 10rem;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 1368px) {
    height: 8rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const Hai = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 4%;
  right: 1.5%;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
`;
