import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import QRCode from "qrcode.react";

const QrCodeGen = ({ name, link }) => {
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    var dataURL = canvas.toDataURL().replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = dataURL;
    downloadLink.download = `${name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <QRContainer>
      <QRCode
        id="qr-gen"
        value={link}
        fgColor="#293149"
        size={170}
        level="H"
        title="Download QR"
      />
      <Button
        style={{
          background: "#1fc36157",
          color: "#080036",
          textTransform: "inherit",
          fontWeight: "500",
          fontFamily: "Poppins",
          padding: "0.3rem 1rem",
          float: "right",
        }}
        onClick={downloadQRCode}
        size="small"
      >
        Download
      </Button>
    </QRContainer>
  );
};

export default QrCodeGen;

export const QRContainer = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;
