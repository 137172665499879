import React, { Fragment } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import Divider from "@mui/material/Divider";
import Logo from "../../../assets/images/logosmall.svg";
import { CustomButton } from "../fields/Button/CustomButton";
import { StudentNav, CredentialProvider, ProductNav } from "./SwitchNav";
import { useSelector } from "react-redux";
import Settings from "@mui/icons-material/Settings";

function SideNav(props) {
  const currentUser = useSelector((state) => state.currentUser);
  const history = useHistory();

  return (
    <Container
      showSideBar={props.sideBarActive}
      onClick={() => {
        props.setShowSideBar(false);
      }}
    >
      <InnerContainer>
        <HeadingWrapper>
          <FlexContainer>
            <Icon src={Logo} alt="Logo" style={{ marginBottom: "1rem" }} />
          </FlexContainer>

          <Divider style={{ background: "#ffffff44" }} />
        </HeadingWrapper>
        <FlexContainer>
          {(() => {
            switch (currentUser.type) {
              case "PROFILE":
                return (
                  <>
                    {(() => {
                      if (currentUser?.profileType === 3)
                        return <>{ProductNav(history)}</>;
                      else if (currentUser?.profileType === 1)
                        return <>{StudentNav(history)}</>;
                      else return <>{StudentNav(history)}</>;
                    })()}
                  </>
                );

              case "CREDENTIALPROVIDER":
                return <Fragment>{CredentialProvider(history)}</Fragment>;
              default:
                return null;
            }
          })()}
          <CustomButton
            text="Settings"
            tab
            onclick={() => {
              history.push("/dashboard/settings");
            }}
            icon={<Settings />}
          />
        </FlexContainer>
      </InnerContainer>
    </Container>
  );
}

export default SideNav;

const Container = styled.div`
  color: #fff;
  z-index: 999;
  overflow: hidden;
  position: relative;
  width: 26rem;
  border-radius: 10px;
  background: linear-gradient(180deg, #293149 0%, #1b2236 100%);
  @media (max-width: 1368px) {
    position: absolute;
    top: 0;
    left: ${(props) => (props.showSideBar ? "0" : "-100%")};
    transition: ${(props) => (props.showSideBar ? "350ms" : "850ms")};
    border-radius: 0;
    height: 100%;
    width: 20rem;
  }
  @media (max-width: 768px) {
    width: 16rem;
    height: 100%;
  }
`;

const InnerContainer = styled.div`
  height: 90%;
  margin: 2rem;
  @media (max-width: 768px) {
    height: 90%;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexContainer = styled.div`
  display: flex;
  height: 85%;
  flex-direction: column;
  justify-content: space-between;
`;

const Icon = styled.img`
  width: 10rem;
  height: auto;
`;
