import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";

import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import {
  ButtonWrapper,
  ContainerForm,
  OuterContainer,
  privacyOptions,
  TextInputContainer,
} from "../../Form/BlockForm/BlockForm";
import uuid from "react-uuid";
import { Label, TextField } from "../../Form/FormComponents/TextField";
import { Column } from "../../Form/Web3ResumeContainer";
import Select from "react-select";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Text } from "../../Typography/Typo";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import { useStateValue } from "../../../../context/StateProvider";
import { useSnackbar } from "notistack";
import BlockFormView from "../../Form/BlockForm/BlockFormView";

function AddAchieveDataForm() {
  const [{ achievementFields }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [privacyAch, setPrivacyAch] = useState(privacyOptions[0]);
  const [name, setName] = useState("");
  const [issuer, setIssuer] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [errors, setErrors] = useState("");
  const [description, setDescription] = useState("");
  const matches = useMediaQuery("(max-width:1368px)");
  const [issueDate, setIssueDate] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleAchievementFieldSubmit = async (e) => {
    setLoading(true);
    const ipfsData = await AddImageToIPFS(
      imageFile && imageFile.length > 0 && imageFile[0]
    );
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    e.preventDefault();
    try {
      dispatch({
        type: "ADD_ACHIEVEMENT_FIELDS",
        item: {
          id: uuid(),
          itemName: name,
          description: description,
          issueDate: new Date(issueDate).valueOf(),
          issuer: issuer,
          itemImage: previewImageUrl,
          // customData: fieldData,
          isPublic: privacyAch.value,
        },
      });
      enqueueSnackbar("Achievments details added", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setName("");
      setIssuer("");
      setIssueDate("");
      setImageFile([]);
      setDescription("");
      setPrivacyAch(privacyOptions[0]);
      setLoading(false);
    }
  };

  return (
    <>
      {achievementFields?.length > 0 && (
        <Column span={!matches ? true : false} style={{ marginTop: "4rem" }}>
          {achievementFields &&
            achievementFields.length > 0 &&
            achievementFields.map((item, key) => {
              return (
                <BlockFormView
                  type={3}
                  data={item && item.issueDate ? item : item.data}
                  key={key}
                />
              );
            })}
        </Column>
      )}
      <OuterContainer style={{ width: "100%" }}>
        <ContainerForm id="achievementForm" name="achievementForm">
          <TextField
            label="Credential Name *"
            placeholder="Credential Name"
            id="certificateName"
            value={name}
            form="none"
            onChange={setName}
            name="certificateName"
            type="text"
            maxLength="100"
          />
          <TextField
            label="Description *"
            placeholder="Description"
            id="description"
            value={description}
            form="none"
            onChange={setDescription}
            name="description"
            type="text"
          />{" "}
          <Column style={{ gridRow: "span 2" }}>
            <Label>Credential Image *</Label>
            <DropzoneComponent
              files={imageFile}
              id="dropzone"
              amount="1"
              form="none"
              setErrors={setErrors}
              errors={errors}
              multiple={false}
              maxSize="1000000"
              setFiles={setImageFile}
              type="image/*, image/jpeg, image/png"
              description="Drop or select your credential image (Max file size 1MB)"
            />
          </Column>{" "}
          <TextField
            label="Issuer *"
            placeholder="issuer Name"
            id="issuer"
            value={issuer}
            form="none"
            onChange={setIssuer}
            name="issuer"
            type="text"
            maxLength="300"
          />
          <Column>
            <Label>Issued Date *</Label>
            <TextInputContainer>
              <DatePicker
                monthPlaceholder="MM"
                yearPlaceholder="YY"
                dayPlaceholder="DD"
                form="none"
                onChange={setIssueDate}
                value={issueDate}
                clearIcon={false}
                calendarIcon={
                  <CalendarTodayIcon style={{ color: "#080036" }} />
                }
                className="custom-date"
              />
            </TextInputContainer>
          </Column>{" "}
          <Column span={!matches ? true : false}>
            <Label>Private / Public</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#c3d0e1",
                  boxShadow: "none",
                },
              })}
              name="isPublic"
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={privacyOptions[0]}
              options={privacyOptions}
              value={privacyAch}
              onChange={setPrivacyAch}
            />
          </Column>
          <ButtonWrapper
            style={{ girdColumn: `${!matches ? "1/3" : "unset"}` }}
          >
            <Button
              style={{
                background: "#1fc36157",
                color: "#080036",
                textTransform: "inherit",
                fontWeight: "500",
                fontFamily: "Poppins",
                padding: ".3rem 1rem",
                float: "right",
              }}
              endIcon={loading && <CircularProgress size={20} />}
              form="achievementForm"
              onClick={handleAchievementFieldSubmit}
              disabled={
                loading ||
                imageFile === [] ||
                name === "" ||
                issueDate === undefined ||
                issuer === ""
              }
              size="small"
            >
              Add Entry
            </Button>
          </ButtonWrapper>
        </ContainerForm>
      </OuterContainer>
    </>
  );
}

export default AddAchieveDataForm;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
