import moment from "moment";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import VerifiedStatusContainer from "./VerifiedStatusContainer";
import PropTypes from "prop-types";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { Modal } from "@mui/material";
import EncryptedData from "./EncryptedData";
import HttpsIcon from "@mui/icons-material/Https";
import { urlToHash } from "../../../utils/ipfs";

const TokenCard = ({
  fieldId,
  itemName,
  issuer,
  issueDate,
  description,
  itemImage,
  isPublic,
  status,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  encryptedType,
  fieldData,
  setCurrentField,
}) => {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Fragment>
      <Modal
        open={openImage}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ImageFlexContainer>
          <div style={{ position: "relative" }}>
            <LargeImage
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(itemImage)}`}
              alt="image"
            />
          </div>
        </ImageFlexContainer>
      </Modal>
      <Container>
        {fieldData?.isPublic ? (
          <LargeImageWrapper>
            <ProfileImage
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(itemImage)}`}
              alt={itemName}
              cursor
              onClick={() => {
                handleImageOpen();
              }}
            />
          </LargeImageWrapper>
        ) : (
          <LargeImageWrapper
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HttpsIcon sx={{ fontSize: 48 }} style={{ color: "#646464" }} />
          </LargeImageWrapper>
        )}

        <Content>
          <FlexSpaceContainer>
            {" "}
            {fieldData?.isPublic ? (
              <Text primary>{itemName}</Text>
            ) : (
              <EncryptedData
                fieldData={itemName}
                setShowDecryption={setShowDecryption}
              />
            )}
            <VerifiedStatusContainer
              // style={{ marginTop: "-3.5rem" }}
              isPublic={isPublic}
              fieldData={fieldData}
              fieldName={
                fieldData?.data?.position ? "Work Experience" : "Education"
              }
              fieldId={fieldId}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setShowDecryption={setShowDecryption}
              isDecrypted={fieldData?.isPublic}
              setCurrentField={setCurrentField}
              setShowEncryption={setShowEncryption}
              encryptedType={encryptedType}
            />
          </FlexSpaceContainer>
          {fieldData?.isPublic ? (
            <Text primary lighter>
              {issuer}
            </Text>
          ) : (
            <EncryptedData
              fieldData={issuer}
              setShowDecryption={setShowDecryption}
            />
          )}
          {fieldData?.isPublic ? (
            <Text lighter color="#6d6d6d">
              {moment(Number(issueDate)).format("MMM YYYY")}
            </Text>
          ) : (
            <EncryptedData
              fieldData={issueDate}
              setShowDecryption={setShowDecryption}
            />
          )}{" "}
          {fieldData?.isPublic ? (
            <>
              {description && (
                <Text style={{ marginTop: "0.5rem" }} primary lighter>
                  {description}
                </Text>
              )}
            </>
          ) : (
            <EncryptedData
              fieldData={description}
              setShowDecryption={setShowDecryption}
            />
          )}
        </Content>
      </Container>
    </Fragment>
  );
};

TokenCard.propTypes = {
  status: PropTypes.number,
  isPublic: PropTypes.bool,
  setVerification: PropTypes.func,
  setShowAction: PropTypes.func,
  itemName: PropTypes.string,
  issuer: PropTypes.string,
  issueDate: PropTypes.string,
  description: PropTypes.string,
  itemImage: PropTypes.any,
  fieldData: PropTypes.object,
};

export default TokenCard;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0.5rem 0rem;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LargeImageWrapper = styled.div`
  overflow: hidden;
  width: 6.5rem;
  height: 6.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const FlexSpaceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
