import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";

//Icons
import AddProfile from "../AddProfile/AddProfile";
import JobListings from "../JobListings/JobListings";
import Claim from "../Claim/Claim";
import Settings from "../Settings/Settings";
import AssignedItems from "./../ViewAssignedItem/AssignedItems";
import Scans from "../Scans/Scans";

function DashBoardSwitch() {
  return (
    <Switch>
      <Route path="/dashboard/my-profile" exact component={AddProfile} />
      <Route path="/dashboard/job-listings" exact component={JobListings} />

      <Route path="/dashboard/settings" exact component={Settings} />
      <Route path="/dashboard/settings" exact component={Settings} />
      {/* <Route path="/dashboard/certificates/:id">
        <Redirect to="/dashboard/credentials/:id" />
      </Route> */}
      {/* <Route path="/dashboard/certificates">
        <Redirect to="/dashboard/credentials" />
      </Route> */}

      {/* <Route path="/dashboard/tokens">
        <Redirect to="/dashboard/credentials" />
      </Route> */}
      {/* <Route path="/dashboard/tokens/:id">
        <Redirect to="/dashboard/credentials/:id" />
      </Route> */}

      <Route path="/dashboard/credentials" exact component={Claim} />
      <Route path="/dashboard/credentials/:id" exact component={Claim} />

      <Route path="/dashboard/certificates" exact component={Claim} />
      <Route path="/dashboard/certificates/:id" exact component={Claim} />

      <Route path="/dashboard/tokens" exact component={Claim} />
      <Route path="/dashboard/tokens/:id" exact component={Claim} />

      <Route path="/products" exact component={AssignedItems} />
      <Route path="/products/:id" exact component={AssignedItems} />

      <Route path="/tickets" exact component={Scans} />
      <Route path="/tickets/:id" exact component={Scans} />

      <Container>
        {/* {item && ( */}
        {/* <ButtonContainer
          cursor
          span
          smallLink
          link="/dashboard/certificates"
          heading="View Claimed Credentials"
          description="Go ahead and view your credentials here."
          icon={"view_cozy"}
        /> */}
        {/* )} */}
        <ButtonContainer
          bigLink
          link="/dashboard/my-profile"
          heading="My Profile"
          description="Add, remove or edit your personal details here."
          icon={"supervisor_account"}
        />
        {/* <ButtonContainer
          bigLink
          link="/dashboard/job-listings"
          heading="Job Listings"
          description="Find the list of jobs posted by companies here."
          icon={"work"}
        />   */}
        <ButtonContainer
          bigLink
          link="/products"
          heading="Products"
          description="Find the list of products assigned to you."
          icon={"shop"}
        />
      </Container>
    </Switch>
  );
}

export default DashBoardSwitch;

export const Container = styled.div`
  display: grid;
  align-content: flex-start;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1068px) {
    grid-template-columns: 1fr;
  }
`;
