import React from "react";
import "../../../assets/css/loading.css";
import styled from "styled-components";
import Logo from "../../../assets/images/logo-single.svg";

const LoadingView = ({ message }) => {
  return (
    <div className="viewport">
      <div>
        <span>
          <span></span>
        </span>
        <span>
          <span></span>
        </span>
      </div>
      <div className="loader">
        {/*?xml version="1.0" encoding="utf-8"?*/}
        {/* Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 250 250"
          style={{ enableBackground: "new 0 0 250 250" }}
          xmlSpace="preserve"
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: "\n\t.myColor{fill: #fff;}\n",
            }}
          />
          <path
            className="myColor"
            d="M20,100c0,44.2,35.8,80,80,80s80-35.8,80-80c0,46.4-35.8,84-80,84S20,146.4,20,100"
          >
            <animateTransform
              accumulate="none"
              additive="replace"
              attributeName="transform"
              begin="0s"
              calcMode="linear"
              dur="2s"
              fill="remove"
              keyTimes="0;1"
              repeatCount={10}
              restart="always"
              type="rotate"
              values="0 100 100;360 100 100"
            ></animateTransform>
          </path>
        </svg>
        <img className="logo flash" alt="loading" src={Logo} />
      </div>
      {message && (
        <GridText half={message?.length < 10}>
          <p style={{ color: "#fff" }}>{message}</p>
          <span className="loading"></span>
        </GridText>
      )}
    </div>
  );
};

export default LoadingView;

const GridText = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: ${(props) => (props.half ? "1fr 0.3fr" : "22fr 1fr")};
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
