import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import FlipIcon from '@mui/icons-material/Flip';

import Slide from '@mui/material/Slide';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { QrReader } from 'react-qr-reader';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CameraDialog() {
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [data, setData] = useState(null);
    const [camera, setCamera] = useState("environment");
    const [showError, setShowError] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpenError = () => {
        setOpenError(true);
    };

    const handleCloseError = () => {
        setOpenError(false);
    };
    const flipDevice = () => {
        if(camera=="environment") setCamera("user")
        if(camera=="user") setCamera("environment")
    };

    useEffect(() => {
        if (data) {
            document.location.replace(data)
        }
    }, [data]);
    return (
        <div>
            <IconButton onClick={handleClickOpen} >
                <QrCodeScannerIcon style={{ color: "#fff" }} />
            </IconButton>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', "background": "#1b2236" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            QR Scanner
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={flipDevice}>
                            <FlipIcon />
                        </Button> */}
                    </Toolbar>
                </AppBar>
                {!showError && <QrReader
                    constraints={{ facingMode: camera }}
                    onResult={(result, error) => {
                        if (!!result) {
                            if (result?.text.includes("qr.app.deprofile.io")) {
                                setData(result?.text);
                            } else {
                                handleClickOpenError()
                            }
                        }
                        if (!!error) {
                            // handleClickOpenError()

                        }
                    }}
                    style={{
                        height: '100%'
                    }}
                />}

                {/* {showError&&} */}
                {/* <Webcam style={{
                        width: '100%'
                    }} audio={false} videoConstraints={{ deviceId: devices[0].deviceId }} /> */}

                {/* {deviceId && <Webcam style={{
                    height: '100%'
                }} audio={false} videoConstraints={{ deviceId: deviceId }} />} */}

                {/* {devices.map((device, key) => (
                    <Webcam style={{
                        width: '100%'
                    }} audio={false} videoConstraints={{ deviceId: device.deviceId }} />
                ))} */}
            </Dialog>
            <Dialog
                open={openError}
                onClose={handleCloseError}

            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The Qrcode is Invalid!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseError}>Try Again</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
