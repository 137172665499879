//FormData
export let getFormData = (target) => {
  var productObject = {};
  let formData = new FormData(target);
  for (let [key, value] of formData.entries()) {
    productObject[key] = value;
  }
  return productObject;
};

export let getFormDataWithCustomImages = (target, imageArr) => {
  var productObject = {};
  let formData = new FormData(target);
  for (let [key, value] of formData.entries()) {
    productObject[key] = value;
  }
  if (imageArr.length > 0) {
    for (let i; i < imageArr.length; i++) {
      // productObject[]
    }
  }

  return productObject;
};

export let getFormDataById = (id) => {
  var productObject = {};
  let formData = new FormData(document.getElementById(id));
  for (let [key, value] of formData.entries()) {
    productObject[key] = value;
  }
  return productObject;
};
