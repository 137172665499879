import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Label, TextField } from "./TextField";
import Select from "react-select";
//Success
import { Button, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useStateValue } from "../../../../context/StateProvider";
import { ColumnContainer } from "../../DashboardComponents/ButtonContainer";
import { Column } from "../Web3ResumeContainer";

const customStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: "none",
    padding: "0.1rem 0.3rem",
    background: "#c3d0e1",
  }),
};

const options = [
  { value: "number", label: "Number" },
  { value: "text", label: "Text" },
  { value: "image", label: "Image / Image Slider" },
];

const privacyOptions = [
  { value: true, label: "Public" },
  { value: false, label: "Private" },
];

export const CustomTextFieldForm = ({ stageType }) => {
  const [privacyCustom, setPrivacyCustom] = useState(privacyOptions[0]);
  const matches = useMediaQuery("(max-width:1368px)");
  const { enqueueSnackbar } = useSnackbar();
  //eslint-disable-next-line
  const [{ fields }, dispatch] = useStateValue();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [select, setSelect] = useState(options[0]);

  useEffect(() => {
    if (name === "") {
      setNameError(true);
    } else setNameError(false);
  }, [name]);

  const handleFieldSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: `${stageType ? "ADD_STAGE_FIELD" : "ADD_FIELD"}`,
        item: {
          name: name,
          select: select,
          isPublic: privacyCustom.value,
        },
      });
      enqueueSnackbar(`Field Added ${name}`, {
        variant: "success",
      });
      setName("");
      setSelect(options[0]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ColumnContainer>
      <Label style={{ marginBottom: "0.5rem", marginTop: "1rem" }}>
        Add Custom Field
      </Label>
      <Container id="custom-form">
        <GridContainer>
          <TextField
            label="Field Name"
            placeholder="Enter Field Name"
            id="field-name"
            name="field-name"
            required
            value={name}
            onChange={setName}
            type="text"
            // icon={<CheckCircleIcon fontSize="small" style={{ color: "#2c2858" }} />}
            color="#21ca54"
            maxLength="50"
          />
          <ColumnContainer>
            <Label style={{ marginBottom: "0.3rem" }}>Select Data Type</Label>
            <Select
              options={options}
              styles={customStyles}
              value={select}
              onChange={setSelect}
              required
              defaultValue={options[0]}
            />
          </ColumnContainer>
          <Column span={!matches ? true : false}>
            <Label>Private / Public</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#c3d0e1",
                  boxShadow: "none",
                },
              })}
              name="isPublic"
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={privacyCustom}
              options={privacyOptions}
              value={privacyCustom}
              onChange={setPrivacyCustom}
            />
          </Column>
        </GridContainer>
        <ButtonWrapper>
          <Button
            style={{
              background: "#1fc36157",
              color: "#080036",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: ".3rem 1rem",
              float: "right",
            }}
            onClick={handleFieldSubmit}
            disabled={nameError}
            type="submit"
            size="small"
          >
            Add field
          </Button>
        </ButtonWrapper>
      </Container>
    </ColumnContainer>
  );
};

const Container = styled.form`
  background-color: #c3d0e181;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.2rem;
`;

const GridContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonWrapper = styled.div`
  width: auto;
`;
