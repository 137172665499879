import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "styled-components";
import PropTypes from "prop-types";

const CustomView = ({ setCustomModalOpen, style }) => {
  return (
    <DivContainer style={style}>
      <Tooltip title="Click to add custom data">
        <IconButton onClick={() => setCustomModalOpen(true)}>
          <AddCircleIcon
            sx={{ fontSize: 35 }}
            style={{
              color: "#8080808f",
            }}
          />
        </IconButton>
      </Tooltip>
    </DivContainer>
  );
};

CustomView.propTypes = {
  style: PropTypes.object,
  setCustomModalOpen: PropTypes.func,
};

export default CustomView;

const DivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c3d0e181;
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  min-height: 5rem;
  padding: 1.5rem;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;
