import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Heading, Text } from "../../components/shared/Typography/Typo";
import { SecretComponentForm } from "./SecretComponentForm";

export const SecretComponent = () => {
  return (
    <Container>
      <HeadingWrapper>
        <Heading style={{ color: "#ffffff" }}>Secret Sign In</Heading>
        {/* <Text lighter small>
          Login with your Secret Key
        </Text> */}
      </HeadingWrapper>
      <SecretComponentForm />
      <Text lighter small>
        Already have an account?{" "}
        <Link to="/login" style={{  color: "#34e78d" }}>Login</Link>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  background: linear-gradient(180deg, #293149 0%, #1b2236 100%);
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 2rem;
  display: flex;
  gap: 3rem;
  align-items: center;
  margin: 1.5rem;
  @media (max-width: 1368px) {
    margin: 2rem;
  }
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;
