export function imageFields(data) {
  if (data) {
    let imageObject = {};
    let refinedImageArr = [];
    if (data.toString()?.includes("ipfs")) {
      let imageArr = data.toString()?.split(",");
      for (let i = 0; i < imageArr?.length; i++) {
        let preview = imageArr[i],
          imageObjectRefined = { ...imageObject, preview };
        refinedImageArr?.push(imageObjectRefined);
      }
      return refinedImageArr;
    }
  }
}
