import styled from "styled-components";
import React from "react";
import moment from "moment";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";

const ProviderVerifications = ({ onClick, verificationData }) => {
  return (
    <Container>
      <ImageWrapper>
        <Image alt="student" src={verificationData?.senderImage} />
      </ImageWrapper>
      <ColumnContainer>
        <Text bold primary underline>
          {typeof verificationData?.dataType?.id === "string"
            ? verificationData?.dataType?.id
            : verificationData?.dataType?.name}
        </Text>
        {(() => {
          if (
            verificationData?.data?.courseName ||
            verificationData?.data?.position
          ) {
            return (
              <ColumnContainer>
                <Text primary lighter>
                  {verificationData?.data?.courseName
                    ? verificationData?.data?.courseName
                    : verificationData?.data?.position}{" "}
                  -{" "}
                  {verificationData?.data.courseName
                    ? verificationData?.data?.institutionName
                    : verificationData?.data?.company}
                </Text>
                <Text primary lighter>
                  {`${moment(Number(verificationData?.data?.startDate)).format(
                    "MMM YYYY"
                  )} - ${
                    verificationData?.data?.endDate === "undefined"
                      ? "Present"
                      : verificationData?.data?.endDate > Date.now()
                      ? "Present"
                      : moment(Number(verificationData?.data?.endDate)).format(
                          "MMM YYYY"
                        )
                  }`}
                </Text>
              </ColumnContainer>
            );
          } else if (verificationData?.data?.itemImage) {
            return (
              <ColumnContainer>
                <Text primary lighter>
                  {verificationData?.data?.itemName} -
                  {verificationData?.data?.issuer}
                </Text>
                <Text primary small lighter>
                  {moment(verificationData?.data?.issueDate).format(
                    "MM/DD/YYYY"
                  )}{" "}
                </Text>
              </ColumnContainer>
            );
          } else {
            return (
              <Text primary lighter>
                {verificationData?.data}
              </Text>
            );
          }
        })()}
        <Text primary small>
          {moment(verificationData.createdAt).fromNow()}
        </Text>
        <FlexContainer>
          <CustomButton
            login
            text="Accept"
            acceptClass
            onclick={() => {
              onClick({
                status: "approve",
                data: verificationData,
              });
            }}
          />
          <CustomButton
            login
            text="Reject"
            rejectClass
            onclick={() => {
              onClick({
                status: "reject",
                data: verificationData,
              });
            }}
          />
        </FlexContainer>
      </ColumnContainer>
    </Container>
  );
};

export default ProviderVerifications;

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #c3d0e176;
  padding: 1rem 2rem;
  gap: 3rem;
  transition: all 200ms ease-in;
  &:hover {
    background-color: #c3d0e1;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0rem;
  align-items: center;
`;

const Image = styled.img`
  width: 10rem;
  height: auto;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  justify-self: end;
  border-radius: 5px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  min-width: 10rem;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
`;

const Text = styled.p`
  text-decoration: ${(props) => (props.underline ? "underline" : "unset")};
  font-family: "Poppins", sans-serif;
  font-size: ${(props) => (props.small ? "0.875rem" : "16px")};
  color: ${(props) => (props.primary ? "#1E1B3B" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "500")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;
