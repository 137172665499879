import React, { useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { EditorState, ContentState, convertFromHTML } from "draft-js";

import { getUserSession } from "../../../services/UserManagement";
import PrivacyToggle from "../fields/Button/PrivacyToggle";
import Select from "react-select";
import {
  Label,
  TextField,
  Container as DropContainer,
  InputContainer,
} from "../Form/FormComponents/TextField";
import {
  ButtonWrapper,
  Column,
  ColumnContainer,
  countryOptions,
  phoneNoValidation,
  techOptions,
} from "../Form/Web3ResumeContainer";
import { FlexSpaceBetweenContainer } from "../Profile/UserProfile";
import TextEditor from "../TextEditor/TextEditor";
import { SubHeading } from "../Typography/Typo";
import DropzoneComponent from "../fields/Dropzone/DropzoneComponent";
import { useMediaQuery } from "@mui/material";
import { CustomButton } from "../fields/Button/CustomButton";
import { AddImageToIPFS, AddToIPFS } from "../../../services/IpfsService";
import { useSelector } from "react-redux";
import { useStateValue } from "../../../context/StateProvider";
import { createCVObject } from "../../../utils/cvObject";
import * as API from "../../../api/API";
import { jwtDecode } from "../../../utils/jwt";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { getArrayData } from "../../../utils/editForm";
import store from "../../../redux/reducers";
import { ADD_USER_DETAILS } from "../../../redux/constants/ActionTypes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { symmetricEncryption } from "../../../utils/encryption";

const CredentialProviderEdit = ({ profileData, setEditOpen, secretKey }) => {
  let previewImageUrl;
  let basicData = {};
  let defaultImage = [{ preview: profileData?.profileImage?.data?.data }];
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const [{ privacyData }, dispatch] = useStateValue();
  const currentUser = useSelector((state) => state.currentUser);
  //eslint-disable-next-line
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [formDataState, setFormDataState] = useState([]);
  const [description, setDescription] = useState(
    profileData?.description?.data?.data
  );
  const [editorState, onEditorStateChange] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(profileData?.description?.data?.data)
      )
    )
  );

  // const [editorState, onEditorStateChange] = useState();

  const [files, setFiles] = useState(defaultImage);
  const [errors, setErrors] = useState("");
  const [country, setCountry] = useState(countryOptions[0]);
  const [phoneNumber, setPhoneNumber] = useState(
    profileData?.phoneNumber?.data?.data
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const matches = useMediaQuery("(max-width:1368px)");

  function handleBasicEdit(e) {
    e.preventDefault();
    setFormDataState(e.currentTarget);
    setShowAction(true);
  }

  //FormData
  const getFormData = async (target) => {
    const formData = new FormData(target);

    //add image to ipfs
    if (files.length > 0 && files && files[0]) {
      if (
        files[0].preview
          .toString()
          .includes("ipfs")
      ) {
        previewImageUrl = files[0].preview;
      } else {
        const ipfsData = await AddImageToIPFS(files[0]);
        previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
      }
    }

    if (description !== profileData.description.data.data) {
      profileData.description.data.data = description;
      profileData.description.verifiedBy = [];
    }

    // Set new value and null the verifiedBy array
    if (previewImageUrl !== profileData.profileImage.data.data) {
      profileData.profileImage.data.data = previewImageUrl;
      profileData.profileImage.verifiedBy = [];
    }

    // Set the privacy (isPublic) and encrypting the data
    privacyData.forEach((item) => {
      if (item.profileImage === true) {
        profileData.profileImage.isPublic = false;
        profileData.profileImage.data.encryptionType = "SYMMETRIC";
        profileData.profileImage.data.data = symmetricEncryption(
          previewImageUrl,
          secretKey
        );
      }
      if (item.description === true) {
        profileData.description.isPublic = false;
        profileData.description.data.encryptionType = "SYMMETRIC";
        profileData.description.data.data = symmetricEncryption(
          description,
          secretKey
        );
      }
    });

    // Check changes in basic data (without profileImage)
    for (let [key, value] of formData.entries()) {
      basicData[key] = value;
      // Set new value and null the verifiedBy array
      if (profileData[key].data.data !== value) {
        profileData[key].data.data = value;
        profileData[key].verifiedBy = [];
      }
      // Set the privacy (isPublic) and encrypting the data
      privacyData.forEach((item) => {
        if (item[key] === true) {
          profileData[key].isPublic = false;
          profileData[key].data.encryptionType = "SYMMETRIC";
          profileData[key].data.data = symmetricEncryption(value, secretKey);
        }
      });
    }

    dispatch({
      type: "CLEAR_PRIVACY",
    });
  };

  const dispatchProduct = async (secretKey) => {
    setLoading(true);
    try {
      await getFormData(formDataState);

      // Get logged in user
      const user = jwtDecode();

      // add product to ipfs
      const productIpfsHash = await AddToIPFS(JSON.stringify(profileData));

      const response = await API.updateCredentialProvider({
        path: { id: user.publicKey },
        token: localStorage.niftoken,
        body: {
          latestIpfsUrl: {
            ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
            timestamp: Date.now(),
          },
          image: previewImageUrl,
          description: description,
          phoneNumber: basicData.phoneNumber,
          country: basicData.country,
          credentialProviderName: basicData.name,
          city: basicData.city,
        },
      });

      if (response?.data?.code === 202) {
        const userData = await API.me({
          token: localStorage.niftoken,
        });
        if (userData?.data?.code === 200) {
          store.dispatch({
            type: ADD_USER_DETAILS,
            payload: userData?.data?.data[0],
          });
          enqueueSnackbar("Basic Deatils Edited", {
            variant: "success",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFiles([]);
      setLoading(false);
      setEditOpen(false);
      setShowAction(false);
      history.push("/dashboard/profile");
    }
  };

  if (showAction) {
    return (
      <ActionConfirm
        successMessage={{
          message: "Making changes in IPFS",
          width: "12rem",
        }}
        warningMessage={"Are you sure you want to edit?"}
        loading={loading}
        setShowAction={setShowAction}
        authTypeInput={authUser.authType}
        publicKey={authUser.publicKey}
        encryptedSecret={authUser.encryptedSecret}
        onSecretKey={dispatchProduct}
      />
    );
  } else {
    return (
      <>
        <OverLayContainer onClick={() => setEditOpen(false)} />
        <Container>
          <OverFlowContainer>
            <GridContainer id="edit-header-form" onSubmit={handleBasicEdit}>
              <FlexSpaceBetweenContainer
                style={{
                  width: "95%",
                  position: "absolute",
                  left: "0",
                  top: "0",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  zIndex: "9999",
                }}
              >
                <SubHeading style={{ padding: "2rem 3rem" }} primary>
                  Edit Basic Details
                </SubHeading>
              </FlexSpaceBetweenContainer>

              <TextField
                disabled={profileData?.name?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                label="Credential Provider Name *"
                required
                placeholder="Credential Provider Name"
                id="name"
                name="name"
                type="text"
                maxLength="20"
                defaultValue={profileData?.name?.data?.data}
                icon={<PrivacyToggle fieldName="name" _id="1523" />}
              />

              <TextEditor
                placeholder="About *"
                fieldName="About the Credential Provider"
                editorState={editorState}
                setDescription={setDescription}
                setEditorState={onEditorStateChange}
                icon={<PrivacyToggle fieldName="description" _id="619" />}
              />

              <DropContainer style={{ gridRow: "span 2" }}>
                <PrivacyToggle
                  fieldName="profileImage"
                  _id="218784"
                  select
                  top="25%"
                  right="1.9%"
                />
                <InputContainer>
                  <Label>Institution Logo *</Label>
                </InputContainer>
                <DropzoneComponent
                  disabled={profileData?.profileImage?.verifiedBy.find(
                    (item) => item?.verificationStatus === 0
                  )}
                  files={files}
                  id="dropzoneTwo"
                  amount="1"
                  form="none"
                  multiple={false}
                  errors={errors}
                  setErrors={setErrors}
                  maxSize="1000000"
                  setFiles={setFiles}
                  type="image/*, image/jpeg, image/png"
                  description="Drop or select your profile picture (Max file size 1MB)"
                />
              </DropContainer>
              <TextField
                disabled={profileData?.addressLineOne?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                label="Address Line-1 *"
                placeholder="Address Line One"
                id="addressLineOne"
                required
                defaultValue={profileData?.addressLineOne?.data?.data}
                name="addressLineOne"
                type="text"
                maxLength="100"
                icon={<PrivacyToggle fieldName="addressLineOne" _id="53658" />}
              />
              <TextField
                disabled={profileData?.addressLineTwo?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                label="Address Line-2"
                placeholder="Address Line Two"
                defaultValue={profileData?.addressLineTwo?.data?.data}
                id="addressLineTwo"
                name="addressLineTwo"
                type="text"
                maxLength="100"
                icon={<PrivacyToggle fieldName="addressLineTwo" _id="21763" />}
              />
              <TextField
                disabled={profileData?.city?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                label="City *"
                placeholder="City"
                id="city"
                required
                name="city"
                defaultValue={profileData?.city?.data?.data}
                type="text"
                maxLength="50"
                icon={<PrivacyToggle fieldName="city" _id="92282" />}
              />
              <Column>
                <Label>Country *</Label>
                <PrivacyToggle
                  fieldName="country"
                  _id="921727"
                  select
                  top={matches ? "50%" : "40%"}
                  right="5%"
                />
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      outline: "none",
                      text: "orangered",
                      primary25: "#2f3a6039",
                      primary: " #2f3a60a7",
                      neutral0: "#c3d0e1",
                      boxShadow: "none",
                      zIndex: "5",
                    },
                  })}
                  name="country"
                  defaultValue={profileData?.country?.data?.data}
                  options={countryOptions}
                  value={country}
                  onChange={setCountry}
                  disabled={profileData?.country?.verifiedBy.find(
                    (item) => item?.verificationStatus === 0
                  )}
                />
              </Column>
              <TextField
                disabled={profileData?.phoneNumber?.verifiedBy.find(
                  (item) => item?.verificationStatus === 0
                )}
                label="Phone Number"
                placeholder="0/ 94/ +94/ 0094"
                id="phoneNumber"
                name="phoneNumber"
                span
                type="text"
                maxLength="13"
                icon={<PrivacyToggle fieldName="phoneNumber" _id="27310" />}
                value={phoneNumber}
                onChange={(e) => {
                  if (e !== "" && !phoneNoValidation.test(e)) {
                    setPhoneNumberError(true);
                  } else {
                    setPhoneNumberError(false);
                  }
                  setPhoneNumber(e);
                }}
                color="#FF613F"
                helper={phoneNumberError && "Invalid Mobile Number"}
              />
            </GridContainer>
            <ColumnContainer
              style={{
                width: "100%",
                position: "absolute",
                right: "0",
                bottom: "0",
                backgroundColor: "#fff",
                borderRadius: "10px",
                zIndex: "9999",
              }}
            >
              <ButtonWrapper style={{ gap: "1rem", padding: "2rem 3rem" }}>
                <CustomButton
                  login
                  text="Cancel"
                  rejectClass
                  type="button"
                  onclick={() => setEditOpen(false)}
                />
                <CustomButton
                  login
                  text="Update Basic Data"
                  type="submit"
                  form="edit-header-form"
                />
              </ButtonWrapper>
            </ColumnContainer>
          </OverFlowContainer>
        </Container>
      </>
    );
  }
};

export default CredentialProviderEdit;

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  max-width: 60rem;
  z-index: 999;
  height: 35rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 1468px) {
    width: 80%;
    padding: 2rem 0rem;
  }
  @media (max-width: 768px) {
    width: 80%;
    padding: 2rem 0.5rem;
  }
`;

const OverFlowContainer = styled.div`
  overflow: auto;
  display: flex;
  padding: 0rem 1.5rem 2rem 1.5rem;
  margin: 1rem;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0rem 0.5rem 1rem 0.5rem;
    margin: 0rem;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b558f; /* Add the blur effect */
`;

export const GridContainer = styled.form`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  margin: 4.5rem 0rem;
  @media (max-width: 1368px) {
    grid-template-columns: 1fr;
  }
`;
