import React from "react";
import DatePicker from "react-date-picker";
import "./DatePicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import styled from "styled-components";

const DateComponent = (props) => {
  return (
    <DatePickerWrapper>
      <DatePicker
        monthPlaceholder="MM"
        yearPlaceholder="YY"
        dayPlaceholder="DD"
        onChange={props.onChange}
        value={props.value}
        clearIcon={false}
        calendarIcon={<CalendarTodayIcon style={{ color: "#080036" }} />}
        className="custom-date"
      />
    </DatePickerWrapper>
  );
};

export default DateComponent;

const DatePickerWrapper = styled.div`
  z-index: 1;
`;