import { ADD_IPFS_COPY_DATA, ADD_USER_DETAILS } from "../constants/ActionTypes";
import { ADD_IPFS_DATA } from "../constants/ActionTypes";

const initialState = {
  currentUser: [],
  latestIpfsData: [],
  ipfsDataCopy: [],
};

export default function variables(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_DETAILS:
      state.currentUser = action.payload;
      return state;
    case ADD_IPFS_DATA:
      state.latestIpfsData = action.payload;
      return state;
    case ADD_IPFS_COPY_DATA:
      state.ipfsDataCopy = action.payload;
      return state;
    default:
      return state;
  }
}
