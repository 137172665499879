import styled from "styled-components";

export const FlexContainer = styled.div`
  padding: 0.3rem 2rem;
  position: sticky;
  min-height: 50px;
  top: 0;
  left: 0;
  background: ${(props) => (props.primary ? "#1E1B3B" : "#fff")};
  color: ${(props) => (props.primary ? "#fff" : "#1E1B3B")};
  margin-bottom: ${(props) => (props.primary ? "1rem" : "none")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  z-index: 50;
  @media (max-width: 1368px) {
    padding: 0.3rem 1rem;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  gap: 1rem;
`;

export const NavTagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const OverLayContainer = styled.div`
  @media (max-width: 1368px) {
    z-index: 99;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background: #0c0b554e;
    color: white;
  }
`;
