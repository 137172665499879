import React from "react";
import styled from "styled-components";
import Pagination from "@mui/material/Pagination";

const ViewPagination = ({ itemsPerPage, totalItems, paginate }) => {
  return (
    <Container>
      <Pagination
        variant="outlined"
        count={Math.ceil(totalItems / itemsPerPage)}
        onChange={paginate}
        defaultPage={1}
        siblingCount={0}
      />
    </Container>
  );
};

export default ViewPagination;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
