import React, { Fragment } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import { useParams, useLocation } from "react-router-dom";
import ScannedSingleItem from "./ScannedSingleItem";
import ScannedList from "./ScannedList";

const Scans = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <Container>
      {id ? (
        <Fragment>
          <ScannedSingleItem />
        </Fragment>
      ) : (
        <>
          <ButtonContainer
            heading="View Scanned Items"
            description="View your scanned items here"
            icon={"link"}
          />
          <ScannedList />
        </>
      )}
    </Container>
  );
};

export default Scans;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
