import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import Select from "react-select";
//MUI
import {
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Text } from "../../components/shared/Typography/Typo";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { checkAlias } from "../../services/UserManagement";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import { NIFTRON, UserType } from "niftron-client-sdk";
import * as API from "../../api/API";

//Password strength checker requirements
var setup = require("hsimp-purescript");
var characterSets = require("hsimp-purescript/dictionaries/character-sets.json");
var periods = require("hsimp-purescript/dictionaries/periods.json");
var namednumbers = require("hsimp-purescript/dictionaries/named-numbers.json");
var top10k = require("hsimp-purescript/dictionaries/top10k.json");
var patterns = require("hsimp-purescript/dictionaries/patterns.json");
var checks = require("hsimp-purescript/dictionaries/checks.json");

// user type select options
// select options
const options = [
  { value: "PROFILE", label: "Profile" },
  { value: "CREDENTIALPROVIDER", label: "Credential Provider" },
];

//Progress button styles
const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

const formStatusProps = {
  success: {
    message: "Signed up successfully.",
    type: "success",
  },
  error: {
    message: "Something went wrong. Please try again.",
    type: "error",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(255, 255, 255)",
    },
    "& .Mui-error": {
      color: "rgb(244, 67, 54)",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(244, 67, 54)",
    },
  },
}));

export const SignUpForm = ({ setSignUpStep, setUserData, userData }) => {
  const classes = useStyles();
  //Form Status
  //eslint-disable-next-line
  const [displayFormStatus, setDisplayFormStatus] = useState(false);
  const [type, setType] = useState(options[0]);
  //eslint-disable-next-line
  const [formStatus, setFormStatus] = useState({
    message: "",
    type: "",
  });

  // setAlias
  const [alias, setAlias] = useState("");
  // Loading state
  const [aliasLoading, setAliasLoading] = useState(false);
  //Check Values
  const [checkAliasError, setCheckAliasError] = useState(false);
  const [checkAliasSuccess, setCheckAliasSuccess] = useState(false);
  // email
  const [email, setEmail] = useState("");

  //Password Strength
  const [passwordStrength, setPasswordStrength] = useState("a second");
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPasswordValues, setConfirmPasswordValues] = useState({
    confirmPassword: "",
    confirmShowPassword: false,
  });

  //Functions
  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleConfirmClickShowPassword = () => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      confirmShowPassword: !confirmPasswordValues.confirmShowPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // reroute after signup
  function handleSignUp() {
    setSignUpStep(1);
  }

  //Alias match check
  const AliasMatch = async (value) => {
    // eslint-disable-next-line
    if (value === alias) {
      setAliasLoading(true);
      const check = await checkAlias(value);
      if (check != null && !check.isAvailable) {
        setCheckAliasError(true);
        setCheckAliasSuccess(false);
      } else {
        setCheckAliasSuccess(true);
        setCheckAliasError(false);
      }
      setAliasLoading(false);
    }
  };

  //Api debouncer
  const aliasAPIDebounced = AwesomeDebouncePromise(AliasMatch, 500);

  // Custom form validation
  const customValidation = (values) => {
    let errors = {};

    var aliasValidation = /^(?=[a-zA-Z0-9._]{8,30}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
    //alias validation
    if (!alias) {
      errors.alias = "Required";
    } else if (checkAliasError) {
      errors.alias = "Alias already exists";
    } else if (!aliasValidation.test(alias)) {
      errors.alias = "Please input a valid alias, should be above 8 characters";
    }

    // email validation
    var emailValidation =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      errors.email = "Required";
    } else if (!emailValidation.test(email)) {
      errors.email = "Please input a valid email address";
    }

    //password validation
    var passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,20}\S$/;
    if (!passwordValues.password) {
      errors.password = "Required";
    } else if (passwordValues.password.length < 8) {
      errors.password = "Password must be 8 characters long.";
    } else if (!passwordRegex.test(passwordValues.password)) {
      errors.password =
        "Please input a valid password. One uppercase, one lowercase, one special character and no spaces";
    }
    //confirm password validation
    if (!confirmPasswordValues.confirmPassword) {
      errors.confirm_password = "Required";
    } else if (
      passwordValues.password !== confirmPasswordValues.confirmPassword
    ) {
      errors.confirm_password = "Passwords doesn't match";
    }
    return errors;
  };

  // Form status
  const createNewUser = async (data) => {
    try {
      // API call integration will be here. Handle success / error response accordingly.
      const options = {
        type: UserType.USER,
        alias: `${data.alias}`,
        password: data.password,
      };

      //eslint-disable-next-line
      const { status, publicKey, secretKey } =
        await NIFTRON.user.createMediumPrivacyUser(options);
      if (publicKey && secretKey) {
        setUserData({
          ...userData,
          alias: alias,
          publicKey: publicKey,
          secretKey: secretKey,
        });
      }
      try {
        const response = await API.register({
          body: {
            alias: data.alias,
            publicKey: publicKey,
            secretKey: secretKey,
            status: 1,
            type: type.value,
            email: data.email,
          },
        });
        console.log("register response:", response);
      } catch (error) {
        console.log(error);
        throw new Error();
      }
      if (data) {
        setFormStatus(formStatusProps.success);
      }
    } catch (error) {
      const response = error.response;
      if (response.data === "user already exist" && response.status === 400) {
        setFormStatus(formStatusProps.duplicate);
      } else {
        setFormStatus(formStatusProps.error);
      }
    } finally {
      setDisplayFormStatus(true);
    }
  };

  //Password strength Checker
  const checkStrength = () => {
    const hsimp = setup({
      calculation: {
        calcs: 40e9,
        characterSets: characterSets,
      },
      time: {
        periods: periods,
        namedNumbers: namednumbers,
        forever: "Forever",
        instantly: "an Instant",
      },
      checks: {
        dictionary: top10k,
        patterns: patterns,
        messages: checks,
      },
    });
    setPasswordStrength(hsimp(passwordValues.password).time);
  };

  return (
    <Formik
      initialValues={{
        alias: "",
        email: "",
        password: "",
        confirm_password: "",
        type: "",
      }}
      validate={customValidation}
      // ON submit do a API call
      onSubmit={async (values, actions) => {
        await createNewUser(values);
        handleSignUp();
        // setTimeout(() => {
        //   actions.setSubmitting(false);
        //   handleSignUp();
        //   alert(JSON.stringify(values, null, 2));
        // }, 3000);
      }}
    >
      {/* Formik Props */}
      {({
        values,
        touched,
        errors,
        isValid,
        dirty,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleSubmit,
      }) => (
        <ContainerForm>
          <FlexContainer>
            {/* Alias */}
            <TextField
              fullWidth
              size="small"
              variant="standard"
              name="alias"
              id="alias"
              className={classes.root}
              label="Alias *"
              disabled={isSubmitting}
              helperText={errors.alias && touched.alias ? errors.alias : null}
              error={errors.alias && touched.alias ? true : false}
              onBlur={handleBlur}
              InputLabelProps={{
                style: {
                  color: "#fff",
                  fontFamily: "Poppins",
                },
              }}
              InputProps={{
                style: { color: "#fff", padding: ".2rem" },
                type: "text",
                value: values.alias,
                endAdornment: (
                  <InputAdornment position="end">
                    {aliasLoading && <CircularProgress size={20} />}
                    {!aliasLoading && checkAliasSuccess && (
                      <CheckCircleOutlineIcon
                        size={20}
                        style={{ fill: "rgb(76, 175, 80)" }}
                      />
                    )}
                    {!aliasLoading && checkAliasError && (
                      <HighlightOffIcon
                        size={20}
                        style={{ fill: "rgb(244, 67, 54)" }}
                      />
                    )}
                  </InputAdornment>
                ),
                onChange: async function (e) {
                  setAlias(e.target.value);
                  setFieldValue("alias", e.target.value);
                  await aliasAPIDebounced(e.target.value);
                },
                onKeyUp: async function (e) {
                  await aliasAPIDebounced(e.target.value);
                },
              }}
            />
            {/* Email */}
            <TextField
              fullWidth
              size="small"
              variant="standard"
              name="email"
              id="email"
              className={classes.root}
              label="Email *"
              disabled={isSubmitting}
              helperText={errors.email && touched.email ? errors.email : null}
              error={errors.email && touched.email ? true : false}
              onBlur={handleBlur}
              InputLabelProps={{
                style: {
                  color: "#fff",
                  fontFamily: "Poppins",
                },
              }}
              InputProps={{
                style: { color: "#fff", padding: ".2rem" },
                onChange: function (e) {
                  setEmail(e.target.value);
                  setFieldValue("email", e.target.value);
                },
                type: "text",
                value: values.email,
              }}
            />
            {/* Password */}
            <TextField
              fullWidth
              size="small"
              variant="standard"
              disabled={isSubmitting}
              className={classes.root}
              name="password"
              label="Password *"
              id="password"
              value={passwordValues.password}
              helperText={
                errors.password && touched.password
                  ? errors.password
                  : passwordValues.password !== "" &&
                    `It takes ${passwordStrength} to crack your password`
              }
              error={errors.password && touched.password ? true : false}
              onBlur={handleBlur}
              InputLabelProps={{
                style: {
                  color: "#fff",
                  fontFamily: "Poppins",
                },
              }}
              InputProps={{
                style: { color: "#fff", padding: ".2rem" },
                onChange: function (e) {
                  checkStrength();
                  setPasswordValues({
                    ...passwordValues,
                    password: e.target.value,
                  });
                  setFieldValue("password", e.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      disabled={isSubmitting}
                    >
                      {passwordValues.showPassword ? (
                        <VisibilityIcon style={{ fill: "#ffffff" }} />
                      ) : (
                        <VisibilityOffIcon style={{ fill: "#ffffff" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "new-password",
                type: passwordValues.showPassword ? "text" : "password",
              }}
            />
            {/* Confirm Password */}
            <TextField
              fullWidth
              size="small"
              variant="standard"
              className={classes.root}
              name="confirm_password"
              id="confirm_password"
              value={confirmPasswordValues.confirmPassword}
              label="Confirm Password *"
              disabled={isSubmitting}
              helperText={
                errors.confirm_password && touched.confirm_password
                  ? errors.confirm_password
                  : null
              }
              error={
                errors.confirm_password && touched.confirm_password
                  ? true
                  : false
              }
              onBlur={handleBlur}
              InputLabelProps={{
                style: {
                  color: "#fff",
                  fontFamily: "Poppins",
                },
              }}
              InputProps={{
                style: { color: "#fff", padding: ".2rem" },
                onChange: function (e) {
                  setConfirmPasswordValues({
                    ...confirmPasswordValues,
                    confirmPassword: e.target.value,
                  });
                  setFieldValue("confirm_password", e.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleConfirmClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      disabled={isSubmitting}
                    >
                      {confirmPasswordValues.confirmShowPassword ? (
                        <VisibilityIcon style={{ fill: "#ffffff" }} />
                      ) : (
                        <VisibilityOffIcon style={{ fill: "#ffffff" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                type: confirmPasswordValues.confirmShowPassword
                  ? "text"
                  : "password",
                autoComplete: "new-password",
              }}
            />
          </FlexContainer>
          <FlexContainer>
            <Label style={{ fontWeight: "400" }}>Select user type *</Label>
            {/* Select user type */}
            <Select
              required
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  text: "#3599B8",
                  font: "#3599B8",
                  primary: "#4da6ff",
                  primary25: "#2f3a6039",
                  neutral80: "white",
                  neutral0: "#293149",
                  color: "black",
                },
              })}
              defaultValue={options[0]}
              value={type}
              onChange={setType}
              options={options}
              disabled={isSubmitting}
            ></Select>
          </FlexContainer>
          {isSubmitting ? (
            <LoadingContainer>
              <BorderLinearProgress />
              <Text small lighter style={{ width: "100px" }}>
                Registering <span className="loading"></span>
              </Text>
            </LoadingContainer>
          ) : (
            <CustomButton
              style={{ marginTop: "2rem" }}
              disabled={!isValid || (isSubmitting && dirty)}
              onclick={handleSubmit}
              text="Register"
              login
              loginIcon
              loginClass
            />
          )}
        </ContainerForm>
      )}
    </Formik>
  );
};

export default SignUpForm;

const ContainerForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    margin-bottom: 5rem;
  }
`;

export const LoadingContainer = styled.div`
  height: 68px;
  width: 100%;
  margin-top: 0.5rem;
  gap: 0.3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Label = styled.label`
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
