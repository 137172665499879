import React from "react";
import styled from "styled-components";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "../Typography/Typo";
import moment from "moment";
import { useSnackbar } from "notistack";
import { FlexSpaceBetweenContainer } from "../ProductProfile/ProductProfile";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const VerificationEntityCard = ({
  status,
  receiverPublicKey,
  receiverImage,
  receiverAlias,
  createdAt,
  type,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      <SmallImageWrapper>
        <ProfileImage src={receiverImage} alt={receiverAlias} />
      </SmallImageWrapper>
      <Content>
        <Text primary>Entity Name</Text>
        <Text primary>{receiverAlias}</Text>
        <Text primary>Entity Public Key</Text>

        <FlexSpaceBetweenContainer>
          <Span primary>
            {receiverPublicKey?.toString()?.substring(0, 20 - 3) + "..."}
          </Span>
          <IconButton
            onClick={() => {
              copyToClipBoard(receiverPublicKey);
              enqueueSnackbar("URL Copied", {
                variant: "info",
              });
            }}
          >
            <ContentCopyIcon
              style={{
                color: "#6d6d6d",
              }}
            />
          </IconButton>
        </FlexSpaceBetweenContainer>
        <Text primary>Verification Status</Text>
        <FlexContainer>
          {status === 0 ? (
            <FlexIconContainer>
              <CircleIcon
                style={{
                  color: "#df642b",
                }}
              />
              <Text primary>Pending</Text>
            </FlexIconContainer>
          ) : status === 1 ? (
            <FlexIconContainer>
              <CheckCircleIcon
                style={{
                  color: "#1ccc54",
                }}
              />
              <Text primary>Approved</Text>
            </FlexIconContainer>
          ) : (
            <FlexIconContainer>
              <CircleIcon
                style={{
                  color: "#cf2020",
                }}
              />
              <Text primary>Rejected</Text>
            </FlexIconContainer>
          )}
        </FlexContainer>
        <Text primary>
          {type === "PASSIVE" ? "Requested on" : "Verified at"}
        </Text>
        <Text lighter color="#6d6d6d">
          {moment(createdAt).format("LLL")}
        </Text>
      </Content>
    </Container>
  );
};

export default VerificationEntityCard;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0.7rem 0rem;
`;

const Content = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;
  grid-template-columns: 2fr 3fr;
`;

const SmallImageWrapper = styled.div`
  overflow: hidden;
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  background-color: #fff;
  position: relative;
  border-radius: 2px;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const FlexIconContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Span = styled.span`
  font-weight: 500;
  max-width: 400px;
  word-break: break-word;
  color: #6d6d6d;
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};

  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
    font-size: 14px;
  }
`;
