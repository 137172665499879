import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";

import ProviderProfileHeader from "../../components/shared/Form/ProviderProfile/ProviderProfileHeader";
import AdditionalDetails from "../../components/shared/Profile/AdditionalDetails";
import { RequestVerification } from "../../components/shared/Profile/RequestVerification";
import {
  FlexSpaceBetweenContainer,
  SectionInnerContainer,
} from "../../components/shared/Profile/UserProfile";
import { SectionContainer } from "../../components/shared/Profile/UserProfileHeader";
import VerifiedStatusContainer from "../../components/shared/Profile/VerifiedStatusContainer";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import { Preview } from "../../pages/JobListings/JobComponent";
import { getUserSession } from "../../api/API";
import ActionConfirm from "../../components/shared/Form/FormComponents/ActionConfirm";
import { urlToHash } from "../../utils/ipfs";
import { GetIPFSData } from "../../services/IpfsService";
import {
  symmetricDecryption,
  symmetricEncryption,
} from "../../utils/encryption";
import EncryptedData from "../../components/shared/Profile/EncryptedData";
import EditForm from "../../components/shared/EditForms/EditForm";
import ContactInfo from "../../components/shared/Profile/ContactInfo";
import CredentialProviderEdit from "./../../components/shared/EditForms/crdentialProviderEdit";
import store from "../../redux/reducers";
import { ADD_IPFS_DATA } from "../../redux/constants/ActionTypes";
import { ProviderShareConfirm } from "../../components/shared/Form/ProviderProfile/ProviderShareConfirm";

const ProviderView = ({ data }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const [showAction, setShowAction] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [editType, setEditType] = useState("");
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });
  const [isPublic, setIsPublic] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [currentField, setCurrentField] = useState({});
  const [decryptType, setDecryptType] = useState();
  const [showCustomEdit, setShowCustomEdit] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openContact, setContactOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  var html = data?.description?.data?.data;
  var div = document.createElement("p");
  div.innerHTML = html;

  // TODO: Want to do a alternative way without getting the latestIpfsData
  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
  }, []);

  // TODO: Can be generalized into a util function
  // Decrypting data
  const decryptData = async (secretKey) => {
    setSecretKey(secretKey);
    // Decrypting all the data
    if (decryptType === "all") {
      Object.entries(latestIpfsData).forEach((e) => {
        const [key, value] = e;
        if (value.isPublic === false) {
          value.data.data = symmetricDecryption(value.data.data, secretKey);
          value.data.encryptionType = "PUBLIC";
          value.isPublic = true;
        }
        if (value.isPublic === true) {
          value.data.encryptionType = "PUBLIC";
        }
        for (let i = 0; i < value.length; i++) {
          if (value[i].isPublic === false) {
            value[i].isPublic = true;
            Object.entries(value[i].data).forEach((e) => {
              const [key, value] = e;
              value.data = symmetricDecryption(value.data, secretKey);
              value.encryptionType = "PUBLIC";
            });
          }
          if (value[i].isPublic === true) {
            value[i].isPublic = true;
            Object.entries(value[i].data).forEach((e) => {
              const [key, value] = e;
              value.encryptionType = "PUBLIC";
            });
          }
        }
      });

      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
      setIsDecrypted(true);
    } else {
      // Decrypting single data
      if (typeof currentField?.id === "string") {
        latestIpfsData[currentField.id].data.data = symmetricDecryption(
          latestIpfsData[currentField.id].data.data,
          secretKey
        );
        latestIpfsData[currentField.id].isPublic = true;
      }
      // Decrypting data of arrays
      if (typeof currentField?.id === "number") {
        Object.entries(
          latestIpfsData[currentField.name][currentField.id].data
        ).forEach((e) => {
          const [key, value] = e;
          value.data = symmetricDecryption(value.data, secretKey);
        });
        latestIpfsData[currentField.name][currentField.id].isPublic = true;
      }
    }
    setShowDecryption(false);
    switch (editType) {
      case "basicEdit":
        return setEditOpen(true);
      case "customEdit":
        return setShowCustomEdit(true);
      case "shareOpen":
        return setShareOpen(true);
      case "contact":
        return setContactOpen(true);
      default:
        return null;
    }
  };

  // TODO: Can be generalized into a util function
  // Encrypting data
  const encryptData = async (data) => {
    // Encrypting all the data
    if (decryptType === "all") {
      setDecryptType();
      setIsDecrypted(false);
      fetchLatestProfileData();
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
    } else {
      // Encrypting single data
      if (typeof data?.id === "string") {
        latestIpfsData[data.id].data.data = symmetricEncryption(
          latestIpfsData[data.id].data.data,
          secretKey
        );
        latestIpfsData[data.id].isPublic = false;
      }
      // Encrypting data of arrays
      if (typeof data?.id === "number") {
        Object.entries(latestIpfsData[data.name][data.id].data).forEach((e) => {
          const [key, value] = e;
          value.data = symmetricEncryption(value.data, secretKey);
        });
        latestIpfsData[data.name][data.id].isPublic = false;
      }
    }
  };

  const handleContactClose = () => {
    setContactOpen(false);
  };

  return (
    <Fragment>
      {showAction && (
        <RequestVerification
          verification={verification}
          show={showAction}
          setShowAction={setShowAction}
        />
      )}
      {showDecryption && (
        <ActionConfirm
          warningMessage={"Confirm your credentials to decrypt"}
          setShowAction={setShowDecryption}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          //use the prop below to get the secret key
          onSecretKey={decryptData}
        />
      )}
      {editOpen && (
        <CredentialProviderEdit
          profileData={latestIpfsData}
          setEditOpen={setEditOpen}
          secretKey={secretKey}
        />
      )}
      {showCustomEdit && (
        <EditForm
          setOverLay={setShowCustomEdit}
          type={4}
          authType={authUser.authType}
        />
      )}
      {shareOpen && (
        <ProviderShareConfirm
          data={latestIpfsData}
          message={"Copy Profile Link"}
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={"Share your profile"}
          link={`http://explorer.deprofile.io/profile/${currentUser.publicKey}`}
        />
      )}

      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
        />
      </Modal>

      <Container>
        <ProviderProfileHeader
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          setShowEncryption={encryptData}
          decryptType={setDecryptType}
          isDecrypted={isDecrypted}
          setEditType={setEditType}
          setEditOpen={setEditOpen}
          setContactOpen={setContactOpen}
          setShareOpen={setShareOpen}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>{" "}
              <VerifiedStatusContainer
                status={0}
                isPublic={isPublic}
                fieldData={latestIpfsData?.description?.data?.data}
                fieldName="Description"
                fieldId={{ id: "description" }}
                setShowAction={setShowAction}
                setVerification={setVerification}
                setShowDecryption={setShowDecryption}
                setShowEncryption={encryptData}
                isDecrypted={latestIpfsData?.description?.isPublic}
                encryptedType={
                  latestIpfsData?.description?.data?.encryptionType
                }
                setCurrentField={setCurrentField}
              />
            </FlexSpaceBetweenContainer>
            {latestIpfsData?.description?.isPublic ? (
              <Preview
                className="preview"
                dangerouslySetInnerHTML={createMarkup(
                  latestIpfsData?.description?.data?.data
                )}
              />
            ) : (
              <EncryptedData
                fieldData={latestIpfsData?.description?.data?.data}
                setShowDecryption={setShowDecryption}
              />
            )}
          </SectionInnerContainer>
        </SectionContainer>
        <AdditionalDetails
          isDecrypted={isDecrypted}
          decryptType={setDecryptType}
          isPublic={isPublic}
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
          setShowCustomEdit={setShowCustomEdit}
          setEditType={setEditType}
        />
      </Container>
    </Fragment>
  );
};

export default ProviderView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;
