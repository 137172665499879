import { Divider, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import { FlexSpaceBetweenContainer } from "../ProductProfile/ProductProfile";
import { SubHeading } from "../Typography/Typo";
import ExperienceCard from "./ExperienceCard";
import { SectionInnerContainer } from "./UserProfile";
import { IconWrapper, SectionContainer } from "./UserProfileHeader";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import EditForm from "../EditForms/EditForm";

function WorkSection({
  isPublic,
  profileData,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  isDecrypted,
  decryptType,
  setShowWorkEdit,
  setEditType,
}) {
  return (
    <SectionContainer>
      <SectionInnerContainer>
        <FlexSpaceBetweenContainer>
          <SubHeading primary>Experience</SubHeading>{" "}
          <IconWrapper style={{ backgroundColor: "#c7c7c72f" }}>
            <IconButton
              onClick={() => {
                setEditType("");
                if (!isDecrypted) {
                  decryptType("all");
                  setShowDecryption(true);
                  setEditType("workEdit");
                } else {
                  setShowWorkEdit((value) => !value);
                }
              }}
            >
              {profileData?.workData?.length === 0 ? (
                <AddIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              ) : (
                <EditIcon style={{ color: "#8a8a8a" }} sx={{ fontSize: 20 }} />
              )}
            </IconButton>
          </IconWrapper>{" "}
        </FlexSpaceBetweenContainer>

        {profileData?.workData &&
          profileData?.workData?.map((item, key) => {
            return (
              <Fragment key={key}>
                <ExperienceCard
                  id={key}
                  fieldData={item}
                  fieldId={{ id: key, name: "workData" }}
                  title={item?.data?.position?.data}
                  logoImage={item?.data?.imageLogo?.data}
                  status={0}
                  isPublic={isPublic}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  startDate={Number(item?.data?.startDate?.data)}
                  institution={item?.data?.company?.data}
                  endDate={Number(item?.data?.endDate?.data)}
                  description={item?.data?.description?.data}
                  setShowDecryption={setShowDecryption}
                  setShowEncryption={setShowEncryption}
                  setCurrentField={setCurrentField}
                  encryptedType={item?.data?.position?.encryptionType}
                />
                {profileData?.workData?.length > 1 &&
                  profileData?.workData?.length - 1 !== key && (
                    <Divider
                      style={{
                        background: "#5e5e5e11",
                        width: "100%",
                      }}
                    />
                  )}
              </Fragment>
            );
          })}
      </SectionInnerContainer>
    </SectionContainer>
  );
}

export default WorkSection;
