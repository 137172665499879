import { useState } from "react";
import styled from "styled-components";
import { Text as PrimarySubText } from "../../components/shared/Typography/Typo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  secondaryButton: {
    color: "#6687f18d",
  },
}));

export default function CopyPasteComponent({ secretKey, label, reuse }) {
  // eslint-disable-next-line
  const [copySuccess, setCopySuccess] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
      setCopySuccess("Secret Key Copied");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  if (reuse)
    return (
      <ReuseContainer>
        <Text fullWidth>{secretKey}</Text>
        <IconButton
          onClick={() => {
            copyToClipBoard(secretKey);
            enqueueSnackbar(`${label} Copied`, {
              variant: "info",
            });
          }}
        >
          <ContentCopyIcon className={classes.secondaryButton} />
        </IconButton>
      </ReuseContainer>
    );
  else
    return (
      <OuterContainer>
        <PrimarySubText>{label}</PrimarySubText>
        <Container>
          <Text>{secretKey}</Text>
          <IconButton
            onClick={() => {
              copyToClipBoard(secretKey);
              enqueueSnackbar(`${label} Copied`, {
                variant: "info",
              });
            }}
          >
            <ContentCopyIcon className={classes.secondaryButton} />
          </IconButton>
        </Container>
      </OuterContainer>
    );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  background-color: #33385e;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;

const ReuseContainer = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 5px;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  gap: 0.5rem;
  min-width: 25rem;
  max-width: 28vw;
  @media (max-width: 768px) {
    min-width: 85vw;
    max-width: 85vw;
  }
  @media (max-width: 660px) {
    min-width: 75vw;
    max-width: 75vw;
  }
  @media (max-width: 375px) {
    min-width: 65vw;
    max-width: 65vw;
  }
`;

const Text = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
