import React from "react";
import styled from "styled-components";
import { SubHeading, Text } from "../../Typography/Typo";
import { Column } from "../Web3ResumeContainer";
import moment from "moment";
import School from "../../../../assets/images/graduation-hat.png";
import Work from "../../../../assets/images/suitcase.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { useStateValue } from "../../../../context/StateProvider";
import { useSnackbar } from "notistack";

const BlockFormView = ({ type, data }) => {
  let todayDate = new Date();
  //eslint-disable-next-line
  const [{ fields }, dispatch] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  // const maxlimit = 200;

  const handleRemoveEduField = (id) => {
    if (window.confirm(`Are you sure you want to delete the entry? `)) {
      enqueueSnackbar(`Entry id ${data.id} Removed!`, {
        variant: "warning",
      });
      dispatch({
        type: "REMOVE_EDU_FIELD",
        id: id,
      });
    }
  };

  const handleRemoveWorkField = (id) => {
    if (window.confirm(`Are you sure you want to delete the entry? `)) {
      enqueueSnackbar(`Entry id ${data.id} Removed!`, {
        variant: "warning",
      });
      dispatch({
        type: "REMOVE_WORK_FIELD",
        id: id,
      });
    }
  };

  const handleRemoveAchiField = (id) => {
    if (window.confirm(`Are you sure you want to delete the entry? `)) {
      enqueueSnackbar(`Entry id ${data.id} Removed!`, {
        variant: "warning",
      });
      dispatch({
        type: "REMOVE_ACHIEVEMENT_FIELD",
        id: id,
      });
    }
  };

  return (
    <>
      {(() => {
        switch (type) {
          case 1:
            return (
              <>
                {" "}
                {data && (
                  <Container>
                    <HoverContainer>
                      <IconButton
                        onClick={() => handleRemoveEduField(data?.id)}
                      >
                        <DeleteIcon style={{ color: "#e73636" }} />
                      </IconButton>
                    </HoverContainer>
                    <FlexContainer>
                      <LogoContainer>
                        <LogoImage
                          src={data?.imageLogo ? data?.imageLogo : School}
                          alt={data?.institutionName}
                        />
                      </LogoContainer>
                      <Column>
                        <SubHeading primary>{data?.courseName}</SubHeading>
                        <Text small primary>
                          {data?.institutionName}
                        </Text>
                        <Text small primary lighter>
                          {data?.description}

                          {/* {data?.description.length > maxlimit
                        ? data?.description
                          .toString()
                          .substring(0, maxlimit - 3) + "..."
                        : data?.description} */}
                        </Text>
                      </Column>
                    </FlexContainer>
                    <FlexContainer>
                      <Text primary>
                        {moment(Number(data?.startDate)).format("MM/DD/YYYY")}
                      </Text>
                      -
                      <Text primary>
                        {data?.endDate > todayDate
                          ? "Present"
                          : moment(Number(data?.endDate)).format("MM/DD/YYYY")}
                      </Text>
                    </FlexContainer>
                  </Container>
                )}
              </>
            );
          case 2:
            return (
              <>
                {" "}
                {data && (
                  <Container>
                    <HoverContainer>
                      <IconButton
                        onClick={() => handleRemoveWorkField(data?.id)}
                      >
                        <DeleteIcon style={{ color: "#e73636" }} />
                      </IconButton>
                    </HoverContainer>
                    <FlexContainer>
                      <LogoContainer>
                        <LogoImage
                          src={data?.imageLogo ? data?.imageLogo : Work}
                          alt={data?.company}
                        />
                      </LogoContainer>
                      <Column>
                        <SubHeading primary>{data?.position}</SubHeading>
                        <Text primary>{data?.company}</Text>
                        <Text small primary lighter>
                          {data?.description}

                          {/* {data.description && data.description.length > maxlimit
                        ? data?.description
                          .toString()
                          .substring(0, maxlimit - 3) + "..."
                        : data?.description} */}
                        </Text>
                      </Column>
                    </FlexContainer>
                    <FlexContainer>
                      <Text primary>
                        {moment(Number(data?.startDate)).format("MM/DD/YYYY")}
                      </Text>
                      -
                      <Text primary>
                        {data?.endDate > todayDate
                          ? "Present"
                          : moment(Number(data?.endDate)).format("MM/DD/YYYY")}
                      </Text>
                    </FlexContainer>
                  </Container>
                )}
              </>
            );
          case 3:
            return (
              <>
                {data && (
                  <Container>
                    <HoverContainer>
                      <IconButton
                        onClick={() => handleRemoveAchiField(data?.id)}
                      >
                        <DeleteIcon style={{ color: "#e73636" }} />
                      </IconButton>
                    </HoverContainer>
                    <FlexContainer>
                      <LogoContainer>
                        <LogoImage src={data?.itemImage} alt={data?.itemName} />
                      </LogoContainer>
                      <Column>
                        <SubHeading primary>{data?.itemName}</SubHeading>
                        <Text primary>{data?.issuer}</Text>

                        <Text primary lighter>
                          {data?.description}
                        </Text>
                      </Column>
                    </FlexContainer>
                    <FlexContainer>
                      <Text primary>
                        {moment(Number(data?.issueDate)).format("MM/DD/YYYY")}
                      </Text>
                    </FlexContainer>
                  </Container>
                )}
              </>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default BlockFormView;

const HoverContainer = styled.div`
  width: 100%;
  display: flex;
  z-index: 50;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: #b6b6b642;
  opacity: 0;
  transition: opacity 300ms ease-out;
  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  margin: 1rem 0rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  border-radius: 10px;
  background-color: #c3d0e181;
  @media (max-width: 562px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const LogoImage = styled.img`
  width: 5rem;
  height: auto;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 10rem;
  height: 7rem;
  margin-right: 1rem;
  border-radius: 10px;
  background-color: #2a415f29;
  align-items: center;
  justify-content: center;
  @media (max-width: 562px) {
    width: 10vw;
    height: 7vw;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  @media (max-width: 562px) {
    gap: 1rem;
  }
`;
