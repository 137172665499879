import React from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Label } from "../../pages/ViewCV/Field";
import { InputContainer } from "../../components/shared/Form/FormComponents/TextField";

import {
  ArrowBody,
  ArrowsContainer,
} from "../../components/shared/Form/StageForm/StageSliderView";
import MultipleImageSlider from "../../components/shared/Form/FormComponents/MultipleImageSlider";

const SlideView = ({ customImageArr }) => {
  var dataListfieldKeys =
    customImageArr?.length > 0 &&
    customImageArr?.map((data) => {
      const keyData = Object.keys(data);
      return keyData[0];
    });

  return (
    <>
      {dataListfieldKeys?.length > 0 &&
        dataListfieldKeys?.map((fieldName, key) => {
          return (
            <Container>
              <InputContainer>
                <Label>{fieldName}</Label>
              </InputContainer>
              <MultipleImageSlider data={customImageArr[key][fieldName]} />
              <ArrowsContainer>
                <ArrowBody className="prev">
                  <ArrowBackIosNewIcon
                    className="prev"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
                <ArrowBody className="next">
                  <ArrowForwardIosIcon
                    className="next"
                    style={{ color: "#080036", cursor: "pointer" }}
                  />
                </ArrowBody>
              </ArrowsContainer>
            </Container>
          );
        })}
    </>
  );
};

export default SlideView;

const Container = styled.div`
  display: flex;
  margin: 1rem 0rem;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0rem;
`;
