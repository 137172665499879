import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { SearchComponent } from "../JobListings/SearchComponent";
import ViewPagination from "../../components/shared/Pagination/Pagination";
import { retrieveClaimedItem } from "../../api/API";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import ClaimCard from "../Claim/ClaimCard";
import { Error, ErrorContainer } from "../Claim/ClaimView";
import { Text } from "../../components/shared/Typography/Typo";
import ErrorBG from "../../assets/images/certificate.gif";

function Credentials() {
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useSelector((state) => state.currentUser);
  const [search, setSearch] = useState("");
  const [date, onDateChange] = useState();
  const [tokensData, setTokenData] = useState([]);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 6;

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scroll(0, 0);
  };

  // Retrieve claimed items API
  const getClaimedItems = async () => {
    try {
      setLoading(true);
      const response = await retrieveClaimedItem({
        path: { pId: currentUser.publicKey },
        token: localStorage.niftoken,
      });
      if (response.data.code === 200) {
        setTokenData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentTokenList =
    tokensData &&
    tokensData.length > 0 &&
    tokensData.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    getClaimedItems();
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <SearchComponent
        searchValue={search}
        onSearchChange={setSearch}
        dateValue={date}
        placeholder="Search credentials"
        onDateChange={(e) => {
          onDateChange(e);
          setSearch(moment(e).format("L"));
        }}
      />
      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "1rem" }} />;
        } else if (tokensData <= 0) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="470px" />
              <Text style={{ marginBottom: "1rem" }} primary>
                No Credentials or Tokens found!
              </Text>
            </ErrorContainer>
          );
        } else {
          return (
            <Fragment>
              <GridContainer>
                {currentTokenList &&
                  currentTokenList
                    .filter((item) => {
                      if (search === "" && date === undefined) {
                        return item;
                      } else if (
                        item.itemName &&
                        item.itemName
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.date &&
                        moment(item.date).format("L").includes(search)
                      ) {
                        return item;
                      }
                      return item;
                    })
                    ?.map((item, key) => {
                      return <ClaimCard data={item} key={key} token />;
                    })}
              </GridContainer>
              {search === "" && (
                <ViewPagination
                  itemsPerPage={itemsPerPage}
                  totalItems={tokensData && tokensData.length}
                  paginate={paginate}
                />
              )}
            </Fragment>
          );
        }
      })()}
    </Container>
  );
}

export default Credentials;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;

const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;
