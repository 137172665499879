import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ViewPagination from "../../components/shared/Pagination/Pagination";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import moment from "moment";
import { SearchComponent } from "../../pages/JobListings/SearchComponent";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import JobPostingView from "./JobPostingView";

function RecentJobs() {
  const currentUser = useSelector((state) => state.currentUser);
  const [date, onDateChange] = useState();
  const [jobPostings, setJobPostings] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const retrievePostedJobs = async () => {
    setLoading(true);
    try {
      const response = await API.fetchJobs({
        query: {
          col: "companyPublicKey",
          key: currentUser.publicKey,
        },
      });
      if (response?.data?.code === 200) {
        setJobPostings(response.data.data.reverse());
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrievePostedJobs();
    //eslint-disable-next-line
  }, []);

  const itemsPerPage = 6;

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scroll(0, 0);
  };

  //get Current Products
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentJobIList =
    jobPostings &&
    jobPostings.length > 0 &&
    jobPostings.slice(indexOfFirstPost, indexOfLastPost);

  if (loading) {
    return <InnerLoadingView />;
  } else {
    return (
      <Container>
        <SearchComponent
          placeholder="Search for posted jobs"
          searchValue={search}
          onSearchChange={setSearch}
          dateValue={date}
          onDateChange={(e) => {
            onDateChange(e);
            setSearch(moment(e).format("L"));
          }}
        />

        <ColumnContainer>
          {jobPostings &&
            jobPostings.length > 0 &&
            currentJobIList
              ?.filter((item) => {
                if (search === "" && date === undefined) {
                  return item;
                } else if (
                  item.position &&
                  item.position.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                } else if (
                  item.name &&
                  item.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return item;
                } else if (
                  item.createdAt &&
                  moment(item.createdAt).format("L").includes(search)
                ) {
                  return item;
                }
                return item;
              })
              .map((item, key) => {
                return <JobPostingView item={item} key={key} />;
              })}
        </ColumnContainer>
        <ColumnContainer>
          {search === "" && (
            <ViewPagination
              itemsPerPage={itemsPerPage}
              totalItems={jobPostings && jobPostings.length}
              paginate={paginate}
            />
          )}
        </ColumnContainer>
      </Container>
    );
  }
}

export default RecentJobs;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;

const ColumnContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 2rem;
`;
