import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import { Checkbox } from "@mui/material";

import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import {
  ButtonWrapper,
  ContainerForm,
  OuterContainer,
  privacyOptions,
  TextInputContainer,
} from "../../Form/BlockForm/BlockForm";
import uuid from "react-uuid";
import { Label, TextField } from "../../Form/FormComponents/TextField";
import { Column } from "../../Form/Web3ResumeContainer";
import Select from "react-select";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Text } from "../../Typography/Typo";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import { useStateValue } from "../../../../context/StateProvider";
import { useSnackbar } from "notistack";
import BlockFormView from "../../Form/BlockForm/BlockFormView";

function AddWorkDataForm() {
  const [{ workFields }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [privacyWork, setPrivacyWork] = useState(privacyOptions[0]);
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [errors, setErrors] = useState("");
  const [description, setDescription] = useState("");
  const matches = useMediaQuery("(max-width:1368px)");
  const [startWorkDate, setStartWorkDate] = useState();
  const [workDateError, setWorkDateError] = useState(false);
  const [endWorkDate, setEndWorkDate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [workChecked, setWorkChecked] = useState(false);

  const handleCheckedWorkChange = (event) => {
    setWorkChecked(event.target.checked);
  };

  const checkDateRange = () => {
    //work
    const startWork = new Date(startWorkDate).getTime();
    const endWork = new Date(endWorkDate).getTime();
    //checkRange
    if (startWork > endWork) {
      setWorkDateError("Your date is invalid");
    } else {
      setWorkDateError("");
    }
  };

  const handleWorkFieldSubmit = async (e) => {
    setLoading(true);
    const ipfsData = await AddImageToIPFS(
      imageFile && imageFile.length > 0 && imageFile[0]
    );
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    e.preventDefault();
    try {
      dispatch({
        type: "ADD_WORK_FIELDS",
        item: {
          id: uuid(),
          position: position,
          company: company,
          startDate: new Date(startWorkDate).valueOf(),
          endDate: !workChecked
            ? new Date(endWorkDate).valueOf()
            : new Date(2050, 2, 3, 10, 30, 50, 800).valueOf(), // Mon Feb 03 2050 10:30:50,
          imageLogo: previewImageUrl,
          description: description,
          isPublic: privacyWork.value,
        },
      });
      enqueueSnackbar("Work experience added", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setPosition("");
      setCompany("");
      setStartWorkDate("");
      setEndWorkDate("");
      setImageFile([]);
      setDescription("");
      setPrivacyWork(privacyOptions[0]);
      setLoading(false);
      setWorkChecked(false);
    }
  };

  useEffect(() => {
    if (!workChecked) {
      if (startWorkDate && endWorkDate) {
        checkDateRange();
      }
    } else {
      setWorkDateError("");
    }
    //eslint-disable-next-line
  }, [startWorkDate, endWorkDate, workChecked]);

  return (
    <>
      {workFields?.length > 0 && (
        <Column span={!matches ? true : false} style={{ marginTop: "4rem" }}>
          {workFields &&
            workFields.length > 0 &&
            workFields.map((item, key) => {
              return (
                <BlockFormView
                  type={2}
                  data={item && item.startDate ? item : item.data}
                  key={key}
                />
              );
            })}
        </Column>
      )}
      <OuterContainer style={{ width: "100%" }}>
        <ContainerForm>
          <TextField
            label="Position *"
            placeholder="Position"
            id="position"
            value={position}
            form="none"
            onChange={setPosition}
            name="position"
            type="text"
            maxLength="100"
          />
          <TextField
            label="Company Name *"
            placeholder="Company Name"
            id="name"
            value={company}
            onChange={setCompany}
            name="name"
            form="none"
            type="text"
            maxLength="100"
          />
          <Column style={{ gridRow: "span 2" }}>
            <Label>Company Logo *</Label>
            <DropzoneComponent
              files={imageFile}
              id="dropzone"
              amount="1"
              form="none"
              multiple={false}
              setErrors={setErrors}
              errors={errors}
              maxSize="1000000"
              setFiles={setImageFile}
              type="image/*, image/jpeg, image/png"
              description="Drop or select your Logo image (Max file size 1MB)"
            />
          </Column>{" "}
          <Column>
            <Label>Start Date *</Label>
            <TextInputContainer>
              <DatePicker
                monthPlaceholder="MM"
                yearPlaceholder="YY"
                dayPlaceholder="DD"
                form="none"
                onChange={setStartWorkDate}
                value={startWorkDate}
                clearIcon={false}
                calendarIcon={
                  <CalendarTodayIcon style={{ color: "#080036" }} />
                }
                className="custom-date"
              />
            </TextInputContainer>
          </Column>
          <Column>
            {!workChecked && (
              <>
                <Label>End Date *</Label>
                <TextInputContainer>
                  <DatePicker
                    monthPlaceholder="MM"
                    yearPlaceholder="YY"
                    form="none"
                    dayPlaceholder="DD"
                    onChange={setEndWorkDate}
                    value={endWorkDate}
                    clearIcon={false}
                    calendarIcon={
                      <CalendarTodayIcon style={{ color: "#080036" }} />
                    }
                    className="custom-date"
                  />
                </TextInputContainer>
              </>
            )}
            <FlexContainer>
              <Checkbox
                checked={workChecked}
                onChange={handleCheckedWorkChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Label>Currently working on this role</Label>
            </FlexContainer>
          </Column>
          <Column>
            <Text lighter smaller style={{ color: "#FF613F" }}>
              {workDateError}
            </Text>
          </Column>
          <TextField
            span={!matches ? true : false}
            label="Description"
            placeholder="Description"
            id="description"
            onChange={setDescription}
            value={description}
            name="description"
            form="none"
            type="text"
          />{" "}
          <Column span={!matches ? true : false}>
            <Label>Private / Public</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#c3d0e1",
                  boxShadow: "none",
                },
              })}
              name="isPublic"
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={privacyOptions[0]}
              options={privacyOptions}
              value={privacyWork}
              onChange={setPrivacyWork}
            />
          </Column>
          <ButtonWrapper
            style={{
              girdColumn: `${!matches ? "1/3" : "unset"}`,
            }}
          >
            <Button
              style={{
                background: "#1fc36157",
                color: "#080036",
                textTransform: "inherit",
                fontWeight: "500",
                fontFamily: "Poppins",
                padding: ".3rem 1rem",
                float: "right",
              }}
              endIcon={loading && <CircularProgress size={20} />}
              form="workForm"
              onClick={handleWorkFieldSubmit}
              disabled={
                workDateError ||
                loading ||
                imageFile === [] ||
                position === "" ||
                endWorkDate === undefined ||
                startWorkDate === undefined ||
                company === ""
              }
              size="small"
            >
              Add Entry
            </Button>
          </ButtonWrapper>
        </ContainerForm>
      </OuterContainer>
    </>
  );
}

export default AddWorkDataForm;
const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
