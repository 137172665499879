import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VerificationStepper from "./VerificationStepper";
import { SubHeading, Text } from "../Typography/Typo";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

const VerificationAccordion = ({
  verification,
  item,
  handleChange,
  expanded,
  index,
}) => {
  return (
    <Accordion
      style={{ boxShadow: "none", margin: "0.2rem 0rem" }}
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <GridContainer>
          <FlexContainer>
            <Avatar sx={{ width: 32, height: 32 }} src={item?.receiverImage} />
            {expanded === `panel${index}` ? (
              <SubHeading primary style={{ textTransform: "capitalize" }}>
                {item?.receiverAlias}
              </SubHeading>
            ) : (
              <Text lighter primary style={{ textTransform: "capitalize" }}>
                {item?.receiverAlias}
              </Text>
            )}
          </FlexContainer>
          <Text lighter primary>
            {moment(item?.createdAt).format("LLL")}
          </Text>
          <>
            {expanded !== `panel${index}` && (
              <>
                {item.verificationStatus === 0 ? (
                  <CircleIcon
                    style={{
                      color: "#df642b",
                    }}
                  />
                ) : item.verificationStatus === 1 ? (
                  <CheckCircleIcon
                    style={{
                      color: "#1ccc54",
                    }}
                  />
                ) : (
                  <CircleIcon
                    style={{
                      color: "#cf2020",
                    }}
                  />
                )}
              </>
            )}
          </>
        </GridContainer>
      </AccordionSummary>
      <AccordionDetails>
        <VerificationStepper item={item} verification={verification} />
      </AccordionDetails>
    </Accordion>
  );
};

export default VerificationAccordion;

const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 4fr 0.5fr;
  place-content: center;
  place-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
