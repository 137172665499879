import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../Typography/Typo";
import { LoginIcon } from "../fields/Button/LoginIcon";
import { LinearProgress } from "@mui/material";

import { withStyles } from "@mui/styles";
import { LoadingContainer } from "../../../pages/Login/LoginForm";
import { CustomButton } from "../fields/Button/CustomButton";
import Search from "../fields/Search/Search";
import moment from "moment";
import image from "../../../assets/images/text logo.png";
import {
  Image,
  ImageContainer,
  ImageWrapper,
} from "../fields/Search/SearchRow";
import { useStateValue } from "../../../context/StateProvider";

//Progress button styles
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 4,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#80B7FF",
    animationDuration: "550ms",
  },
}))(LinearProgress);

export const RequestVerification = ({ setShowAction, verification }) => {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [submitting, setSubmitting] = useState(false);
  const [search, setSearch] = useState("");
  const [{ searchData }, dispatch] = useStateValue();

  const handleSubmit = () => {
    try {
      dispatch({
        type: "REMOVE_AUTHORITY",
      });
    } catch (error) {
      console.log(error);
    }
    setShowAction(false);
  };

  const maxlimit = 200;
  return (
    <>
      <OverLayContainer
        onClick={() => {
          if (Object.keys(searchData).length > 0) {
            try {
              dispatch({
                type: "REMOVE_AUTHORITY",
              });
            } catch (error) {
              console.log(error);
            }
          }
          setShowAction(false);
        }}
      />
      <Container>
        <FlexContainer>
          <Text primary>{`Type : ${verification.type}`}</Text>
          <Text primary>Unverified</Text>
        </FlexContainer>
        <Divider
          style={{
            background: "#36353511",
            width: "100%",
            margin: "0.5rem 0rem",
          }}
        />
        <FlexContainer addPadding>
          <ColumnContainer>
            <Text primary lighter small>
              Please Select an authority
            </Text>
          </ColumnContainer>
          <LoginIcon large />
        </FlexContainer>
        <Divider
          style={{
            width: "100%",
            marginBottom: "0.5rem",
          }}
        />
        <Search
          reducer="AUTHORITY"
          style={{ width: "100%" }}
          placeholder="Search for an Authority"
          searchValue={search}
          data={[
            { name: "Niftron" },
            { name: "University of Westminster" },
            { name: "Harvard University" },
            { name: "University of Cambridge" },
            { name: "Columbia University" },
            { name: "University of Oxford" },
            { name: "University of Chicago" },
          ]}
          onSearchChange={setSearch}
          withSelect
        />
        <FlexContainer
          addPadding
          style={{
            marginTop: "0.5rem",
          }}
        >
          <ColumnContainer>
            <Text primary>{verification.type}</Text>
            {(() => {
              if (
                verification?.fieldData?.data?.courseName ||
                verification?.fieldData?.data?.position
              ) {
                return (
                  <ColumnContainer>
                    <Text primary small lighter>
                      {verification.fieldData?.data?.courseName
                        ? verification.fieldData?.data?.courseName?.data
                        : verification.fieldData?.data?.position?.data}{" "}
                      -{" "}
                      {verification.fieldData?.data.courseName?.data
                        ? verification.fieldData?.data.institutionName?.data
                        : verification.fieldData?.data?.company?.data}
                    </Text>
                    <Text primary small lighter>
                      {moment(
                        verification.fieldData.data.startDate.data
                      ).format("MM/DD/YYYY")}{" "}
                      -{" "}
                      {moment(
                        verification.fieldData?.data?.endDate?.data
                      ).format("MM/DD/YYYY")}
                    </Text>
                  </ColumnContainer>
                );
              } else if (verification?.fieldData?.data?.itemImage) {
                return (
                  <ColumnContainer>
                    <Text primary small lighter>
                      {verification.fieldData?.data?.itemName.data} -
                      {verification.fieldData?.data.issuer?.data}
                    </Text>
                    <Text primary small lighter>
                      {moment(
                        verification.fieldData.data.issueDate.data
                      ).format("MM/DD/YYYY")}{" "}
                    </Text>
                  </ColumnContainer>
                );
              } else {
                return (
                  <Text primary small lighter>
                    {verification.fieldData.toString().length > maxlimit
                      ? verification.fieldData
                          .toString()
                          .substring(0, maxlimit - 3) + "..."
                      : verification.fieldData.toString()}
                  </Text>
                );
              }
            })()}
          </ColumnContainer>
        </FlexContainer>
        <FlexContainer
          style={{ width: "100%", marginTop: "1rem", marginBottom: "2rem" }}
        >
          {searchData?.name && (
            <FlexContainerSearch>
              <ImageContainer>
                <ImageWrapper>
                  <Image src={image} alt="logo" />
                </ImageWrapper>
                <Text lighter small color="#394673">
                  {searchData?.name}
                </Text>
              </ImageContainer>
            </FlexContainerSearch>
          )}
        </FlexContainer>
        {submitting ? (
          <LoadingContainer>
            <BorderLinearProgress />
            <Text primary small lighter style={{ width: "80px" }}>
              Verifying <span className="loading"></span>
            </Text>
          </LoadingContainer>
        ) : (
          <CustomButton
            wide
            login
            disabled={Object.keys(searchData).length === 0}
            onclick={handleSubmit}
            text="Request Verification"
          />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 25rem;
  z-index: 999;
  height: 35rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const OverLayContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 50%;
  z-index: 999;
  right: 50%;
  transform: translate(50%, -50%);
  background: #0c0b5584;
`;

const FlexContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.addPadding ? "0.5rem 0rem" : "0rem")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem, 0rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled.p`
  font-size: 0.7rem;
  color: ${(props) => (props.primary ? "#080036" : props.color)};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
`;

export const ColumnLoadContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 20rem;
  align-items: center;
`;

export const Gif = styled.img`
  margin-top: 5rem;
  width: 16rem;
  height: auto;
`;

const FlexContainerSearch = styled.div`
  text-align: center;
  width: 100%;
  background: #eaeaf3;
  align-items: center;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  padding: 0.5rem 1rem;
  gap: 1rem;
`;
