import styled from "styled-components";
import { LoginBanner } from "./LoginBanner";
import { LoginComponent } from "./LoginComponent";
import { GridContainer } from "./LoginPageStyles";

const Login = () => {
  return (
    <Container>
      <GridContainer>
        <LoginComponent />
        <LoginBanner />
      </GridContainer>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  backface-visibility: hidden;
  background-image: url(${(props) => props.image});
  @media (max-width: 768px) {
    height: auto;
  }
`;
