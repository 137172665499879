import React, { Fragment } from "react";
import styled from "styled-components";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import Credentials from "../CertificatesView/Credentials";
import { useParams, useLocation } from "react-router-dom";
import ClaimView from "../Claim/ClaimView";

const Claim = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <Container>
      {id ? (
        <Fragment>
          {location.state?.data?.status === 1 ? (
            <ButtonContainer
              heading={`${location.state?.data?.itemName}`}
              description={`View your credentials below`}
              icon={"link"}
            />
          ) : (
            <ButtonContainer
              heading="Claim Credentials"
              description="Go ahead and claim your credentials here"
              icon={"assured_workload"}
            />
          )}

          <ClaimView />
        </Fragment>
      ) : (
        <>
          <ButtonContainer
            heading="View Credentials"
            description="View your credentials here"
            icon={"link"}
          />
          <Credentials />
        </>
      )}
    </Container>
  );
};

export default Claim;

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
