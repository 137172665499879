import React from "react";
import styled from "styled-components";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import Table from "../../components/shared/Table/Table";
import { ButtonWrapper } from "../../university-pages/Certificates/IssueCertificateForm";

function TokenCredentialsTableView({ data, setShowAction, setView, token }) {
  function handleSubmit(e) {
    e.preventDefault();
    setShowAction(true);
  }

  return (
    <GridContainer>
      {data && (
        <TableOuterWrapper>
          <TableWrapper>
            <Table theadData={data[0]} tbodyData={data.slice(1)} />
          </TableWrapper>
        </TableOuterWrapper>
      )}
      <ButtonWrapper>
        <CustomButton login text="Back" onclick={() => setView(0)} />
        <CustomButton
          login
          text={token ? "Issue Tokens" : "Issue Certificates"}
          type="submit"
          onclick={(e) => handleSubmit(e)}
        />
      </ButtonWrapper>
    </GridContainer>
  );
}

export default TokenCredentialsTableView;

const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
  padding-bottom: 2rem;
  overflow: auto;
  padding-right: 1rem;
  max-height: 39vh;
  min-height: 20vh;
`;

export const TableOuterWrapper = styled.div`
  padding: 1rem;
  padding-bottom: 2rem;
  border-radius: 10px;
  background: #eff2f5;
`;
