import React, { useState } from "react";
import styled from "styled-components";
import ShoppingIcon from "../../assets/images/shopping-cart.png";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import JobPostingForm from "./JobPostingForm";
import RecentJobs from "./RecentJobs";

import FileIcon from "../../assets/images/file-icon.png";

function JobPosting() {
  const [view, setView] = useState(0);
  return (
    <Container>
      <ButtonContainer
        onClick={() => setView(1)}
        cursor
        biglink
        heading="Post a Job"
        description="Post a job, hire candidates"
        icon={"work"}
      />
      <ButtonContainer
        onClick={() => setView(0)}
        cursor
        biglink
        heading="Recent Job Postings"
        description="View recently posted jobs"
        icon={"work_history"}
      />
      <FlexContainer>
        {view === 0 ? <RecentJobs /> : <JobPostingForm setMainView={setView} />}
      </FlexContainer>
    </Container>
  );
}

export default JobPosting;

export const Container = styled.div`
  display: grid;
  align-content: flex-start;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1068px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  gap: 1rem;
  width: 100%;
`;
