import React from "react";
import styled from "styled-components";
import { SubHeading, Text } from "../Typography/Typo";

const UserProfileSelectButton = ({
  icon,
  title,
  description,
  selected,
  onClick,
}) => {
  return (
    <Container selected={selected} onClick={onClick}>
      <IconWrapper>
        <span className="material-icons ico-md green">{icon}</span>
      </IconWrapper>
      <SubHeading primary>{title}</SubHeading>
      <Text lighter primary small>
        {description}
      </Text>
    </Container>
  );
};

export default UserProfileSelectButton;

const Container = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 2rem 3rem;
  align-items: center;
  text-align: center;
  max-width: 20rem;
  min-height: 17rem;
  gap: 1rem;
  display: flex;
  cursor: pointer;
  transition: all 100ms ease-in;
  background-color: ${(props) => (props.selected ? "#d3dae06c" : "unset")};
  /* box-shadow: ${(props) =>
    props.selected ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : "unset"}; */
  flex-direction: column;
  &:hover {
    background-color: #d3dae06c;
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  background: -moz-linear-gradient(top, #00f260 0%, #0575e6 100%);
  background: -webkit-linear-gradient(top, #00f260 0%, #0575e6 100%);
  background: linear-gradient(to bottom, #00f260 0%, #0575e6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00F260, endColorstr=#0575E6, GradientType=0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
