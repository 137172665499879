import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Heading, Text } from "../Typography/Typo";
import { IconWrapper } from "../../../App";

export const ButtonContainer = (props) => {
  if (props.bigLink)
    return (
      <LinkContainer to={props.link} span={props.span} active={props.active}>
        <ColumnContainer>
          <Heading large primary>
            {props.heading}
          </Heading>
          <Text lighter primary small>
            {props.description}
          </Text>
        </ColumnContainer>

        <IconWrapper>
          <span className="material-icons ico-lg">{props.icon}</span>
        </IconWrapper>
      </LinkContainer>
    );
  else if (props.smallLink) {
    return (
      <SmallLinkContainer
        to={props.link}
        span={props.span}
        cursor={Boolean(props.cursor)}
        active={props.active}
      >
        <ColumnContainer>
          <Heading large primary>
            {props.heading}
          </Heading>
          <Text lighter primary small>
            {props.description}
          </Text>
        </ColumnContainer>
        <IconWrapper>
          <span className="material-icons ico-lg">{props.icon}</span>
        </IconWrapper>
      </SmallLinkContainer>
    );
  } else {
    return (
      <DivContainer
        cursor={Boolean(props.cursor)}
        onClick={props.onClick}
        active={props.active}
      >
        <ColumnContainer>
          <Heading large primary>
            {props.heading}
          </Heading>
          <Text lighter primary small>
            {props.description}
          </Text>
        </ColumnContainer>
        <IconWrapper>
          <span className="material-icons ico-lg">{props.icon}</span>
        </IconWrapper>
      </DivContainer>
    );
  }
};

export const LinkContainer = styled(Link)`
  text-decoration: none;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  display: flex;
  background-color: ${(props) => (props.active ? "#bcfbc2d8" : "#eff2f5")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  min-height: 10rem;
  justify-content: space-between;
  padding: 1.5rem;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;

const SmallLinkContainer = styled(Link)`
  display: flex;
  text-decoration: none;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  background-color: ${(props) => (props.active ? "#bcfbc2d8" : "#eff2f5")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  min-height: 5rem;
  padding: 1.5rem;
  justify-content: space-between;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;

const DivContainer = styled.div`
  cursor: ${(props) => (props.cursor ? "pointer" : "unset")};
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? "#bcfbc2d8" : "#eff2f5")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  min-height: 5rem;
  padding: 1.5rem;
  justify-content: space-between;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;

export const Icon = styled.img`
  width: 6rem;
  height: auto;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
