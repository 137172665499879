import React from "react";
import styled from "styled-components";
import UserProfileSelectButton from "./UserProfileSelectButton";

const UserProfileSelect = ({ setProfileType, profileType }) => {
  return (
    <Container>      
      <UserProfileSelectButton
        onClick={() => {
          setProfileType(2);
        }}
        selected={Boolean(profileType === 2)}
        icon="business"
        title="Entity Profile"
        description="Entities can maintain a profile to showcase their achievements, testimonials, and anything they want to verify."
      />
      <UserProfileSelectButton
        onClick={() => {
          setProfileType(1);
        }}
        selected={Boolean(profileType === 1)}
        icon="badge"
        title="Web 3.0 Resume"
        description="Individuals can maintain an instantly trustable and verifiable Smart Resume created in a decentralized manner with multiple entity verifications. Each info in the DePro can be verified."
      />

      <UserProfileSelectButton
        onClick={() => {
          setProfileType(3);
        }}
        selected={Boolean(profileType === 3)}
        icon="inventory_2"
        title="Product Profile"
        description="You can create a profile for each of your products to maintain authenticity, traceability and credibility."
      />
      {/* <UserProfileSelectButton
        onClick={() => {
          // setProfileType(4);
        }}
        selected={Boolean(profileType === 4)}
        icon="category"
        title="Asset Profile"
        description="Any valuable asset can be profiled through DePro with the verification of info that comes from multiple entities or users."
      /> */}
      {/* <UserProfileSelectButton
        onClick={() => {
          // setProfileType(5);
        }}
        selected={Boolean(profileType === 5)}
        icon="verified_user"
        title="Individual Profile"
        description="Individuals can profile any info they need and can request verification. (Finance, VISA, documents, contracts)"
      /> */}
    </Container>
  );
};

export default UserProfileSelect;

const Container = styled.div`
  display: grid;
  row-gap: 2rem;
  place-items: center;
  justify-items: center;
  column-gap: 0.8rem;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
  margin: 3rem 0rem;
  border-radius: 5px;
`;
