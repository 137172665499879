import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { TextField } from "../../components/shared/Form/FormComponents/TextField";
import MultipleImageViewSlider from "../../components/shared/Form/StageForm/MultipleImageViewSlider";
import { Image, ImageContainer } from "./IssueCertificateForm";
import ShareIcon from "@mui/icons-material/Share";
import { ShareConfirm } from "../../components/shared/Profile/ShareConfirm";
import { useSelector } from "react-redux";
import { urlToHash } from "../../utils/ipfs";

const CredentialView = ({ item }) => {
  const [shareOpen, setShareOpen] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);

  var dataListfieldKeys =
    item?.customData?.length > 0 &&
    item?.customData?.map((data) => {
      const keyData = Object.keys(data);
      return keyData;
    });

  var customDataListToMap =
    item?.customData?.length > 0 &&
    item?.customData?.map((data) => {
      return data;
    });

  return (
    <Container>
      {shareOpen && (
        <ShareConfirm
          data={item}
          message={
            item?.type === "education"
              ? "Copy Credential Link"
              : "Copy Token Link"
          }
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={
            item?.type === "education"
              ? "Share your Credential"
              : "Share your Token"
          }
          link={` http://explorer.deprofile.io/item/${item?.uniqueId}`}
        />
      )}
      <GridContainer style={{ marginTop: "2rem" }}>
        <ColumnContainer style={{ gap: "1rem" }}>
          <TextField
            disabled
            label={"Credential Name *"}
            value={item?.itemName}
          />
          <TextField
            disabled
            label={"Description *"}
            value={item?.description}
          />
          <TextField disabled label={"Blockchain *"} value={item?.blockchain} />

          <ColumnContainer>
            {dataListfieldKeys.length > 0 &&
              dataListfieldKeys?.map((fieldName, key) => {
                if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
                  return null;
                } else {
                  return (
                    <TextField
                      key={key}
                      disabled
                      label={
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(0, 1)
                          .toUpperCase() +
                        fieldName[0]
                          .toString()
                          .replace(/([A-Z])/g, " $1")
                          .substring(1)
                      }
                      value={customDataListToMap[key][fieldName[0]]}
                    />
                  );
                }
              })}
          </ColumnContainer>
        </ColumnContainer>
        <ImageContainer>
          <Image src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
               item?.itemImage
              )}`} alt="Credential" />
        </ImageContainer>
      </GridContainer>
      <ColumnContainer>
        {dataListfieldKeys.length > 0 &&
          dataListfieldKeys?.map((fieldName, key) => {
            if (Array.isArray(customDataListToMap[key][fieldName[0]])) {
              return (
                <ColumnContainer span={"true"}>
                  <MultipleImageViewSlider
                    fieldName={fieldName[0]}
                    data={customDataListToMap[key][fieldName[0]]}
                  />
                </ColumnContainer>
              );
            }
          })}
      </ColumnContainer>
      <Fragment> <ButtonWrapper> <CustomButton
        login
        icon={<ShareIcon />}
        text={
          item?.type === "education"
            ? "Share Credential"
            : "Share Token"
        }
        onclick={() => setShareOpen(true)}
      /> </ButtonWrapper> </Fragment>

      {/* <ButtonWrapper>
        <CustomButton login text="Back" onclick={() => setView(0)} />{" "}
        <CustomButton
          login
          disabled={
            certificateName === "" ||
            emailError ||
            (email === "" && recipient === "") ||
            imageFile.length === 0
          }
          onclick={() => {
            setShowAction(true);
          }}
          text="Issue Credential"
        />
      </ButtonWrapper> */}
    </Container>
  );
};

export default CredentialView;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
`;


const ButtonWrapper = styled.div`
  padding-top: 1rem;
  gap: 2rem;
  display: flex;
  justify-content: flex-end;
`; 

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
`;
