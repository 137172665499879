import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUserSession } from "../../../../api/API";
import { useStateValue } from "../../../../context/StateProvider";
import { dispatchEditObject, getArrayData } from "../../../../utils/editForm";
import { useHistory } from "react-router-dom";
import ActionConfirm from "../../Form/FormComponents/ActionConfirm";
import AddCustomDataEditComponent from "./AddCustomDataEditComponent";
import MultiCustomDataEditComponent from "./MultiCustomDataEditComponent";

function CustomDataFormSwitch({ setOverLay, authType }) {
  const latestIpfsData = useSelector((state) => state.latestIpfsData);
  const ipfsDataCopy = useSelector((state) => state.ipfsDataCopy);
  const [customStateData, setCustomStateData] = useState(
    getArrayData(latestIpfsData?.customData)
  );
  const [status, setStatus] = useState(customStateData?.length <= 0 ? 1 : 2);
  const [loading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [{ customData }, dispatch] = useStateValue();
  const currentUser = useSelector((state) => state.currentUser);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleEditCustom = (secretkey) => {
    let productObject = {};
    let customProductDataArr = [];
    try {
      if (customStateData?.length > 0) {
        for (var key of Object.keys(customStateData)) {
          customProductDataArr.push(customStateData[Number(key)]);
        }
        productObject.customData = customProductDataArr;
      }

      if (productObject?.customData[0]?.data) {
        dispatchEditObject(
          secretkey,
          setLoading,
          productObject,
          [],
          currentUser,
          enqueueSnackbar,
          "customData-edit",
          ipfsDataCopy.length !== 0 ? ipfsDataCopy : latestIpfsData,
          setOverLay,
          history,
          authType
        );
      } else {
        dispatch({
          type: "CLEAR_FIELDS",
        });
        setOverLay(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCustomStateData([]);
    }
  };

  const handleAddCustom = (secretkey) => {
    let productObject = {};
    try {
      productObject.customData = customData;

      dispatchEditObject(
        secretkey,
        setLoading,
        productObject,
        [],
        currentUser,
        enqueueSnackbar,
        "customData-add",
        ipfsDataCopy,
        setOverLay,
        history,
        authType
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {(() => {
        switch (status) {
          case 1:
            return (
              <AddCustomDataEditComponent
                setOverLay={setOverLay}
                setStatus={setStatus}
                showEdit={customStateData?.length <= 0}
              />
            );
          case 2:
            return (
              <MultiCustomDataEditComponent
                setOverLay={setOverLay}
                setStatus={setStatus}
                customData={customStateData}
                setCustomData={setCustomStateData}
                datas={latestIpfsData.customData}
              />
            );
          case 3:
            return (
              <ActionConfirm
                successMessage={{
                  message: "Making changes in IPFS",
                  width: "12rem",
                }}
                warningMessage={"Are you sure you want to Add data?"}
                loading={loading}
                setShowAction={setOverLay}
                authTypeInput={authUser.authType}
                publicKey={authUser.publicKey}
                encryptedSecret={authUser.encryptedSecret}
                onSecretKey={handleAddCustom}
              />
            );
          case 4:
            return (
              <ActionConfirm
                successMessage={{
                  message: "Making changes in IPFS",
                  width: "12rem",
                }}
                warningMessage={"Are you sure you want to edit?"}
                loading={loading}
                setShowAction={setOverLay}
                authTypeInput={authUser.authType}
                publicKey={authUser.publicKey}
                encryptedSecret={authUser.encryptedSecret}
                onSecretKey={handleEditCustom}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
}

export default CustomDataFormSwitch;
