import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSnackbar } from "notistack";
import { SearchComponent } from "./SearchComponent";
import ViewPagination from "../../components/shared/Pagination/Pagination";
import JobComponent from "./JobComponent";
import { fetchJobs } from "../../api/API";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import { Error, ErrorContainer } from "../Claim/ClaimView";
import { Text } from "../../components/shared/Typography/Typo";
import ErrorBG from "../../assets/images/joberror.gif";

function JobView() {
  const [date, onDateChange] = useState();
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [jobLoading, setJobLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const itemsPerPage = 6;

  const paginate = (event, value) => {
    setCurrentPage(value);
    window.scroll(0, 0);
  };

  // Fetching all the jobs
  const retrieveJobs = async () => {
    setLoading(true);
    try {
      const response = await fetchJobs({
        query: {
          col: "",
          key: "",
        },
      });

      if (
        response &&
        response.status === 200 &&
        response.data.data.length > 0
      ) {
        setJobs(response.data.data);
      } else {
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveJobs();
    //eslint-disable-next-line
  }, []);

  //get Current Products
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentJobIList =
    jobs && jobs.length > 0 && jobs.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Container>
      <SearchComponent
        searchValue={search}
        onSearchChange={setSearch}
        dateValue={date}
        placeholder="Search for Jobs"
        onDateChange={(e) => {
          onDateChange(e);
          setSearch(moment(e).format("L"));
        }}
      />

      {(() => {
        if (loading) {
          return <InnerLoadingView style={{ marginTop: "1rem" }} />;
        } else if (jobs.length <= 0) {
          return (
            <ErrorContainer>
              <Error bg={ErrorBG} width="500px" />
              <Text primary>No Jobs Found</Text>
            </ErrorContainer>
          );
        } else {
          return (
            <>
              {jobs &&
                jobs.length > 0 &&
                currentJobIList
                  ?.filter((item) => {
                    if (search === "" && date === undefined) {
                      return item;
                    } else if (
                      item.position &&
                      item.position.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.name &&
                      item.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    } else if (
                      item.createdAt &&
                      moment(item.createdAt).format("L").includes(search)
                    ) {
                      return item;
                    }
                    return item;
                  })
                  .map((item, key) => {
                    return (
                      <JobComponent
                        key={key}
                        description={item.description}
                        company={{
                          name: item.name,
                          logo: item.companyProfileImage,
                        }}
                        time={item.createdAt}
                        loading={jobLoading}
                        title={item.position}
                        setJobLoading={loading}
                        item={item}
                        // location={
                        //   item?.companyAddress?.addressLineOne +
                        //   "," +
                        //   item?.companyAddress?.addressLineTwo
                        // }
                        location={item?.email}
                      />
                    );
                  })}
              {search === "" && (
                <ViewPagination
                  itemsPerPage={itemsPerPage}
                  totalItems={jobs && jobs.length}
                  paginate={paginate}
                />
              )}
            </>
          );
        }
      })()}
    </Container>
  );
}

export default JobView;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 55vh;
`;
