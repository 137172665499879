import React from "react";
import styled from "styled-components";
import CredentialRow from "./CredentialRow";
import TableHeadItem from "./TableHeadItem";
import TableRow from "./TableRow";
import VerificationTableRow from "./VerificationTableRow";

export default function Table({
  customClass,
  theadData,
  tbodyData,
  tableView,
  customRow,
  credentialRow,
  onChange,
}) {
  return (
    <TableContainer className={customClass}>
      {tbodyData?.length <= 0 ? (
        <>
          <tbody>
            <WideRow>No Items to Display</WideRow>
          </tbody>
        </>
      ) : (
        <>
          <TableHead>
            <Tr>
              {theadData &&
                theadData.length > 0 &&
                theadData?.map((h, key) => {
                  return <TableHeadItem key={key} item={h} />;
                })}
            </Tr>
          </TableHead>
          <tbody>
            {tbodyData &&
              tbodyData?.length > 0 &&
              tbodyData?.map((item, key) => {
                if (customRow) {
                  return (
                    <VerificationTableRow
                      onChange={onChange}
                      key={key}
                      id={key}
                      data={item}
                      tableView={tableView}
                    />
                  );
                } else if (credentialRow) {
                  return <CredentialRow item={item} key={key} />;
                } else {
                  return (
                    <TableRow
                      key={key}
                      data={item}
                      tableView={tableView}
                      credentialRow={credentialRow}
                    />
                  );
                }
              })}
          </tbody>
        </>
      )}
    </TableContainer>
  );
}

const TableContainer = styled.table`
  width: 100%;
  color: #2f3a60;
  height: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const TableHead = styled.thead`
  width: 100%;
  position: sticky;
  z-index: 99;
  top: 0;
  background: #eff2f5;
  left: 0;
  text-align: center;
`;

const Tr = styled.tr`
  &:last-child {
    border-right: 0;
  }
`;

const WideRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: center;
`;
