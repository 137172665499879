import * as API from "../api/API";
import { ADD_USER_DETAILS } from "../redux/constants/ActionTypes";
import store from "../redux/reducers";
import { AddToIPFS } from "../services/IpfsService";
import { createCVObject, oldCreateCVObject } from "./cvObject";
import { jwtDecode } from "./jwt";
import { symmetricEncryption } from "./encryption";

export const getArrayData = (array, type) => {
  let newArr = [];

  if (type === "techStack") {
    if (array?.length > 0)
      array?.map((data) => {
        newArr?.push({
          value: data?.data?.data,
          label:
            data?.data?.data?.charAt(0)?.toUpperCase() +
            data?.data?.data?.slice(1),
        });
      });

    return newArr;
  } else if (type === "stageData") {
    return;
  } else {
    if (array?.length > 0)
      array?.map((data) => {
        let newObject = {};
        var keys = Object.keys(data?.data);
        keys.map((fieldKey) => {
          if (fieldKey === "isPublic")
            newObject[fieldKey] =
              data?.data[fieldKey]?.data === "true" ? true : false;
          else newObject[fieldKey] = data?.data[fieldKey]?.data;
        });
        newArr?.push(newObject);
      });

    return newArr;
  }
};

export const dispatchEditObject = async (
  secretKey,
  setLoading,
  productData,
  privacyData,
  currentUser,
  enqueueSnackbar,
  type,
  latestIpfsData,
  setOverLay,
  history,
  authType
) => {
  setLoading(true);
  try {
    // Get logged in user
    let getLatestIpfsData = latestIpfsData;
    const user = jwtDecode();

    //workData-add and workData-edit
    if (type === "workData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      if (getLatestIpfsData?.workData) {
        let newWorkData = [
          ...getLatestIpfsData.workData,
          ...formattedObject.workData,
        ];
        delete getLatestIpfsData.workData;
        getLatestIpfsData.workData = newWorkData;
      } else {
        getLatestIpfsData.workData = formattedObject.workData;
      }
    } else if (type === "workData-edit") {
      productData.workData?.map((item) => {
        if (item.isPublic === false) {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            value.data = symmetricEncryption(value.data, secretKey);
            value.encryptionType = "SYMMETRIC";
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.workData = productData.workData;
    }

    //eduData-add and eduData-edit
    else if (type === "eduData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      if (getLatestIpfsData?.educationData) {
        let newEduData = [
          ...getLatestIpfsData.educationData,
          ...formattedObject.educationData,
        ];
        delete getLatestIpfsData.educationData;
        getLatestIpfsData.educationData = newEduData;
      } else {
        getLatestIpfsData.educationData = formattedObject.educationData;
      }
    } else if (type === "eduData-edit") {
      productData.educationData?.map((item) => {
        if (item.isPublic === false) {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            value.data = symmetricEncryption(value.data, secretKey);
            value.encryptionType = "SYMMETRIC";
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.educationData = productData.educationData;
    }

    //achievementsData-add and edit
    else if (type === "achievementsData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      if (getLatestIpfsData?.achievements) {
        let newAchieveData = [
          ...getLatestIpfsData.achievements,
          ...formattedObject.achievements,
        ];
        delete getLatestIpfsData.achievements;
        getLatestIpfsData.achievements = newAchieveData;
      } else {
        getLatestIpfsData.achievements = formattedObject.achievements;
      }
    } else if (type === "achievementsData-edit") {
      productData.achievements?.map((item) => {
        if (item.isPublic === false) {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            value.data = symmetricEncryption(value.data, secretKey);
            value.encryptionType = "SYMMETRIC";
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.achievements = productData.achievements;
    }

    //customData-add and edit
    else if (type === "customData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      if (getLatestIpfsData?.customData) {
        let newAchieveData = [
          ...getLatestIpfsData.customData,
          ...formattedObject.customData,
        ];
        delete getLatestIpfsData.customData;
        getLatestIpfsData.customData = newAchieveData;
      } else {
        getLatestIpfsData.customData = formattedObject.customData;
      }
    } else if (type === "customData-edit") {
      productData.customData?.map((item) => {
        if (item.isPublic === false || item?.data?.isPublic?.data === "false") {
          item?.data &&
            Object.entries(item.data).forEach((e) => {
              const [key, value] = e;
              value.data = symmetricEncryption(value.data, secretKey);
              value.encryptionType = "SYMMETRIC";
            });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.customData = productData.customData;
    }

    //stageData-add and edit
    else if (type === "stageData-add") {
      // Format into CV Object
      const formattedObject = createCVObject(
        productData,
        user.publicKey,
        user.publicKey,
        secretKey,
        privacyData
      );

      if (getLatestIpfsData?.stageData) {
        let newAchieveData = [
          ...getLatestIpfsData.stageData,
          ...formattedObject.stageData,
        ];
        delete getLatestIpfsData.stageData;
        getLatestIpfsData.stageData = newAchieveData;
      } else {
        getLatestIpfsData.stageData = formattedObject.stageData;
      }
    } else if (type === "stageData-edit") {
      productData.stageData?.map((item) => {
        if (item.isPublic === false) {
          Object.entries(item.data).forEach((e) => {
            const [key, value] = e;
            if (key !== "customStageData") {
              value.data = symmetricEncryption(value.data, secretKey);
              value.encryptionType = "SYMMETRIC";
            }
          });
          item.isPublic = false;
        }
      });
      getLatestIpfsData.stageData = productData.stageData;
    }

    // add product to ipfs

    const productIpfsHash = await AddToIPFS(JSON.stringify(getLatestIpfsData));

    const data = {
      path: { id: user.publicKey },
      token: localStorage.niftoken,
      body: {
        latestIpfsUrl: {
          ipfsUrl: `https://niftron.infura-ipfs.io/ipfs/${productIpfsHash.ipfsHash}`,
          timestamp: Date.now(),
        },
      },
    };
    const response =
      authType === "1"
        ? await API.updateCredentialProvider(data)
        : await API.addCv(data);

    if (response?.data?.code === 202) {
      const userData = await API.me({
        token: localStorage.niftoken,
      });
      if (userData?.data?.code === 200) {
        store.dispatch({
          type: ADD_USER_DETAILS,
          payload: userData?.data?.data[0],
        });
        enqueueSnackbar("Successfully made changes", {
          variant: "success",
        });
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    setOverLay(false);
    authType === "1"
      ? history.push("/dashboard/profile")
      : history.push("/dashboard/my-profile");
  }
};
