import { Avatar, AvatarGroup } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { CustomButton } from "../fields/Button/CustomButton";
import { Text } from "../Typography/Typo";
import VerificationAccordion from "./VerificationAccordion";
import VerificationStepper from "./VerificationStepper";

const VerificationStatusView = ({
  handleRequestVerification,
  verification,
  isViewAssignedItem,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      {verification?.fieldData?.verifiedBy?.length > 1 && (
        <FlexContainer>
          <Text primary>Requested To</Text>
          {/* <AvatarGroup total={verification?.fieldData?.verifiedBy?.length}>
            {verification?.fieldData?.verifiedBy.map((item, key) => (
              <Avatar
                sx={{ width: 24, height: 24 }}
                key={key}
                alt={item?.receiverAlias}
                src={item?.receiverImage}
              />
            ))}
          </AvatarGroup> */}
        </FlexContainer>
      )}
      {verification?.fieldData?.verifiedBy?.length === 0 && (
        <Text primary>There are no available verification information</Text>
      )}
      <OverFlowContainer>
        {verification?.fieldData?.verifiedBy.map((item, key) => {
          if (verification?.fieldData?.verifiedBy?.length > 1) {
            return (
              <VerificationAccordion
                key={key}
                item={item}
                verification={verification}
                handleChange={handleChange}
                expanded={expanded}
                index={key}
              />
            );
          } else {
            return (
              <VerificationStepper
                key={key}
                item={item}
                verification={verification}
              />
            );
          }
        })}
      </OverFlowContainer>
      {!isViewAssignedItem && (
        <ButtonContainer>
          <CustomButton
            login
            wide
            onclick={handleRequestVerification}
            text="Request Verification"
            disabled={verification?.fieldData?.verifiedBy?.find(
              (item) => item?.verificationStatus === 0
            )}
          />
        </ButtonContainer>
      )}
    </Container>
  );
};

export default VerificationStatusView;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  z-index: 999;
  height: auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 52px -22px rgba(0, 0, 0, 0.75);
  @media (max-width: 768px) {
    width: 80%;
    padding: 1.5rem;
  }
  @media (max-width: 1368px) {
    width: 80%;
  }
`;

const ButtonContainer = styled.div`
  width: 93%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  background-color: #fff;
`;

const OverFlowContainer = styled.div`
  max-height: 30rem;
  overflow-y: auto;
  padding: 0rem 1rem 2rem 1rem;
  width: 93%;
`;

const FlexContainer = styled.div`
  width: 93%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;
