import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Text } from "../../Typography/Typo";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

export const NavTag = (props) => {
  const [previous, setPrevious] = useState("");
  const location = useLocation();
  //TODO
  //eslint-disable-next-line
  const [routeMatch, setRouteMatch] = useState(false);
  var url = location.pathname;

  useEffect(() => {
    const getPrevious = () => {
      var previousUrl = url.slice(0, url.lastIndexOf("/"));
      setPrevious(previousUrl);
    };

    getPrevious();
  }, [url]);

  return (
    <Container>
      <Link
        style={{ textDecoration: "none" }}
        to={{
          pathname: routeMatch ? previous + `/` + props.param : previous,
        }}
      >
        <Text color="#fff" lighter style={{ textTransform: "capitalize" }}>
          {props.param.replace(/-/g, " ")}
        </Text>
      </Link>
      {props.renderIcon && <ArrowForwardIosIcon />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  @media (max-width: 1368px) {
    display: none;
  }
`;
