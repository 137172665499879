import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Modal } from "@mui/material";

import {
  ArrowBody,
  ArrowsContainer,
  Slider,
} from "../Form/StageForm/StageSliderView";
import VerifiedStatusContainer from "../Profile/VerifiedStatusContainer";
import { GetIPFSData } from "../../../services/IpfsService";
import { SubHeading, Text } from "../Typography/Typo";
import ProductProfileHeader, {
  IconWrapper,
  SectionContainer,
} from "./ProductProfileHeader";
import { RequestVerification } from "../Profile/RequestVerification";
import { ViewVerifiedDetails } from "./ViewVerifiedDetails";
import AdditionalDetails from "../Profile/AdditionalDetails";
import { urlToHash } from "../../../utils/ipfs";
import EditForm from "../EditForms/EditForm";
import {
  symmetricDecryption,
  symmetricEncryption,
} from "../../../utils/encryption";
import store from "../../../redux/reducers";
import { ADD_IPFS_DATA } from "../../../redux/constants/ActionTypes";
import EncryptedData from "../Profile/EncryptedData";
import ActionConfirm from "../Form/FormComponents/ActionConfirm";
import { getUserSession } from "../../../api/API";
import ContactInfo from "../Profile/ContactInfo";
import { ProductShareConfirm } from "./ProductShareConfirm";
import ProductProfileEdit from "../EditForms/ProductProfileEdit";

const ProductProfile = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const [authUser, setAuthUser] = useState(() => getUserSession());
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [showAction, setShowAction] = useState(false);
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });
  const [isPublic, setIsPublic] = useState(false);
  const [showStageEdit, setShowStageEdit] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [decryptType, setDecryptType] = useState();
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [currentField, setCurrentField] = useState({});
  const [openContact, setContactOpen] = useState(false);
  const [editType, setEditType] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [showCustomEdit, setShowCustomEdit] = useState(false);
  const [viewVerifiedData, setViewVerifiedData] = useState(false);

  // TODO: Want to do a alternative way without getting the latestIpfsData
  // Get the latestIpfsData
  const fetchLatestProfileData = async () => {
    if (currentUser?.latestIpfsUrl?.ipfsUrl) {
      const hash = urlToHash(currentUser?.latestIpfsUrl?.ipfsUrl);
      try {
        const data = await GetIPFSData(hash);
        setLatestIpfsData(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchLatestProfileData();
  }, []);

  // TODO: Can be generalized into a util function
  // Decrypting data
  const decryptData = async (secretKey) => {
    setSecretKey(secretKey);
    // Decrypting all the data
    if (decryptType === "all") {
      Object.entries(latestIpfsData).forEach((e) => {
        const [key, value] = e;

        if (value.isPublic === false) {
          value.data.data = symmetricDecryption(value.data.data, secretKey);
          value.data.encryptionType = "PUBLIC";
          value.isPublic = true;
        }
        if (value.isPublic === true) {
          value.data.encryptionType = "PUBLIC";
        }

        if (key === "stageData" || key === "customData") {
          for (let i = 0; i < value.length; i++) {
            if (value[i].isPublic === false) {
              value[i].isPublic = true;
              Object.entries(value[i].data).forEach((e) => {
                const [key, value] = e;
                if (key !== "customStageData") {
                  value.data = symmetricDecryption(value.data, secretKey);
                  value.encryptionType = "PUBLIC";
                }
              });
            }
            if (value[i].isPublic === true) {
              value[i].isPublic = true;
              Object.entries(value[i].data).forEach((e) => {
                const [key, value] = e;
                value.encryptionType = "PUBLIC";
              });
            }
          }
        }
      });

      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
      setIsDecrypted(true);
    } else {
      // Decrypting single data

      if (typeof currentField?.id === "string") {
        latestIpfsData[currentField.id].data.data = symmetricDecryption(
          latestIpfsData[currentField.id].data.data,
          secretKey
        );
        latestIpfsData[currentField.id].isPublic = true;
      }
      // Decrypting data of arrays
      if (typeof currentField?.id === "number") {
        Object.entries(
          latestIpfsData[currentField.name][currentField.id].data
        ).forEach((e) => {
          const [key, value] = e;
          if (key !== "customStageData") {
            value.data = symmetricDecryption(value.data, secretKey);
          }
        });
        latestIpfsData[currentField.name][currentField.id].isPublic = true;
      }
    }
    setShowDecryption(false);
    switch (editType) {
      case "basicEdit":
        return setEditOpen(true);
      case "customEdit":
        return setShowCustomEdit(true);
      case "stageEdit":
        return setShowStageEdit(true);
      case "shareOpen":
        return setShareOpen(true);
      default:
        return null;
    }
  };

  // TODO: Can be generalized into a util function
  // Encrypting data
  const encryptData = async (data) => {
    // Encrypting all the data
    if (decryptType === "all") {
      setDecryptType();
      setIsDecrypted(false);
      fetchLatestProfileData();
      store.dispatch({
        type: ADD_IPFS_DATA,
        payload: latestIpfsData,
      });
    } else {
      // Encrypting single data
      if (typeof data?.id === "string") {
        latestIpfsData[data.id].data.data = symmetricEncryption(
          latestIpfsData[data.id].data.data,
          secretKey
        );
        latestIpfsData[data.id].isPublic = false;
      }
      // Encrypting data of arrays
      if (typeof data?.id === "number") {
        Object.entries(latestIpfsData[data.name][data.id].data).forEach((e) => {
          const [key, value] = e;

          if (key !== "customStageData") {
            value.data = symmetricEncryption(value.data, secretKey);
          }
        });
        latestIpfsData[data.name][data.id].isPublic = false;
      }
    }
  };

  const handleContactClose = () => {
    setContactOpen(false);
  };

  return (
    <Fragment>
      {showAction && (
        <RequestVerification
          verification={verification}
          currentField={currentField}
          show={showAction}
          setShowAction={setShowAction}
        />
      )}
      {shareOpen && (
        <ProductShareConfirm
          data={latestIpfsData}
          message={"Copy Product Profile Link"}
          setShowAction={setShareOpen}
          currentUser={currentUser}
          title={"Share your product Profile"}
          link={`http://explorer.deprofile.io/depro/${currentUser.alias}`}
        />
      )}{" "}
      {editOpen && (
        <ProductProfileEdit
          profileData={latestIpfsData}
          setEditOpen={setEditOpen}
          secretKey={secretKey}
        />
      )}
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
        />
      </Modal>
      {viewVerifiedData && (
        <ViewVerifiedDetails
          verifiedData={currentField}
          setShowAction={setViewVerifiedData}
        />
      )}
      {showDecryption && (
        <ActionConfirm
          warningMessage={"Confirm your credentials to decrypt"}
          setShowAction={setShowDecryption}
          authTypeInput={authUser.authType}
          publicKey={authUser.publicKey}
          encryptedSecret={authUser.encryptedSecret}
          onSecretKey={decryptData}
        />
      )}
      {showStageEdit && <EditForm setOverLay={setShowStageEdit} type={5} />}
      {showCustomEdit && <EditForm setOverLay={setShowCustomEdit} type={4} />}
      <Modal
        open={openContact}
        onClose={handleContactClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactInfo
          profileData={latestIpfsData}
          setShowAction={setShowAction}
          setVerification={setVerification}
          setShowDecryption={setShowDecryption}
          setShowEncryption={encryptData}
          setCurrentField={setCurrentField}
        />
      </Modal>
      <Container>
        <ProductProfileHeader
          profileData={latestIpfsData}
          setVerification={setVerification}
          setShowAction={setShowAction}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          setShowDecryption={setShowDecryption}
          setCurrentField={setCurrentField}
          setShowEncryption={encryptData}
          decryptType={setDecryptType}
          isDecrypted={isDecrypted}
          setEditOpen={setEditOpen}
          setEditType={setEditType}
          setShareOpen={setShareOpen}
          setContactOpen={setContactOpen}
        />
        <SectionContainer>
          <SectionInnerContainer>
            <FlexSpaceBetweenContainer>
              <SubHeading primary>About</SubHeading>{" "}
              <VerifiedStatusContainer
                isPublic={isPublic}
                fieldData={latestIpfsData?.description}
                fieldName="Description"
                fieldId={{ id: "description" }}
                setShowAction={setShowAction}
                setVerification={setVerification}
                setShowDecryption={setShowDecryption}
                setShowEncryption={encryptData}
                isDecrypted={latestIpfsData?.description?.isPublic}
                encryptedType={
                  latestIpfsData?.description?.data?.encryptionType
                }
                setCurrentField={setCurrentField}
              />
            </FlexSpaceBetweenContainer>
            {latestIpfsData?.description?.isPublic ? (
              <Text primary lighter>
                {latestIpfsData?.description?.data?.data}
              </Text>
            ) : (
              <EncryptedData
                fieldData={latestIpfsData?.description?.data?.data}
                setShowDecryption={setShowDecryption}
              />
            )}
          </SectionInnerContainer>
        </SectionContainer>

        {latestIpfsData &&
          latestIpfsData.stageData &&
          latestIpfsData.stageData.length > 0 && (
            <SectionContainer>
              <FlexColumnContainer>
                <FlexSpaceBetweenContainer>
                  <SubHeading primary>Stages</SubHeading>
                  <IconWrapper style={{ backgroundColor: "#c7c7c72f" }}>
                    <IconButton
                      onClick={() => {
                        if (!latestIpfsData?.stageData?.[0].isPublic) {
                          setEditType("");
                          if (!isDecrypted) {
                            setDecryptType("all");
                            setShowDecryption(true);
                            setEditType("stageEdit");
                          } else {
                            setShowStageEdit(true);
                          }
                        } else {
                          setShowStageEdit(true);
                        }
                      }}
                    >
                      {latestIpfsData?.stageData?.length === 0 ? (
                        <AddIcon
                          style={{ color: "#8a8a8a" }}
                          sx={{ fontSize: 20 }}
                        />
                      ) : (
                        <EditIcon
                          style={{ color: "#8a8a8a" }}
                          sx={{ fontSize: 20 }}
                        />
                      )}
                    </IconButton>
                  </IconWrapper>
                </FlexSpaceBetweenContainer>
                <Slider
                  productData={latestIpfsData?.stageData}
                  setShowAction={setShowAction}
                  setVerification={setVerification}
                  setViewVerifiedData={setViewVerifiedData}
                  setCurrentField={setCurrentField}
                  setShowDecryption={setShowDecryption}
                  setShowEncryption={encryptData}
                  isPublic={isPublic}
                />
                <ArrowsContainer>
                  <ArrowBody className="prev">
                    <ArrowBackIosNewIcon
                      className="prev"
                      style={{ color: "#080036", cursor: "pointer" }}
                    />
                  </ArrowBody>
                  <ArrowBody className="next">
                    <ArrowForwardIosIcon
                      className="next"
                      style={{ color: "#080036", cursor: "pointer" }}
                    />
                  </ArrowBody>
                </ArrowsContainer>
              </FlexColumnContainer>
            </SectionContainer>
          )}

        {latestIpfsData &&
          latestIpfsData.customData &&
          latestIpfsData.customData.length > 0 && (
            <AdditionalDetails
              isDecrypted={isDecrypted}
              decryptType={setDecryptType}
              isPublic={isPublic}
              profileData={latestIpfsData}
              setShowAction={setShowAction}
              setVerification={setVerification}
              setShowDecryption={setShowDecryption}
              setShowEncryption={encryptData}
              setCurrentField={setCurrentField}
              setShowCustomEdit={setShowCustomEdit}
              setEditType={setEditType}
            />
          )}
      </Container>
    </Fragment>
  );
};

export default ProductProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
