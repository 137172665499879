import React from "react";
import { ButtonContainer } from "../../components/shared/DashboardComponents/ButtonContainer";
import styled from "styled-components";
import { Error, ErrorContainer } from "../../pages/Claim/ClaimView";
import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ErrorBG from "../../assets/images/not-found.gif";
import { useLocation } from "react-router-dom";
import VerificationSingleView from "../../components/shared/VerificationViews/VerificationSingleView";

const VerifyApplication = () => {
  const { state } = useLocation();

  if (state?.item) {
    return (
      <Container>
        <ButtonContainer
          span
          biglink
          heading={state.item.senderAlias}
          description={`Requested Type  :  ${
            state.item.dataType.name
              ? state.item.dataType.name
              : state.item.dataType.id
          }`}
          icon={"link"}
        />
        <VerificationSingleView data={state.item} />
      </Container>
    );
  } else {
    return (
      <ErrorContainer>
        <Error bg={ErrorBG} width="500px">
          <SubHeading primary>User Not Found!</SubHeading>
        </Error>
        <Text primary>Looks like you're lost</Text>
      </ErrorContainer>
    );
  }
};

export default VerifyApplication;

const Container = styled.div`
  display: flex;
  border-radius: 10px;
  border: 0.75px solid #d3d3d3b0;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
`;
