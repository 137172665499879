import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import reducer, { initialstate } from "./context/reducer";
import { StateProvider } from "./context/StateProvider";
import { Provider } from "react-redux";
import store from "./redux/reducers/index";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider
      classes={{
        containerAnchorOriginTopCenter: "z-alert",
      }}
      maxSnack={1}
      preventDuplicate
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Router>
        <Provider store={store}>
          <StateProvider initialstate={initialstate} reducer={reducer}>
            <App />
          </StateProvider>
        </Provider>
      </Router>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
