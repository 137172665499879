import { Modal } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { ImageFlexContainer, LargeImage } from "../../../pages/ViewCV/Field";
import { urlToHash } from "../../../utils/ipfs";

export default function TableRow({ data, credentialRow }) {
  const [openImage, setImageOpen] = useState(false);
  const handleImageOpen = () => setImageOpen(true);

  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <Tr>
      {data?.map((item) => {
        if (item.includes("http")) {
          return (
            <Td>
              <Modal
                open={openImage}
                onClose={handleImageClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ImageFlexContainer>
                  <div style={{ position: "relative" }}>
                    <LargeImage src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                      item
                    )}`} alt="image" />
                  </div>
                </ImageFlexContainer>
              </Modal>
              <ImageWrapper
                image={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                  item
                )}`}
                onClick={() => {
                  handleImageOpen();
                }}
              />
            </Td>
          );
        } else {
          return <Td key={item}>{item}</Td>;
        }
      })}
    </Tr>
  );
}

export const Tr = styled.tr`
  cursor: ${(props) => (props.credentialRow ? "pointer" : "unset")};
  &:nth-child(odd) {
    background: rgba(57, 70, 115, 0.048);
  }
  &:hover {
    background: rgba(57, 70, 115, 0.226);
  }
`;

export const Td = styled.td`
  padding: 0.6rem 1rem;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const ImageWrapper = styled.div`
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 2rem;
  cursor: pointer;
  width: 4rem;
  border-radius: 2px;
`;
