import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-date-picker";
import { Checkbox } from "@mui/material";
import DropzoneComponent from "../../fields/Dropzone/DropzoneComponent";
import {
  ButtonWrapper,
  ContainerForm,
  OuterContainer,
  privacyOptions,
  TextInputContainer,
} from "../../Form/BlockForm/BlockForm";
import uuid from "react-uuid";
import { Label, TextField } from "../../Form/FormComponents/TextField";
import { Column } from "../../Form/Web3ResumeContainer";
import Select from "react-select";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Text } from "../../Typography/Typo";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import { useStateValue } from "../../../../context/StateProvider";
import { useSnackbar } from "notistack";
import BlockFormView from "../../Form/BlockForm/BlockFormView";

function AddEduDataForm() {
  const [{ educationFields }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [privacyEdu, setPrivacyEdu] = useState(privacyOptions[0]);
  const [name, setName] = useState("");
  const [institution, setInstitution] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [errors, setErrors] = useState("");
  const [description, setDescription] = useState("");
  const matches = useMediaQuery("(max-width:1368px)");
  const [startEduDate, setStartEduDate] = useState();
  const [eduDateError, setEduDateError] = useState(false);
  const [endEduDate, setEndEduDate] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [eduChecked, setEduChecked] = useState(false);

  const handleCheckedEduChange = (event) => {
    setEduChecked(event.target.checked);
  };

  const checkDateRange = () => {
    //work
    const startWork = new Date(startEduDate).getTime();
    const endWork = new Date(endEduDate).getTime();
    //checkRange
    if (startWork > endWork) {
      setEduDateError("Your date is invalid");
    } else {
      setEduDateError("");
    }
  };

  const handleEduFieldSubmit = async (e) => {
    setLoading(true);
    const ipfsData = await AddImageToIPFS(
      imageFile && imageFile.length > 0 && imageFile[0]
    );
    var previewImageUrl = `https://niftron.infura-ipfs.io/ipfs/${ipfsData.ipfsHash}`;
    e.preventDefault();
    try {
      dispatch({
        type: "ADD_EDUCATION_FIELDS",
        item: {
          id: uuid(),
          courseName: name,
          institutionName: institution,
          startDate: new Date(startEduDate).valueOf(),
          endDate: !eduChecked
            ? new Date(endEduDate).valueOf().toString()
            : new Date(2050, 2, 3, 10, 30, 50, 800).valueOf(), // Mon Feb 03 2050 10:30:50,
          imageLogo: previewImageUrl,
          description: description,
          isPublic: privacyEdu.value,
        },
      });
      enqueueSnackbar("Education details added", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setName("");
      setInstitution("");
      setStartEduDate("");
      setEndEduDate("");
      setImageFile([]);
      setDescription("");
      setPrivacyEdu(privacyOptions[0]);
      setLoading(false);
      setEduChecked(false);
    }
  };

  useEffect(() => {
    if (!eduChecked) {
      if (startEduDate && endEduDate) {
        checkDateRange();
      }
    } else {
      setEduDateError("");
    }
    //eslint-disable-next-line
  }, [startEduDate, endEduDate, eduChecked]);

  return (
    <>
      {educationFields?.length > 0 && (
        <Column span={!matches ? true : false} style={{ marginTop: "4rem" }}>
          {educationFields &&
            educationFields?.length > 0 &&
            educationFields?.map((item, key) => {
              return (
                <BlockFormView
                  type={1}
                  data={item && item.startDate ? item : item.data}
                  key={key}
                />
              );
            })}
        </Column>
      )}
      <OuterContainer style={{ width: "100%" }}>
        <ContainerForm>
          <TextField
            label="Course Name *"
            placeholder="Course Name"
            id="courseName"
            onChange={setName}
            value={name}
            form="none"
            name="courseName"
            type="text"
            maxLength="100"
          />
          <TextField
            label="Institution Name *"
            placeholder="Institution Name"
            id="institution"
            onChange={setInstitution}
            value={institution}
            name="institution"
            form="none"
            type="text"
            maxLength="100"
          />
          <Column style={{ gridRow: "span 2" }}>
            <Label>Institution Logo *</Label>
            <DropzoneComponent
              files={imageFile}
              id="dropzone"
              amount="1"
              form="none"
              multiple={false}
              maxSize="1000000"
              setFiles={setImageFile}
              setErrors={setErrors}
              errors={errors}
              type="image/*, image/jpeg, image/png"
              description="Drop or select your Logo image (Max file size 1MB)"
            />
          </Column>
          <Column>
            <Label>Start Date *</Label>
            <TextInputContainer>
              <DatePicker
                monthPlaceholder="MM"
                yearPlaceholder="YY"
                form="none"
                dayPlaceholder="DD"
                onChange={setStartEduDate}
                value={startEduDate}
                clearIcon={false}
                calendarIcon={
                  <CalendarTodayIcon style={{ color: "#080036" }} />
                }
                className="custom-date"
              />
            </TextInputContainer>
          </Column>
          <Column>
            {!eduChecked && (
              <>
                <Label>End Date *</Label>
                <TextInputContainer>
                  <DatePicker
                    monthPlaceholder="MM"
                    yearPlaceholder="YY"
                    form="none"
                    dayPlaceholder="DD"
                    onChange={setEndEduDate}
                    value={endEduDate}
                    clearIcon={false}
                    calendarIcon={
                      <CalendarTodayIcon style={{ color: "#080036" }} />
                    }
                    className="custom-date"
                  />
                </TextInputContainer>
              </>
            )}
            <FlexContainer>
              <Checkbox
                checked={eduChecked}
                onChange={handleCheckedEduChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Label>Currently working on this role</Label>
            </FlexContainer>
          </Column>
          <Column>
            <Text lighter smaller style={{ color: "#FF613F" }}>
              {eduDateError}
            </Text>
          </Column>
          <TextField
            span={!matches ? true : false}
            label="Description"
            placeholder="Description"
            id="description"
            onChange={setDescription}
            value={description}
            name="description"
            form="none"
            type="text"
          />{" "}
          <Column span={!matches ? true : false}>
            <Label>Private / Public</Label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  outline: "none",
                  text: "orangered",
                  primary25: "#2f3a6039",
                  primary: " #2f3a60a7",
                  neutral0: "#c3d0e1",
                  boxShadow: "none",
                },
              })}
              name="isPublic"
              className="basic-multi-select"
              classNamePrefix="select"
              defaultValue={privacyOptions[0]}
              options={privacyOptions}
              value={privacyEdu}
              onChange={setPrivacyEdu}
            />
          </Column>
          <ButtonWrapper
            style={{
              girdColumn: `${!matches ? "1/3" : "unset"}`,
            }}
          >
            <Button
              style={{
                background: "#1fc36157",
                color: "#080036",
                textTransform: "inherit",
                fontWeight: "500",
                fontFamily: "Poppins",
                padding: ".3rem 1rem",
                float: "right",
              }}
              endIcon={loading && <CircularProgress size={20} />}
              form="educationForm"
              onClick={handleEduFieldSubmit}
              disabled={
                eduDateError ||
                loading ||
                imageFile === [] ||
                name === "" ||
                startEduDate === undefined ||
                institution === ""
              }
              size="small"
            >
              Add Entry
            </Button>
          </ButtonWrapper>
        </ContainerForm>
      </OuterContainer>
    </>
  );
}

export default AddEduDataForm;
const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
