import React from "react";
import styled from "styled-components";
import { InputContainer } from "../../fields/TextField/CustomTextField";
import MultipleImageSlider from "../FormComponents/MultipleImageSlider";
import { Label } from "../FormComponents/TextField";
import { ArrowBody, ArrowsContainer } from "./StageSliderView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VerifiedStatusContainer from "../../Profile/VerifiedStatusContainer";

const MultipleImageViewSlider = ({
  data,
  fieldName,
  viewPage,
  isPublic,
  setShowAction,
  setVerification,
  setShowDecryption,
  setShowEncryption,
  setCurrentField,
  profileData,
  id,
}) => {
  return (
    <Container>
      <InputContainer>
        <Label>
          {fieldName
            .toString()
            .replace(/([A-Z])/g, " $1")
            .substring(0, 1)
            .toUpperCase() +
            fieldName
              .toString()
              .replace(/([A-Z])/g, " $1")
              .substring(1)}
        </Label>
        {viewPage && (
          <VerifiedStatusContainer
            status={0}
            isPublic={isPublic}
            fieldData={profileData}
            fieldId={{ id: id, name: "customData" }}
            fieldName={fieldName}
            setShowAction={setShowAction}
            setVerification={setVerification}
            setShowDecryption={setShowDecryption}
            setShowEncryption={setShowEncryption}
            isDecrypted={profileData.isPublic}
            setCurrentField={setCurrentField}
            encryptedType={profileData.data[fieldName].encryptionType}
          />
        )}
      </InputContainer>
      <MultipleImageSlider data={data} />
      <ArrowsContainer>
        <ArrowBody className="prev">
          <ArrowBackIosNewIcon
            className="prev"
            style={{ color: "#080036", cursor: "pointer" }}
          />
        </ArrowBody>
        <ArrowBody className="next">
          <ArrowForwardIosIcon
            className="next"
            style={{ color: "#080036", cursor: "pointer" }}
          />
        </ArrowBody>
      </ArrowsContainer>
    </Container>
  );
};

export default MultipleImageViewSlider;

const Container = styled.div`
  display: flex;
  margin: 1rem 0rem;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0rem;
`;
