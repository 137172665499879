import React, { useState } from "react";
import styled from "styled-components";
import TimeAgo from "react-time-ago";
import DOMPurify from "dompurify";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { CustomButton } from "../../components/shared/fields/Button/CustomButton";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  AbsoluteContainer,
  ColumnContainer,
  Container,
  FlexContainer,
  Image,
  ImageWrapper,
  OpacityContainer,
  Preview,
  PrevieWrapper,
  Text,
} from "../../pages/JobListings/JobComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urlToHash } from "../../utils/ipfs";

const JobPostingView = ({ item }) => {
  const [view, setView] = useState(false);
  const history = useHistory();

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <Container hide={!view}>
      <CountContainer>
        <FlexContainer>
          <ImageWrapper>
            <Image
              alt="company"
              src={`https://niftron.infura-ipfs.io/ipfs/${urlToHash(
                item?.companyProfileImage
              )}`}
            />
          </ImageWrapper>
          <ColumnContainer>
            <Text bold primary underline>
              {item?.position}
            </Text>
            <Text color="#666666">{item?.name}</Text>
            <Text color="#666666">{`${item?.city}, ${item?.country}`}</Text>
            <Text primary small>
              <TimeAgo date={new Date(item?.createdAt)} locale="en-US" />
            </Text>

            <FlexContainer style={{ gap: "1rem" }}>
              <CustomButton
                login
                text="View Applicants"
                onclick={() => {
                  history.push("/dashboard/job-postings/candidates", item);
                }}
              />
              <CustomButton login rejectClass text="Delete" />
            </FlexContainer>
          </ColumnContainer>
        </FlexContainer>
        <ColumnContainer>
          <IconButton>
            <EditIcon />
          </IconButton>
        </ColumnContainer>
      </CountContainer>
      <PrevieWrapper hide={!view}>
        <Preview
          className="preview"
          dangerouslySetInnerHTML={createMarkup(item?.description)}
        />
        {!view ? (
          <AbsoluteContainer>
            <IconButton onClick={() => setView((value) => !value)}>
              <KeyboardDoubleArrowDownIcon style={{ color: "#1E1B3B" }} />
            </IconButton>
          </AbsoluteContainer>
        ) : (
          <ColumnContainer style={{ alignItems: "center" }}>
            <IconButton onClick={() => setView((value) => !value)}>
              <KeyboardDoubleArrowUpIcon style={{ color: "#1E1B3B" }} />
            </IconButton>
          </ColumnContainer>
        )}
      </PrevieWrapper>
      <OpacityContainer hide={view} />
    </Container>
  );
};

export default JobPostingView;

export const CountContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;
