import styled from "styled-components";
import BgOne from "../../assets/images/bg-1.jpg";
import { LoginBanner } from "../Login/LoginBanner";
import { SignUpComponent } from "./SignUpComponent";
import { GridContainer } from "./SignUpPageStyles";

const SignUp = () => {
  return (
    <Container>
      <GridContainer>
        <SignUpComponent /> <LoginBanner />
      </GridContainer>
    </Container>
  );
};

export default SignUp;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-size: cover;
  backface-visibility: hidden;
  /* background-image: url(${BgOne}); */
  @media (max-width: 768px) {
    height: auto;
  }
`;
