import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useParams, useLocation } from "react-router-dom";

import { SubHeading, Text } from "../../components/shared/Typography/Typo";
import ProductProfileHeader, {
  IconWrapper,
  SectionContainer,
} from "../../components/shared/ProductProfile/ProductProfileHeader";
import { assignQr, getObject } from "../../api/API";
import EncryptedData from "../../components/shared/Profile/EncryptedData";
import {
  ArrowBody,
  ArrowsContainer,
  Slider,
} from "../../components/shared/Form/StageForm/StageSliderView";
import AdditionalDetails from "../../components/shared/Profile/AdditionalDetails";
import { urlToHash } from "../../utils/ipfs";
import { GetIPFSData } from "./../../services/IpfsService";
import InnerLoadingView from "../../components/shared/loading/InnerLoadingView";
import VerifiedStatusContainer from "../../components/shared/Profile/VerifiedStatusContainer";
import { ViewVerifiedDetails } from "../../components/shared/ProductProfile/ViewVerifiedDetails";
import { RequestVerification } from "../../components/shared/Profile/RequestVerification";
import VerificationStatusView from "../../components/shared/VerificationViews/VerificationStatusView";

const ViewProduct = () => {
  const { id } = useParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [date, onDateChange] = useState();
  const currentUser = useSelector((state) => state.currentUser);
  const [latestIpfsData, setLatestIpfsData] = useState();
  const [isPublic, setIsPublic] = useState(false);
  const [isDecrypted, setIsDecrypted] = useState(false);
  const [showDecryption, setShowDecryption] = useState(false);
  const [ownerShipDetails, setOwnerShipDetails] = useState("");
  const [productPageLoading, setProductPageLoading] = useState(false);
  const [ownershipLoading, setOwnerShipLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [verification, setVerification] = useState({
    type: null,
    fieldData: null,
  });
  const [currentField, setCurrentField] = useState({});
  const [viewVerifiedData, setViewVerifiedData] = useState(false);

  const fetchIpfsData = async (ipfsUrl) => {
    const hash = urlToHash(ipfsUrl);
    try {
      const data = await GetIPFSData(hash);
      setLatestIpfsData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProduct = async () => {
    setProductPageLoading(true);
    try {
      const response = await getObject({
        aId: id,
      });
      if (response !== undefined) {
        fetchIpfsData(response?.data?.data[0].latestIpfsUrl.ipfsUrl);
        fetchOwnerShipDetails();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setProductPageLoading(false);
    }
  };

  const fetchOwnerShipDetails = async () => {
    setOwnerShipLoading(true);
    try {
      const response = await assignQr({
        body: {
          productPublicKey: id,
          userPublicKey: currentUser?.publicKey,
          userAlias: currentUser?.alias,
          userImage: currentUser?.image,
        },
      });
      if (response?.data?.code === 201) {
        setOwnerShipDetails({
          userImage: currentUser.image,
          userPublicKey: currentUser.publicKey,
          userAlias: currentUser.alias,
        });
      } else if (response?.data?.code === 200) {
        setOwnerShipDetails(response?.data?.data[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setOwnerShipLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <>
      {(() => {
        if (productPageLoading) {
          return <InnerLoadingView />;
        } else if (latestIpfsData && !productPageLoading) {
          return (
            <Fragment>
              {showAction && (
                <RequestVerification
                  verification={verification}
                  currentField={currentField}
                  show={showAction}
                  setShowAction={setShowAction}
                  isViewAssignedItem={true}
                />
              )}
              {viewVerifiedData && (
                <ViewVerifiedDetails
                  verifiedData={currentField}
                  setShowAction={setViewVerifiedData}
                />
              )}
              <Container>
                <ProductProfileHeader
                  profileData={latestIpfsData}
                  setVerification={setVerification}
                  setShowAction={setShowAction}
                  isPublic={isPublic}
                  setIsPublic={setIsPublic}
                  setShowDecryption={setShowDecryption}
                  setCurrentField={setCurrentField}
                  isDecrypted={isDecrypted}
                  isViewAssignedItem={true}
                  ownerShipDetails={ownerShipDetails}
                />
                <SectionContainer>
                  <SectionInnerContainer>
                    <FlexSpaceBetweenContainer>
                      <SubHeading primary>About</SubHeading>{" "}
                      {latestIpfsData?.description?.isPublic && (
                        <VerifiedStatusContainer
                          isPublic={isPublic}
                          fieldData={latestIpfsData?.description}
                          fieldName="Description"
                          fieldId={{ id: "description" }}
                          setShowAction={setShowAction}
                          setVerification={setVerification}
                          setShowDecryption={setShowDecryption}
                          isDecrypted={latestIpfsData?.description?.isPublic}
                          encryptedType={
                            latestIpfsData?.description?.data?.encryptionType
                          }
                          setCurrentField={setCurrentField}
                        />
                      )}
                    </FlexSpaceBetweenContainer>
                    {latestIpfsData?.description?.isPublic ? (
                      <Text primary lighter>
                        {latestIpfsData?.description?.data?.data}
                      </Text>
                    ) : (
                      <EncryptedData
                        fieldData={latestIpfsData?.description?.data?.data}
                        setShowDecryption={setShowDecryption}
                      />
                    )}
                  </SectionInnerContainer>
                </SectionContainer>

                {latestIpfsData &&
                  latestIpfsData.stageData &&
                  latestIpfsData.stageData.length > 0 && (
                    <SectionContainer>
                      <FlexColumnContainer>
                        <FlexSpaceBetweenContainer>
                          <SubHeading primary>Stages</SubHeading>
                        </FlexSpaceBetweenContainer>
                        <Slider
                          productData={latestIpfsData?.stageData}
                          setShowAction={setShowAction}
                          setVerification={setVerification}
                          setViewVerifiedData={setViewVerifiedData}
                          setCurrentField={setCurrentField}
                          setShowDecryption={setShowDecryption}
                          isPublic={isPublic}
                        />
                        <ArrowsContainer>
                          <ArrowBody className="prev">
                            <ArrowBackIosNewIcon
                              className="prev"
                              style={{ color: "#080036", cursor: "pointer" }}
                            />
                          </ArrowBody>
                          <ArrowBody className="next">
                            <ArrowForwardIosIcon
                              className="next"
                              style={{ color: "#080036", cursor: "pointer" }}
                            />
                          </ArrowBody>
                        </ArrowsContainer>
                      </FlexColumnContainer>
                    </SectionContainer>
                  )}

                {latestIpfsData &&
                  latestIpfsData.customData &&
                  latestIpfsData.customData.length > 0 && (
                    <AdditionalDetails
                      isDecrypted={isDecrypted}
                      isPublic={isPublic}
                      profileData={latestIpfsData}
                      setShowAction={setShowAction}
                      setVerification={setVerification}
                      setShowDecryption={setShowDecryption}
                      setCurrentField={setCurrentField}
                      isViewAssignedItem={true}
                    />
                  )}
              </Container>
            </Fragment>
          );
        }
      })()}
    </>
  );
};

export default ViewProduct;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.noGap ? "0" : "1rem")};
  border: 0.75px solid #d3d3d36e;
  border-radius: 10px;
  padding: 1rem;
  @media (max-width: 1368px) {
    padding: 0.4rem;
  }
`;

export const SectionInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Box = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;
export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 1.5rem 3rem 1.5rem;
`;

export const Link = styled.a`
  color: #1e1b3b;
  max-width: 600px;
  word-break: break-word;
  font-weight: 500;
  @media (max-width: 1368px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  gap: 1rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
`;
